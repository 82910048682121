import { Fragment, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReceipt } from '@fortawesome/free-solid-svg-icons';
import { Dispatch, SetStateAction, useState } from 'react';
import Dropdown from '../components/dropdown';
import { useNavigate } from 'react-router-dom';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  items: string[];
}

export default function RescheduleSelectOrderModal({
  open,
  setOpen,
  items,
}: Props) {
  const cancelButtonRef = useRef(null);

  const [selected, setSelected] = useState<string | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    if (items && items[0]) {
      setSelected(items[0]);
    }
  }, [items]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          ></span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:text-center sm:align-middle">
              <div className="sm:flex sm:flex-col sm:items-center sm:justify-center">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                  <FontAwesomeIcon
                    icon={faReceipt}
                    className="text-indigo-600"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mt-2 sm:flex sm:flex-col sm:justify-center sm:text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Select an order
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm leading-[24px] text-gray-500">
                      Please select the corresponding order #
                    </p>
                  </div>
                </div>
                <div className="flex justify-center pt-2 sm:flex-col">
                  <Dropdown
                    title={selected ? selected : 'Order #'}
                    changed={selected ? true : false}
                    items={items}
                    width={'w-32'}
                    widthOfDropdown={'w-32'}
                    height={'h-fit overflow-y-scroll'}
                    functionToSend={setSelected}
                  />
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse sm:justify-between">
                {selected ? (
                  <button
                    type="button"
                    className="min-h-12 mt-3 inline-flex w-full justify-center rounded-md bg-indigo-700 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() =>
                      navigate('/booking-select', {
                        state: { orderId: parseInt(selected) },
                      })
                    }
                    ref={cancelButtonRef}
                  >
                    Reschedule
                  </button>
                ) : (
                  <button
                    type="button"
                    className="min-h-12 mt-3 inline-flex w-full justify-center rounded-md bg-indigo-700 px-4 py-2 text-base font-medium text-white opacity-50 shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    disabled={true}
                    ref={cancelButtonRef}
                  >
                    Reschedule
                  </button>
                )}
                <div className="pb-3"></div>
                <button
                  type="button"
                  className="min-h-12 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
