import { useEffect } from 'react';
import smartlookClient from 'smartlook-client';
import { useMe } from '../services/user.service';

interface Props {
  children: React.ReactNode;
}

export default function SmartlookProvider({ children }: Props) {
  const { data: user } = useMe();
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      smartlookClient.init('652a8340547c4d1ffcadf53af9102fba5eb34956', {
        region: 'us',
      });
      if (user) {
        smartlookClient.identify(user.id, {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          phoneNumber: user.phone ? user.phone : '',
        });
      }
    }
  }, [user]);
  return <>{children}</>;
}
