import { Moment } from 'moment';
import { ProductObj } from '../types';
import { PersistentStorageItem } from './persistent-storage';

export const ORDER_STATE_KEY = 'order-state';

export interface OrderState {
  product: ProductObj;
  date: Moment;
  incartAddition?: number;
  incartPopup?: number;
}

export class OrderStatePersistentStorageItem extends PersistentStorageItem<
  string,
  OrderState[]
> {
  public key: string;
  public defaultValue?: OrderState[] = [];

  constructor(key: string) {
    super();
    this.key = key;
  }

  public encode(item: OrderState[]) {
    return JSON.stringify(item);
  }

  public decode(encodedOrderState: string) {
    return JSON.parse(encodedOrderState) as OrderState[];
  }
}
