import { useCloudinaryImage } from '../helpers';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';

interface Props {
  state: OrderState;
  removeItem: () => void;
}

export default function ProductCard({ state, removeItem }: Props) {
  const {
    product: { product },
  } = state;
  const image = useCloudinaryImage(product.image);
  return (
    <div className="mt-4 flex flex-row rounded-lg bg-white shadow-md">
      <div className="flex flex-col p-4">
        <div className="h-14 w-14">
          <img
            src={image}
            className="h-14 w-14 rounded-md object-cover"
            alt={product.name}
          />
        </div>
      </div>
      <div className="my-4 flex w-full flex-col">
        <div className="text-sm font-semibold leading-5 text-gray-900">{`$ ${product.price}`}</div>
        <div className="flex w-full flex-row justify-between">
          <div className="text-sm font-medium leading-5 text-gray-900">
            {product.name}
          </div>
          <div
            className="cursor-pointer px-4 text-sm font-medium leading-5 text-red-500"
            onClick={removeItem}
          >
            Remove
          </div>
        </div>
        <div className="mt-1 text-sm font-medium leading-5">{'12 oz'}</div>
      </div>
    </div>
  );
}
