import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import SelectedFridgeItem from '../fridges/selected-fridge-item';
import orderImage from '../img/order.png';
import firstBookingImage from '../img/first-booking.png';
import OrderSuccessItem from '../order-success/order-success-item';
import { useOrderByPaymentIntent } from '../services/orders.service';
import ErrorPage from './error';
import LoadingPage from './loading';
import { BOOKING_DAYS } from '../constants';
import { BookingItem } from '../types';

export default function OrderSuccess() {
  const { paymentIntent } = useParams();
  const navigate = useNavigate();
  const { bookings, error, loading } = useOrderByPaymentIntent(paymentIntent);
  localStorage.removeItem(BOOKING_DAYS);

  if (loading) {
    return <LoadingPage />;
  }

  if (error || !bookings) {
    return (
      <ErrorPage
        error={error?.graphQLErrors.map(({ message }) => `${message}`)}
      />
    );
  }
  const {
    subTotal,
    taxTotal,
    discountTotal,
    shippingTotal,
    incartAdditionsDiscount,
  } = bookings[0].payment;
  var total = 0;
  total = +subTotal + +taxTotal - +discountTotal + +shippingTotal;
  if (incartAdditionsDiscount && total !== 0) {
    total = total - +incartAdditionsDiscount;
  }
  total = parseFloat(total.toFixed(2));

  function add(accumulator: number, a: number) {
    return accumulator + a;
  }

  function calculateItems(bookingItems: BookingItem[]) {
    let count = bookingItems.map((e) => e.quantity).reduce(add, 0);
    return count;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {bookings && bookings[0] && bookings[0].firstBooking ? (
        <>
          <img
            src={firstBookingImage}
            alt="first"
            className="h-64 w-full object-scale-down"
          />
          <div className="pt-4"></div>
        </>
      ) : (
        <>
          <img
            src={orderImage}
            alt="food"
            className="h-64 w-full object-cover"
          />
          <div className="pt-12"></div>
        </>
      )}
      <div className="border-b bg-gray-50">
        <div className="mx-4">
          <div className="text-sm font-medium text-gray-900">
            Payment Successful
          </div>
          <div className="mt-1 text-4xl font-semibold">
            Thanks for ordering!
          </div>
          <div className="text-md mt-3 mb-4 ml-1 text-gray-500">
            {`Prepping delivery for your order. You'll receive an email with your receipt!`}
          </div>
          <div
            onClick={() => navigate('/upcoming')}
            className="text-md ml-1 text-indigo-500"
          >
            Continue to Dashboard{' '}
            <FontAwesomeIcon className="ml-1" icon={faArrowRight} />
          </div>
        </div>
        <div className="mt-6 h-0.5 bg-gray-50" />
      </div>

      <div className="mx-4 mt-6">
        {bookings.map(({ startAt, bookingItems }, index) => (
          <div key={index}>
            <div className="relative flex flex-row items-center pt-2">
              <div className="flex h-14 w-14 flex-col items-center justify-center rounded-md bg-indigo-100">
                <div className="text-md text-indigo-600">
                  {moment(startAt).format('MMM')}
                </div>
                <div className="text-md text-indigo-600">
                  {moment(startAt).format('D')}
                </div>
              </div>
              <div className="flex flex-col pl-2 text-sm">
                {calculateItems(bookingItems) > 1 ? (
                  <div>{calculateItems(bookingItems)} items</div>
                ) : (
                  <div>{calculateItems(bookingItems)} item</div>
                )}
                <div className="text-sm font-medium">
                  {'Subtotal: $'}

                  {bookingItems
                    .map((e) =>
                      e.incartAddition
                        ? e.incartAddition.price * e.quantity
                        : e.incartPopup
                        ? e.incartPopup.price * e.quantity
                        : e.price * e.quantity
                    )
                    .reduce(add, 0)
                    .toFixed(2)}
                </div>
              </div>
            </div>
            {bookingItems.map((item) => (
              <OrderSuccessItem item={item} />
            ))}
          </div>
        ))}
      </div>
      {bookings[0].cubbi.fridge && (
        <SelectedFridgeItem fridge={bookings[0].cubbi.fridge} />
      )}
      <div className="pt-16" />
      <dl className="space-y-6 bg-gray-50 px-4 py-4 text-sm">
        <div className="flex justify-between">
          <dt className="font-medium text-gray-500">Subtotal</dt>
          <dd className="text-gray-700">${subTotal}</dd>
        </div>
        <div className="flex justify-between">
          <dt className="flex font-medium text-gray-500">Discount</dt>
          <dd className="text-gray-500">
            {discountTotal > 0 ? '$' + discountTotal : '-'}
          </dd>
        </div>
        {incartAdditionsDiscount > 0 && discountTotal !== subTotal && (
          <div className="flex justify-between">
            <dt className="font-medium text-gray-500">Cart discount</dt>
            <dd className="text-green-700">-${incartAdditionsDiscount}</dd>
          </div>
        )}
        <div className="flex justify-between">
          <dt className="font-medium text-gray-500">Taxes</dt>
          <dd className="text-gray-900">${taxTotal}</dd>
        </div>
        <div className="flex justify-between">
          <dt className="font-medium text-gray-500">Delivery</dt>
          <dd className="text-gray-900">
            {shippingTotal ? `$${shippingTotal}` : 'FREE'}
          </dd>
        </div>
        <div className="flex justify-between border-t">
          <dt className="py-4 font-medium text-gray-900">Total</dt>
          <dd className="py-4 text-indigo-600">${total.toFixed(2)}</dd>
        </div>
      </dl>
      <div className="flex justify-end py-4 px-4 text-indigo-600">
        <div onClick={() => navigate('/upcoming')}>
          Continue to Dashboard
          <FontAwesomeIcon className="ml-1" icon={faArrowRight} />
        </div>
      </div>
    </div>
  );
}
