import { getNodeDataFromEdges } from '../helpers';
import { Product, QueryResult } from '../types';
import FeaturedProductCard from './featured-card';
import moment, { Moment } from 'moment';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';

interface Props {
  products: Product[];
  handleOpenProductModal: (product: Product) => unknown;
  currentDate: Moment;
  orderItems: OrderState[];
}

export default function FeaturedProducts({
  products,
  handleOpenProductModal,
  currentDate,
  orderItems,
}: Props) {
  const currentDayFormatted = currentDate.format('dddd');
  const currentDayByNumber = currentDate.date();
  const today = moment().date();
  const tomorrow = moment().add(1, 'days');
  const tomorrowByNumber = tomorrow.date();
  const tomorrowByMonth = tomorrow.month();
  const todayMonth = moment().month();
  const hour = moment().hour();
  const currentDayByMonth = currentDate.month();
  return (
    <div className="flex snap-x snap-mandatory space-x-3 overflow-x-auto px-3">
      {products
        .filter(({ isAvailable }) => isAvailable)
        .filter(({ availableDates }) => {
          if (
            today === currentDayByNumber &&
            todayMonth === currentDayByMonth
          ) {
            if (
              availableDates &&
              availableDates.includes(currentDayFormatted)
            ) {
              return true;
            } else {
              return false;
            }
          } else if (
            tomorrowByNumber === currentDayByNumber &&
            tomorrowByMonth === currentDayByMonth &&
            hour >= 18
          ) {
            if (
              availableDates &&
              availableDates.includes(currentDayFormatted)
            ) {
              return true;
            } else {
              return false;
            }
          }
          if (availableDates) {
            return availableDates.includes(currentDayFormatted);
          }
          return false;
        })
        .map((product, index) => (
          <FeaturedProductCard
            key={index}
            product={product}
            handleOpenProductModal={() => handleOpenProductModal(product)}
            currentDate={currentDate}
            orderItems={orderItems}
          />
        ))}
    </div>
  );
}
