interface Props {
  setCode: (code: string) => void;
  code: string;
  submit: () => void;
}

export default function CouponCode({ setCode, code, submit }: Props) {
  return (
    <div className="flex flex-col px-6 py-6">
      <div className="mb-6 text-lg font-bold leading-6">Coupon Code</div>
      <input
        className="w-full rounded-md border border-gray-200 bg-white px-4 py-3 leading-5 text-gray-500 placeholder-gray-500 shadow focus:border-gray-500 focus:placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:ring-opacity-50"
        placeholder="Enter Coupon Code"
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <button
        type="button"
        onClick={submit}
        className="mt-4 items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 shadow hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Apply Code
      </button>
    </div>
  );
}
