import { BookingItem } from '../types';
import { useCloudinaryImage } from '../helpers';

interface Props {
  item: BookingItem;
}

export default function OrderSuccessItem({ item }: Props) {
  const {
    name,
    price,
    quantity,
    image,
    description,
    incartAddition,
    incartPopup,
  } = item;
  return (
    <div className="pt-2">
      <div className="flex flex-row items-center rounded-md shadow-md">
        <div className="py-4 pl-1">
          <div className="px-2">
            <img
              src={useCloudinaryImage(image)}
              alt={name}
              className="h-16 w-16 rounded-md object-cover shadow-md"
            ></img>
          </div>
        </div>
        <div className="w-full">
          {incartAddition ? (
            <div className="text-sm font-medium text-gray-900">
              ${incartAddition.price}
            </div>
          ) : incartPopup ? (
            <div className="text-sm font-medium text-gray-900">
              ${incartPopup.price}
            </div>
          ) : (
            <div className="text-sm font-medium text-gray-900">${price}</div>
          )}

          <div className="max-w-[22ch] overflow-hidden truncate whitespace-nowrap text-sm text-gray-900 sm:max-w-[48ch]">
            {name}
          </div>
          <div className="flex w-full flex-row justify-between">
            <div className="max-w-[30ch] overflow-hidden truncate whitespace-nowrap text-sm text-gray-500 sm:max-w-[48ch]">
              {description}
            </div>
            {quantity > 1 && (
              <div className="px-4 text-sm text-gray-500">x {quantity}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
