export default function CategoriesSkeleton() {
  const constList = [0, 0, 0, 0, 0];
  return (
    <div className="flex w-full animate-pulse border-b">
      <div className="-mb-px flex space-x-8 overflow-auto  px-4">
        {constList.map((e, i) => {
          return (
            <div
              key={i}
              className="group mb-2 inline-flex cursor-pointer items-center border-b-2 border-transparent py-2 px-8 text-xl text-sm font-medium text-gray-500 hover:border-gray-500 hover:text-gray-700"
            >
              ...
            </div>
          );
        })}
      </div>
    </div>
  );
}
