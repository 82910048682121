import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Dispatch, SetStateAction } from 'react';
import { InCartAdditions, ProductObj, AddonGroup } from '../types';
import moment, { Moment } from 'moment';
import Dropdown from '../components/dropdown';
import { useCloudinaryImage } from '../helpers';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  incart: InCartAdditions;
  dates: string[];
  addItem: (
    { product, addons, incartAddition, incartPopup }: ProductObj,
    date: Moment
  ) => void;
}
export default function TopProductModal({
  open,
  setOpen,
  incart,
  dates,
  addItem,
}: Props) {
  const cancelButtonRef = useRef(null);
  const convertDates = dates.map((e) => moment(e));
  const convertedDates = convertDates.map((e) => e.format('MMMM D'));
  const [date, setDate] = useState<string | undefined>(
    convertedDates && convertedDates[0] ? convertedDates[0] : undefined
  );
  const dateToday = moment();

  function addToStored() {
    if (date && incart) {
      let convertedDate = moment(date);
      convertedDate.set('year', dateToday.year());
      let addonGroup: AddonGroup[] = [];
      let productToReturn = {
        ...incart.product,
        price: incart.price,
        addons: addonGroup,
      };
      let item: ProductObj = {
        product: productToReturn,
        addons: addonGroup,
        incartAddition: incart.id,
      };
      addItem(item, convertedDate);
      setOpen(false);
    }
  }

  // PersistentStorage.clear(
  //   new UpsellStatePersistentStorageItem(UPSELL_STATE_KEY)
  // );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Add to cart?
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="flex items-center justify-center">
                        <img
                          className="h-24 w-24"
                          src={useCloudinaryImage(incart.product.image)}
                          alt={incart.product.image}
                        ></img>
                      </div>
                      <div className="py-2">
                        <p className="text-sm text-gray-500">
                          {incart.product.name}
                        </p>
                        <p className="text-sm text-gray-500">{incart.price}</p>
                      </div>
                    </div>
                    {convertedDates.length > 1 && (
                      <div className="py-2">
                        <Dropdown
                          changed={false}
                          items={convertedDates}
                          title={
                            date ? date : convertedDates && convertedDates[0]
                          }
                          width={'w-[168px] h-fit'}
                          widthOfDropdown={'w-[168px] h-fit'}
                          functionToSend={setDate}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                    onClick={() => addToStored()}
                  >
                    Add to cart
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Maybe next time
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
