import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import LoadingPage from './loading';

enum RedirectType {
  Email = 'email',
  Phone = 'phone',
}

export default function RedirectScreen() {
  const { redirect } = useParams<Record<string, string | undefined>>();

  useEffect(() => {
    if (redirect) {
      if (redirect.includes(RedirectType.Email)) {
        window.location.href = `mailto:support@cub.bi`;
      } else if (redirect.includes(RedirectType.Phone)) {
        window.location.href = `tel:3064005430`;
      }
    }
  }, [redirect]);

  return <LoadingPage />;
}
