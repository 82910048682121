import { BookingItem } from '../types';

interface Props {
  item: BookingItem;
}

export default function ReceiptItem({ item }: Props) {
  const { name, quantity, price } = item;
  return (
    <div className="flex">
      <dl className="flex w-full flex-row justify-between divide-gray-200 py-4 text-sm">
        <div className="flex">
          <p className="ml-2 text-gray-700">x{quantity}</p>
          <div className="flex pl-4">
            <dt className="text-left font-medium text-gray-900">{name}</dt>
          </div>
        </div>
        <div className="flex justify-end">
          <dd className="ml-2 justify-end text-gray-500">${price}</dd>
        </div>
      </dl>
    </div>
  );
}
