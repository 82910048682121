import { useCloudinaryImage } from '../helpers';

interface Props {
  name: string;
  image: string | undefined;
}

export default function BookingSelectImage({ image, name }: Props) {
  return (
    <div className="flex flex-row items-center px-2">
      <img
        src={useCloudinaryImage(image)}
        alt={name}
        className="object-fit h-12 w-12"
      ></img>
    </div>
  );
}
