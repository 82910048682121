import {
  faInfoCircle,
  faParachuteBox,
  faTimesCircle,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { getNodeDataFromEdges } from '../helpers';
import ErrorPage from '../screens/error';
import LoadingPage from '../screens/loading';
import { useOrderById, REFUND_BY_ID } from '../services/orders.service';
import OrderItemImage from './order-item-with-image';
import SelectedFridgeItem from '../fridges/selected-fridge-item';
import TopNavigation from '../navigation/top-navigation';
import { useMutation } from '@apollo/client';
import { useState, Fragment, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { classNames } from '../helpers';
import { PriceTotal } from '../types';

export default function OrderDetail() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const orderNumber = parseInt(orderId!);
  const { order, bookings, loading, error } = useOrderById(orderNumber);
  const [refundById] = useMutation<{ success: Boolean }>(REFUND_BY_ID);
  const [refundErrors, setRefundErrors] = useState<{ message: string }[]>([]);
  const [orderTotals, setOrderTotals] = useState<PriceTotal[]>();

  useEffect(() => {
    if (order?.payment.creditCard !== null) {
      setOrderTotals(
        order?.payment.creditCard.paymentSet &&
          getNodeDataFromEdges(order?.payment.creditCard.paymentSet.edges)
      );
    }
    if (order?.payment) {
    }
  }, [order]);

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  const {
    subTotal,
    taxTotal,
    discountTotal,
    shippingTotal,
    multiDayDiscount,
    incartAdditionsDiscount,
  } = order?.payment
    ? order.payment
    : {
        subTotal: 0,
        taxTotal: 0,
        discountTotal: 0,
        shippingTotal: 0,
        multiDayDiscount: 0,
        incartAdditionsDiscount: 0,
      };
  let total = +subTotal + +taxTotal - +discountTotal + +shippingTotal;
  if (total !== 0) {
    total = total - +multiDayDiscount;
  }

  if (incartAdditionsDiscount && total !== 0) {
    total = total - +incartAdditionsDiscount;
  }
  total = parseFloat(total.toFixed(2));

  function countItems() {
    let sum = 0;
    bookings?.map((e) => {
      return (sum = sum + e.bookingItems.length);
    });
    return sum;
  }

  async function handleRefund() {
    if (window.confirm('Are you sure you want to cancel this order?')) {
      try {
        const { data } = await refundById({
          variables: {
            input: {
              orderId: orderId,
            },
          },
        });
        if (!data) {
          alert('Error occured');
        } else {
          alert('Success, your order has been refunded');
        }
      } catch (e: any) {
        setRefundErrors(e.graphQLErrors);
      }
    }
  }
  const updatedDate = moment(order?.updatedAt);
  return (
    <>
      <TopNavigation title="Order Detail" url="/upcoming" />
      <Disclosure as="nav" className="bg-white">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 sm:pt-24 sm:pb-8 ">
          <div className="-mx-4 bg-gray-50 py-4">
            {bookings && bookings[0] && order ? (
              <div className="w-full px-4">
                <dl>
                  <dt className="mt-2 text-2xl font-extrabold tracking-tight sm:text-5xl">
                    Order #{order.id}
                  </dt>
                  {order.refundId ? (
                    <div className="pt-4">
                      <dd className="flex w-64 items-center justify-around rounded-2xl border py-1 text-center font-medium">
                        <FontAwesomeIcon
                          className=" text-xs text-amber-500"
                          icon={faCircle}
                        />
                        Refunded Entire Order
                        <div />
                      </dd>
                    </div>
                  ) : (
                    <div className="pt-4">
                      <dd className="flex w-24 items-center justify-around rounded-2xl rounded-lg border py-1 text-center font-medium">
                        <FontAwesomeIcon
                          className=" text-xs text-green-500"
                          icon={faCircle}
                        />
                        Paid
                        <div></div>
                      </dd>
                    </div>
                  )}
                </dl>
                {bookings && bookings[0] && (
                  <dl className="inline-flex w-full py-4 text-sm font-medium">
                    <dt className="pr-2 text-gray-500 ">Order placed</dt>
                    <dd className=" text-gray-900">
                      {moment(bookings[0].createdAt).format('MMM D, yyyy')}
                    </dd>
                  </dl>
                )}
                {bookings && bookings[0] && order.refundId && updatedDate && (
                  <div className="inline-flex text-sm font-medium">
                    <dt className="pr-2 text-gray-500">Order updated</dt>
                    <dd className="text-gray-900">
                      {updatedDate.format('MMM D, YYYY')}
                    </dd>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <p>Order does not exist</p>
              </div>
            )}
          </div>
          <div className="-mx-4 bg-gray-50">
            <Menu as="div" className="relative inline-block w-40 text-left">
              <div className="px-4 ">
                <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                  More
                  <ChevronDownIcon
                    className="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="w-42 mx-4 flex origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="mailto:support@cub.bi"
                          target="__blank"
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-sm text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Support
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() =>
                            navigate('/booking-select', {
                              state: { orderId: orderNumber },
                            })
                          }
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-sm text-gray-900'
                              : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm'
                          )}
                        >
                          Reschedule
                        </button>
                      )}
                    </Menu.Item>
                    {order && order.refundId ? (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? 'text-sm text-red-200' : 'text-red-200',
                              'block w-full px-4 py-2 text-left text-sm'
                            )}
                          >
                            Cancel Order
                          </button>
                        )}
                      </Menu.Item>
                    ) : (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleRefund}
                            className={classNames(
                              active ? 'text-sm text-red-400' : 'text-red-500',
                              'block w-full px-4 py-2 text-left text-sm'
                            )}
                          >
                            Cancel Order
                          </button>
                        )}
                      </Menu.Item>
                    )}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            {refundErrors.length >= 1 && (
              <div className="p-6">
                {refundErrors.map(({ message }) => (
                  <div
                    className="relative rounded bg-red-50 px-4 py-3 text-red-700"
                    role="alert"
                  >
                    <div className="mr-12 flex flex-row justify-around">
                      <div>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="text-red-500"
                        />
                      </div>
                      <div className="ml-4 flex flex-col">
                        <strong className="font-medium">
                          {'Cannot Cancel Order:'}
                        </strong>
                        <strong>
                          {'One or more items has been finalized.'}
                        </strong>
                        <span className="block sm:inline">{message}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="-mx-4 border-b py-4"> </div>
          </div>
          <div className="mx-4 mt-6">
            <div
              className="flex w-full items-center "
              aria-labelledby="header-heading"
            >
              <div onClick={() => navigate(-1)}>
                <FontAwesomeIcon
                  icon={faParachuteBox}
                  className="align-self-center mr-2 justify-start text-gray-400"
                  style={{ height: 24, width: 24 }}
                />
              </div>
              <div className="mx-auto w-screen max-w-7xl px-4 py-4 sm:px-6 lg:flex lg:justify-between lg:px-8">
                <h3 className="text-black-900 text-lg font-semibold tracking-wide text-gray-900">
                  Ordered Items ({countItems()})
                </h3>
              </div>
            </div>
          </div>
          {!order?.refundId && (
            <div className="rounded-lg bg-blue-50 py-4 px-4 text-blue-800">
              <div className="inline-flex">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-blue-400"
                />
                <p className="pl-4">Unable to pickup delivery items? </p>
              </div>
              <br />
              <div className="inline-flex py-4 px-8 text-blue-700">
                <a
                  href="https://app.cub.bi/#/support:email"
                  className="pr-8"
                  target="__blank"
                >
                  Email us
                </a>
                <a target="__blank" href="https://app.cub.bi/#/support:phone">
                  Call now
                </a>
              </div>
            </div>
          )}

          <h2 className="sr-only">Your order </h2>
          <h3 className="sr-only">Items</h3>
          <div className="py-4"></div>
          {bookings?.map(({ bookingItems, startAt }) => {
            return (
              <>
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-start">
                    <span className="text-md bg-gray-50 pr-2 text-gray-500">
                      {moment(startAt).format('ddd MMM D')}
                    </span>
                  </div>
                </div>
                {bookingItems.map((item) => (
                  <OrderItemImage item={item} />
                ))}
              </>
            );
          })}

          {bookings && bookings[0] && (
            <div className="-mx-4">
              <SelectedFridgeItem fridge={bookings[0].cubbi.fridge} />
            </div>
          )}
        </div>
        <div className="flex py-4 lg:justify-center">
          <div className="w-full px-4 text-2xl lg:w-1/2">
            <div>
              <h3 className="sr-only">Your information</h3>

              <h4 className="sr-only">Payment</h4>
              <dl className="grid grid-cols-2 gap-x-6 border-gray-200 text-sm">
                {orderTotals &&
                  orderTotals[0] !== null &&
                  orderTotals[0].payee !== null &&
                  order?.payment !== null &&
                  order?.payment.creditCard !== null && (
                    <div>
                      <div>
                        {orderTotals[0].payee.firstName}{' '}
                        {orderTotals[0].payee.lastName}
                      </div>
                      <dd className="mt-2 text-gray-900">
                        <p>
                          <span className="sr-only">Ending in </span>
                          Ending with {order?.payment.creditCard.last4}
                        </p>
                      </dd>

                      <dt className="font-medium text-gray-500">
                        Expires
                        {` ${
                          order?.payment.creditCard.expMonth
                        }/${order?.payment.creditCard.expYear.slice(2, 4)}`}
                      </dt>
                    </div>
                  )}
              </dl>
              <p className="py-8 font-medium">Charges Breakdown</p>
              <h3 className="sr-only">Summary</h3>

              {order?.payment !== null && (
                <dl className="space-y-6 border-gray-200 text-sm">
                  <div className="flex justify-between">
                    <dt className="font-medium text-gray-500">Subtotal</dt>
                    <dd className="text-gray-700">${subTotal}</dd>
                  </div>
                  {discountTotal > 0 && (
                    <div className="flex justify-between">
                      <dt className="flex font-medium text-gray-500">
                        Discount
                      </dt>
                      <dd className="text-green-700">
                        {discountTotal > 0 ? '-$' + discountTotal : '-'}
                      </dd>
                    </div>
                  )}
                  {multiDayDiscount > 0 && subTotal !== discountTotal && (
                    <div className="flex justify-between">
                      <dt className="flex font-medium text-gray-500">
                        Multi-day discount
                      </dt>
                      <dd className="text-green-700">
                        {multiDayDiscount > 0 ? '-$' + multiDayDiscount : '-'}
                      </dd>
                    </div>
                  )}
                  {incartAdditionsDiscount > 0 && subTotal !== discountTotal && (
                    <div className="flex justify-between">
                      <dt className="flex font-medium text-gray-500">
                        Cart discount
                      </dt>
                      <dd className="text-green-700">
                        {incartAdditionsDiscount > 0
                          ? '-$' + incartAdditionsDiscount
                          : '-'}
                      </dd>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <dt className="font-medium text-gray-500">Taxes</dt>
                    <dd className="text-gray-900">${taxTotal}</dd>
                  </div>
                  <div className="flex justify-between">
                    <dt className="font-medium text-gray-500">Delivery</dt>
                    <dd className="text-gray-900">
                      {order?.payment?.shippingTotal
                        ? '$' + order?.payment?.shippingTotal
                        : 'FREE'}
                    </dd>
                  </div>
                  <div className="flex justify-between border-t border-b text-lg font-medium text-gray-900">
                    <dt className="py-4">Total</dt>
                    <dd className="py-4">${total}</dd>
                  </div>
                  <div className="flex justify-between">
                    <dt className="text-gray-500">Paid by customer</dt>
                    <dd>${total}</dd>
                  </div>
                  {order?.refundId && (
                    <>
                      <div className="flex justify-between">
                        <dt className="text-gray-500">Refunded</dt>
                        <dd>-${total}</dd>
                      </div>
                      <div className="flex justify-between text-lg font-medium text-gray-900">
                        <dt className="py-4">Net Payment</dt>
                        <dd className="py-4">$0.00</dd>
                      </div>
                    </>
                  )}
                </dl>
              )}
            </div>
          </div>
        </div>
      </Disclosure>
    </>
  );
}
