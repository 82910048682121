export default function PrestockSkeleton() {
  const constList = [0, 0, 0, 0];
  return (
    <div className="flex animate-pulse">
      <div className="p-4">
        <div className="pt-4" />
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8">
          {constList.map((e, i) => (
            <div
              className="relative h-[308px] w-[170px] overflow-hidden rounded-xl bg-white shadow-md hover:shadow-xl"
              key={i}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}
