import { useOrderById } from '../services/orders.service';
import LoadingPage from '../screens/loading';
import ErrorPage from '../screens/error';
import { getNodeDataFromEdges } from '../helpers';
import moment from 'moment';
import ReceiptItem from './receipt-item';
import { useParams } from 'react-router-dom';
import SelectedFridgeItem from '../fridges/selected-fridge-item';
import TopNavigation from '../navigation/top-navigation';

export default function OrderReceipt() {
  const { orderId } = useParams();
  const orderNumber = parseInt(orderId!);

  const { order, bookings, loading, error } = useOrderById(orderNumber);

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  const orderTotals =
    order?.payment.creditCard.paymentSet &&
    getNodeDataFromEdges(order?.payment.creditCard.paymentSet.edges);

  const { subTotal, taxTotal, discountTotal, shippingTotal } =
    orderTotals && orderTotals[0]
      ? orderTotals[0]
      : { subTotal: 0, taxTotal: 0, discountTotal: 0, shippingTotal: 0 };

  var total = 0;
  total = +subTotal + +taxTotal - +discountTotal + +shippingTotal;
  total = parseFloat(total.toFixed(2));
  return (
    <>
      <TopNavigation
        title="Order Receipt"
        url={`/order-details/${order?.id}`}
      />
      <div className="bg-white">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 sm:py-24 lg:px-8">
          <div className="-mx-4 bg-gray-50 py-4">
            {bookings && bookings[0] && order ? (
              <div className="w-full px-4">
                <dl>
                  <dt className="mt-2 text-2xl font-extrabold tracking-tight sm:text-5xl">
                    Order #{order?.id}
                  </dt>
                </dl>
                <dl className="inline-flex w-full py-4 text-sm font-medium">
                  <dt className="pr-2 text-gray-500 ">Order placed</dt>
                  <dd className=" text-gray-900">
                    {moment(bookings[0].createdAt).format('MMM Do, yyyy')}
                  </dd>
                </dl>
              </div>
            ) : (
              <div>
                <p>Order does not exist</p>
              </div>
            )}
          </div>
          <div className="-mx-4 border-b"></div>

          <h2 className="sr-only">Your order </h2>
          <h3 className="sr-only">Items</h3>
          <div className="py-4"> </div>
          {bookings?.map(({ bookingItems, startAt }, index) => {
            return (
              <div key={index}>
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-start">
                    <span className="text-md bg-gray-50 pr-2 text-gray-500">
                      {moment(startAt).format('ddd MMM D')}
                    </span>
                  </div>
                </div>
                {bookingItems.map((item) => (
                  <ReceiptItem item={item} />
                ))}
              </div>
            );
          })}

          {bookings && bookings[0] && (
            <div className="-mx-4">
              <SelectedFridgeItem fridge={bookings[0].cubbi.fridge} />
            </div>
          )}

          <div>
            <h3 className="sr-only">Your information</h3>

            <h4 className="sr-only">Payment</h4>
            <dl className="grid grid-cols-2 gap-x-6 border-gray-200 py-10 text-sm">
              <div>
                {orderTotals && orderTotals[0] && (
                  <div>
                    {orderTotals[0].payee.firstName}{' '}
                    {orderTotals[0].payee.lastName}
                  </div>
                )}

                <dd className="mt-2 text-gray-900">
                  <p>
                    <span className="sr-only">Ending in </span>
                    Ending with {order?.payment.creditCard.last4}
                  </p>
                </dd>
                <dt className="font-medium text-gray-500">
                  Expires
                  {` ${
                    order?.payment.creditCard.expMonth
                  }/${order?.payment.creditCard.expYear.slice(2, 4)}`}
                </dt>
              </div>
            </dl>
            <h3 className="sr-only">Summary</h3>

            <dl className="space-y-6 border-gray-200 text-sm">
              <div className="flex justify-between">
                <dt className="font-medium text-gray-500">Subtotal</dt>
                <dd className="text-gray-700">${subTotal}</dd>
              </div>
              <div className="flex justify-between">
                <dt className="flex font-medium text-gray-500">Discount</dt>
                <dd className="text-gray-500">
                  {discountTotal > 0 ? '$' + discountTotal : '-'}
                </dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium text-gray-500">Taxes</dt>
                <dd className="text-gray-900">${taxTotal}</dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium text-gray-500">Delivery</dt>
                <dd className="text-gray-900">
                  {shippingTotal ? `${shippingTotal}` : 'FREE'}
                </dd>
              </div>
              <div className="flex justify-between border-t">
                <dt className="py-4 font-medium text-gray-900">Total</dt>
                <dd className="py-4 text-indigo-600">${total}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
