export default class PersistentStorage {
  private static cache: { [key: string]: string | null } = {};

  public static update<K extends string, V>(
    item: PersistentStorageItem<K, V>,
    newValue: V
  ) {
    localStorage.setItem(
      item.key,
      (PersistentStorage.cache[item.key] = item.encode(newValue))
    );
  }

  public static clear<K extends string, V>(item: PersistentStorageItem<K, V>) {
    localStorage.setItem(item.key, (PersistentStorage.cache[item.key] = ''));
  }

  public static get<K extends string, V>(item: PersistentStorageItem<K, V>) {
    const persistedItem = (PersistentStorage.cache[item.key] =
      PersistentStorage.cache[item.key] || localStorage.getItem(item.key));
    return !persistedItem
      ? item.defaultValue || null
      : item.decode(persistedItem);
  }
}

export abstract class PersistentStorageItem<K extends string, V> {
  public abstract key: K;
  public abstract defaultValue?: V;
  public abstract encode(v: V): string;
  public abstract decode(encodedString: string): V;
}
