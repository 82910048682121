import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { classNames } from '../helpers';
import { AddonGroup } from '../types';
import { Field } from 'formik';
import { SelectionType } from '../constants';

interface Props {
  addon: AddonGroup;
}

export default function SingleAddon({ addon: group }: Props) {
  const { addons } = group;
  const [selected, setSelected] = useState();

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <div className="-space-y-px rounded-md bg-white">
        {addons.map((addon, addonIdx) => (
          <RadioGroup.Option
            key={addon.name}
            value={addon}
            className={({ checked }) =>
              classNames(
                addonIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                addonIdx === addons.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : '',
                checked ? 'border-indigo-200 bg-indigo-50' : 'border-gray-200',
                'relative cursor-pointer items-center border p-4 focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <div className="flex flex-row items-center justify-between">
                <div>
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? 'text-indigo-900' : 'text-gray-900',
                      'block text-sm font-medium'
                    )}
                  >
                    {addon.name}
                  </RadioGroup.Label>

                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      checked ? 'text-indigo-700' : 'text-gray-500',
                      'block text-sm'
                    )}
                  >
                    {addon.price > 0 ? `Add $${addon.price}` : 'Free'}
                  </RadioGroup.Description>
                </div>

                <Field
                  type="radio"
                  name={SelectionType.A_1}
                  value={addon.id}
                  className={classNames(
                    checked
                      ? 'border-transparent bg-indigo-600'
                      : 'border-gray-300 bg-white',
                    active ? 'ring-2 ring-indigo-500 ring-offset-2' : '',
                    'mt-0.5 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border'
                  )}
                />
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
