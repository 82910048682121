import { useCloudinaryImage } from '../helpers';
import { DeviceType } from '../helpers';
import { getMobileOperatingSystem } from '../helpers';

interface Props {
  image: string;
  name: string;
  index: number;
  staticImage: boolean;
}
export default function PromoCard({ image, name, index, staticImage }: Props) {
  const img = useCloudinaryImage(image);
  const staticPromo = image;

  const device: DeviceType = getMobileOperatingSystem();
  function handleLink() {
    if (device === DeviceType.Android) {
      return 'https://play.google.com/store/apps/details?id=com.cubbi';
    } else {
      return 'https://apps.apple.com/ca/app/cubbi-2-0/id6444135609';
    }
  }

  return (
    <div className="flex-shrink-0 snap-center" key={index}>
      <div className="py-4 px-3 ">
        {staticImage ? (
          <a href={handleLink()}>
            <img
              src={staticImage ? staticPromo : img}
              alt={name}
              className="w-64 overflow-clip rounded-xl bg-clip-border shadow-xl"
            />
          </a>
        ) : (
          <img
            src={staticImage ? staticPromo : img}
            alt={name}
            className="w-80 overflow-clip rounded-xl bg-clip-border shadow-xl"
          />
        )}
      </div>
    </div>
  );
}
