export default function DateSelectSkeleton() {
  return (
    <div className="mb-6 h-[330px] w-[330px] rounded-md shadow">
      <div className="flex animate-pulse space-x-4">
        <div className="bg-slate-400 pt-2"></div>
        <div className="flex-1 space-y-6 py-1">
          <div className="p-4">
            <div className="h-2 rounded bg-slate-400 pb-4"></div>
          </div>
          <div className="px-4">
            <div className="py-4">
              <div className="h-2 rounded bg-slate-400"></div>
            </div>
            <div className="py-4">
              <div className="h-2 rounded bg-slate-400"></div>
            </div>
            <div className="py-4">
              <div className="h-2 rounded bg-slate-400"></div>
            </div>
            <div className="py-4">
              <div className="h-2 rounded bg-slate-400"></div>
            </div>
            <div className="py-4">
              <div className="h-2 rounded bg-slate-400"></div>
            </div>
            <div className="py-4">
              <div className="h-2 rounded bg-slate-400"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
