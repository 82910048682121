import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { classNames, useCloudinaryImage } from '../helpers';
import pod from '../img/logo-black-square.png';
import {
  FridgeStatePersistentStorageItem,
  FRIDGE_STATE_KEY,
} from '../persistent-storage/fridge-state.persistent-storage';
import { usePersistentStorageItem } from '../persistent-storage/hooks';
import FridgeFromSub from '../subscriptions/fridge-from-sub';
import { Fridge } from '../types';

interface Props {
  padding?: string;
  fromMealPlan?: boolean;
  fridgeFromSub?: Fridge;
}

export default function DeliveryDetails({
  padding,
  fromMealPlan,
  fridgeFromSub,
}: Props) {
  const navigate = useNavigate();
  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );
  const [selectedFridge] = usePersistentStorageItem(
    selectedFridgeState,
    undefined
  );

  const image = useCloudinaryImage(selectedFridge?.image);
  if (!selectedFridge) {
    return null;
  }

  return (
    <>
      {fridgeFromSub ? (
        <div
          className={classNames(padding ?? 'px-6', 'cursor-pointer')}
          onClick={() =>
            fromMealPlan
              ? navigate(`/fridges`, {
                  state: {
                    fromQuestionnaire: false,
                    fromMealPlan: false,
                    fromEditFridge: true,
                  },
                })
              : navigate('/fridges')
          }
        >
          <h1 className="text-lg font-bold leading-6">Delivering to</h1>
          <div className="flex items-center justify-between py-6">
            <div className="flex flex-row">
              <FridgeFromSub fridgeFromSub={fridgeFromSub} />
              <div className="pl-4">
                <div className="text-sm font-medium leading-5 text-gray-900">
                  {fridgeFromSub.name}
                </div>
                <div className="text-sm leading-5 text-gray-500">
                  {fridgeFromSub.owner?.name}
                </div>
                <div className="truncate text-sm leading-5 text-gray-500">
                  {fridgeFromSub?.location?.description}
                </div>
              </div>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className="text-gray-400" />
          </div>
          <div className="border border-gray-200" />
        </div>
      ) : (
        <div
          className={classNames(padding ?? 'px-6', 'cursor-pointer')}
          onClick={() =>
            fromMealPlan
              ? navigate(`/fridges`, {
                  state: {
                    fromQuestionnaire: false,
                    fromMealPlan: false,
                    fromEditFridge: true,
                  },
                })
              : navigate('/fridges')
          }
        >
          <h1 className="text-lg font-bold leading-6">Delivering to</h1>
          <div className="flex items-center justify-between py-6">
            <div className="flex flex-row">
              <img
                src={image ?? pod}
                alt={selectedFridge.name}
                className="h-16 w-16 justify-center rounded-lg border border-gray-100 object-cover shadow-sm"
              />
              <div className="pl-4">
                <div className="text-sm font-medium leading-5 text-gray-900">
                  {selectedFridge.name}
                </div>
                <div className="text-sm leading-5 text-gray-500">
                  {selectedFridge?.owner?.name}
                </div>
                <div className="truncate text-sm leading-5 text-gray-500">
                  {selectedFridge?.location?.description}
                </div>
              </div>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className="text-gray-400" />
          </div>
          <div className="border border-gray-200" />
        </div>
      )}
    </>
  );
}
