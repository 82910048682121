import { useNavigate } from 'react-router-dom';
import React from 'react';
import { TOKEN, REFRESH_TOKEN, FRIDGE } from '../constants';

export default function AlreadyMemberButton() {
  const navigate = useNavigate();
  async function onPress() {
    //TODO reset tokens async
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(FRIDGE);
    navigate('/login');
  }
  return (
    <div onClick={() => onPress()}>
      <p className={`text-center text-sm font-medium text-primary-700`}>
        Already a member? login
      </p>
    </div>
  );
}
