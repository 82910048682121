import { Moment } from 'moment';
import { useNavigate } from 'react-router-dom';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';
import { PriceTotal } from '../types';
import ProductImageCard from './product-image-card';

interface Props {
  items: {
    date: Moment;
    products: OrderState[];
  }[];
  priceTotal: PriceTotal;
}

export default function OrderItemSummary({ items, priceTotal }: Props) {
  const navigate = useNavigate();
  const deliveryDays = items.length;
  const count = items
    .map(({ products }) => products.length)
    .reduce((a, b) => a + b, 0);
  const products = items.flatMap(({ products }) => products);
  return (
    <div className="py-5 px-4">
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold leading-6">My Order</div>
        <div
          className="text-sm font-medium leading-5 text-indigo-500"
          onClick={() => navigate(-1)}
        >
          View →
        </div>
      </div>
      <div className="mt-6 flex items-center justify-between">
        <div className="text-sm leading-5 text-gray-600">
          {count} items / {deliveryDays} delivery days
        </div>
        <div className="text-sm font-semibold leading-5 text-gray-900">
          ${priceTotal.subTotal.toFixed(2)}
        </div>
      </div>
      <div className="my-4 flex space-x-3">
        {products.slice(0, 4).map(({ product: { product } }, index) => (
          <ProductImageCard
            key={index}
            index={index}
            product={product}
            length={products.length}
          />
        ))}
      </div>
      <div className="rounded-sm border-b border-gray-200 pt-4" />
    </div>
  );
}
