import { BookingById, ProductWithAddons } from '../types';
import { handleReformatMonth } from '../helpers';
import BookingSelectImage from './booking-select-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import React from 'react';

interface Props {
  item: BookingById;
}

export default function BookingSelectItem({ item }: Props) {
  const date = item.startAt.substring(0, 10);
  const month = handleReformatMonth(parseInt(date.substring(5, 7)));
  const day = parseInt(date.substring(8, 10));
  const productsWithAddons = handlePassProducts();

  function handlePassProducts() {
    let list = item.bookingItems;
    let result: ProductWithAddons[] = [];
    if (list) {
      list.forEach(({ addons, productId, quantity }) => {
        if (quantity > 1) {
          for (let i = 0; i < quantity; i++) {
            result = [
              ...result,
              { product: productId, addons: addons.map(({ id }) => id) },
            ];
          }
        } else {
          result = [
            ...result,
            { product: productId, addons: addons.map(({ id }) => id) },
          ];
        }
      });
    }
    return result;
  }
  let increment = 0;
  const navigate = useNavigate();
  return (
    <div className="flex sm:justify-center">
      <div
        className="flex w-full flex-col rounded-md border px-4 py-2 sm:w-1/2"
        key={item.id}
        onClick={() =>
          navigate('/reschedule', {
            state: { bookingIds: [item.id], products: productsWithAddons },
          })
        }
      >
        <span className="flex w-full justify-center">
          {month} {day}
        </span>
        <div
          className="flex flex-row items-center justify-between py-2"
          key={item.id}
        >
          <div className="flex flex-row" key={item.id}>
            {React.Children.toArray(
              item.bookingItems.map((bookingItem) => {
                let jsxElements: JSX.Element[] = [<></>];
                if (bookingItem.quantity > 1) {
                  for (let i = 0; i < bookingItem.quantity; i++) {
                    increment = increment + 1;
                    jsxElements.push(
                      <BookingSelectImage
                        key={increment}
                        image={bookingItem.image}
                        name={bookingItem.name}
                      />
                    );
                  }
                  return jsxElements;
                } else {
                  increment = increment + 1;

                  return (
                    <BookingSelectImage
                      key={increment}
                      image={bookingItem.image}
                      name={bookingItem.name}
                    />
                  );
                }
              })
            )}
          </div>
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-sm text-gray-500"
          />
        </div>
      </div>
    </div>
  );
}
