import LoadingSkeleton from '../components/loading-skeleton';
import { handleCarouselCity } from '../helpers';
import CarouselCardSkeleton from '../loading-skeletons/kitchen/carousel-card-skeleton';
import { usePromos } from '../services/products.service';
import PromoCard from './promo-card';
import { Fridge } from '../types';
import moment from 'moment';
import { useMemo } from 'react';
import promo from '../img/promo1.png';
import { useCloudinaryImage } from '../helpers';

interface Props {
  fridge: Fridge | null;
  email?: string;
}

export default function PromoCarousel({ email, fridge }: Props) {
  const today = moment();
  const currTime = useMemo(() => today.toISOString(true), []);
  const { promos, loading } = usePromos(
    fridge ? fridge.id : 1,
    currTime,
    email
  );

  if (loading) {
    return <CarouselCardSkeleton />;
  }
  const staticPromo = {
    name: 'nativeAppPromo',
    image: promo,
    position: 0,
    staticImage: true,
  };

  if (promos) {
    return (
      <div className="flex snap-x snap-mandatory overflow-x-auto">
        {promos
          ?.slice()
          .sort(({ position: orderA }, { position: orderB }) => orderA - orderB)
          .map(({ name, image }, index) => (
            <PromoCard
              key={index}
              image={image}
              name={name}
              index={index}
              staticImage={false}
            />
          ))}
      </div>
    );
  } else {
    return null;
  }
}
