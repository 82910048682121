import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames, useCloudinaryImage } from '../helpers';
import { Product, ProductObj } from '../types';

interface Props {
  product: Product;
  selectedProductIds: Set<number>;
  addItemToOrder: (product: ProductObj) => unknown;
  handleOpenProductModal: (product: Product) => unknown;
  removeItemFromOrder: (product: ProductObj) => unknown;
}

export default function GridProductCardUnconditional({
  product,
  addItemToOrder,
  handleOpenProductModal,
  selectedProductIds,
  removeItemFromOrder,
}: Props) {
  const { name, image, price, description, totalSize } = product;
  const productImage = useCloudinaryImage(image);
  const isSelected = selectedProductIds.has(+product.id);

  return (
    <div className="relative overflow-hidden rounded-xl bg-white shadow-md hover:shadow-xl">
      <div className="pb-6" onClick={() => handleOpenProductModal(product)}>
        <img
          src={productImage}
          alt={name}
          className={classNames(
            isSelected ? 'opacity-30' : 'opacity-100',
            'rounded-t-xvl pointer-events-none mx-auto mb-3 h-32 w-full object-cover group-hover:opacity-75'
          )}
        />
        <div className="flex justify-between px-4 text-lg font-bold text-gray-900">
          <div className={classNames(undefined)}>{`$${price}`}</div>
        </div>
        <div>
          <div className={classNames('px-4 py-1 text-gray-900')}>{name}</div>
        </div>
      </div>
      <div className="mt-4 mb-2 flex w-4 items-center justify-between px-4">
        <div
          className={classNames(
            'absolute bottom-3 w-7/12 truncate text-gray-400'
          )}
        >
          {totalSize ?? description}
        </div>
        <div className="mr-4" />
        {isSelected ? (
          <button
            type="button"
            onClick={
              product.addons.length === 0
                ? () => removeItemFromOrder({ product, addons: [] })
                : () => handleOpenProductModal(product)
            }
            className="absolute bottom-2 right-2 inline-flex items-center rounded-full border  bg-indigo-600 p-2.5  shadow-sm shadow-lg focus:outline-none"
          >
            <FontAwesomeIcon
              icon={faCheck}
              aria-hidden="true"
              size="sm"
              className="font-bold text-white"
            />
          </button>
        ) : (
          <button
            type="button"
            onClick={
              product.addons.length === 0
                ? () => addItemToOrder({ product, addons: [] })
                : () => handleOpenProductModal(product)
            }
            className="absolute bottom-2 right-2 inline-flex items-center rounded-full border border-gray-200 bg-white p-2.5 shadow-sm focus:outline-none"
          >
            <FontAwesomeIcon
              icon={faPlus}
              aria-hidden="true"
              size="sm"
              className="font-thin text-gray-500"
            />
          </button>
        )}
      </div>
    </div>
  );
}
