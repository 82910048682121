import moment from 'moment';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';
import ProductCard from './product-card';
import SubtotalCard from './subtotal-card';

interface Props {
  items: {
    date: moment.Moment;
    products: OrderState[];
  }[];
  removeItem: (item: OrderState) => void;
}

export default function DateProductsList({ items, removeItem }: Props) {
  return (
    <div className="flex flex-col px-6">
      {items.map(({ date, products }) => (
        <>
          <SubtotalCard date={date} products={products} />
          {products.map((state) => (
            <ProductCard removeItem={() => removeItem(state)} state={state} />
          ))}
          {/* <div className="mt-7 rounded-lg border border-gray-200" /> */}
        </>
      ))}
    </div>
  );
}
