import React from 'react';
import { isIOS, isAndroid } from 'react-device-detect';
import Login from './login';

const IOS_URL = 'https://apps.apple.com/ca/app/cubbi-2-0/id6444135609';
const ANDROID_URL = 'https://play.google.com/store/apps/details?id=com.cubbi';

class RedirectToAppStore extends React.Component {
  componentDidMount() {
    this.redirectToApp();
  }

  redirectToApp() {
    if (isIOS) {
      window.location.href = IOS_URL;
    } else if (isAndroid) {
      window.location.href = ANDROID_URL;
    }
  }

  render() {
    return <Login />;
  }
}

export default RedirectToAppStore;
