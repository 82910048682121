import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Dispatch, SetStateAction } from 'react';
import {
  faTag,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET_DISCOUNT_VERIFY } from '../services/payment.service';
import { ProductWithAddons, CheckDiscount } from '../types';
import { useLazyQuery } from '@apollo/client';
interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setAddingCode: Dispatch<SetStateAction<boolean>>;
  fridgeId?: number;
  user?: string;
  productIds?: number[];
  orderDates?: string[];
  productWithAddons?: ProductWithAddons[];
}

export default function DiscountModal({
  open,
  setOpen,
  setAddingCode,
  fridgeId,
  user,
  productIds,
  orderDates,
  productWithAddons,
}: Props) {
  const [discountCode, setDiscountCode] = useState<string>('');
  const [displayCode, setDisplayCode] = useState<string>('');
  const [existsButEmpty, setExistsButEmpty] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const datesToCheck = orderDates?.map((e) => new Date(e).toISOString());
  const [checkDiscount] = useLazyQuery<{
    checkDiscountCode: CheckDiscount;
  }>(GET_DISCOUNT_VERIFY);

  function handleClose() {
    handleDiscountCode();
    setOpen(false);
    setDisplayCode('');
    setDisabled(true);
  }

  function handleCloseWithoutCheck() {
    setOpen(false);
    setSubmitted(false);
    setDisplayCode('');
    setDisabled(true);
  }
  async function handleDiscountCode() {
    const { data: discountData } = await checkDiscount({
      variables: {
        products: productWithAddons ? productWithAddons : [],
        fridges: [fridgeId],
        user,
        discountCode,
        dates: datesToCheck,
      },
    });
    if (discountData?.checkDiscountCode.usableCode === true) {
      setOpen(false);
      setSubmitted(false);
      localStorage.setItem('discountCode', discountCode);
      setAddingCode(true);
    } else if (
      discountData?.checkDiscountCode.codeExists &&
      discountData.checkDiscountCode.usableCode === false
    ) {
      setExistsButEmpty(true);
      setDisplayCode(discountCode);
      setSubmitted(true);
      setDisabled(true);
    } else if (discountData?.checkDiscountCode.usableCode === false) {
      setSubmitted(true);
      setDisplayCode(discountCode);
      setDisabled(true);
    }
  }

  function handleEnableButton(val: string) {
    if (disabled) {
      setDisabled(false);
    }
    setDiscountCode(val);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block w-11/12 transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle">
              <div>
                <div className="flex items-center justify-center">
                  <div className="flex h-12 w-12 items-center justify-center rounded-full bg-indigo-200">
                    <FontAwesomeIcon icon={faTag} className="text-indigo-600" />
                  </div>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="pb-2 text-lg font-medium leading-6 text-gray-900"
                  >
                    Enter Your Code
                  </Dialog.Title>
                  <div className="">
                    <input
                      onChange={(e) => handleEnableButton(e.target.value)}
                      className="w-full rounded-md border px-2 py-2 text-base"
                      placeholder="Promo Code"
                    ></input>
                  </div>
                </div>
              </div>
              {submitted && !existsButEmpty && (
                <div className="pt-4">
                  <div className="flex h-[72px] bg-yellow-50">
                    <div className="flex flex-row px-4 py-4">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="flex items-center justify-start text-yellow-400"
                      />
                      <p className="items-center justify-center px-2 text-sm text-yellow-800">
                        {displayCode.toUpperCase() + ' '} Promo code isn't
                        available to you right now
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {submitted && existsButEmpty && (
                <div className="pt-4">
                  <div className="flex h-[72px] bg-yellow-50">
                    <div className="flex flex-row px-4 py-4">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="flex items-center justify-start text-yellow-400"
                      />
                      <p className="items-center justify-center px-2 text-sm text-yellow-800">
                        {displayCode + ' '} Promo code does not apply to current
                        items
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-5 sm:mt-6">
                {!disabled ? (
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={handleDiscountCode}
                  >
                    Redeem Code
                  </button>
                ) : (
                  <button
                    disabled={true}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white opacity-50 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                  >
                    Redeem Code
                  </button>
                )}

                <div className="py-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={handleCloseWithoutCheck}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
