import { useOrderById } from '../services/orders.service';
import { useLocation } from 'react-router-dom';
import LoadingPage from '../screens/loading';
import ErrorPage from '../screens/error';
import TopNavigation from '../navigation/top-navigation';
import BookingSelectItem from './booking-select-item';
import moment from 'moment';
import { BookingById, ProductWithAddons } from '../types';
import React, { useEffect, useRef } from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

interface orderState {
  orderId: number;
}
export default function BookingSelect() {
  const location = useLocation();
  const state = location.state as orderState;
  const { order, bookings, loading, error } = useOrderById(state.orderId);
  const dayToday = moment();
  const pastCutoff = useRef(false);
  const dayTomorrow = moment().add(1, 'days');
  const navigate = useNavigate();

  function handleCutoffWindow(item: BookingById) {
    let day = parseInt(item.startAt.substring(8, 10));
    let month = parseInt(item.startAt.substring(5, 7));
    let year = parseInt(item.startAt.substring(0, 4));
    if (item.cancelledAt && item.cancelledAt.length > 0) {
      pastCutoff.current = true;
      return 'skip';
    }

    if (
      (day <= dayToday.date() && dayToday.month() + 1 === month) ||
      (dayTomorrow.date() === day &&
        dayTomorrow.month() + 1 === month &&
        dayTomorrow.hour() >= 18) ||
      (day <= dayToday.date() && month <= dayToday.month() + 1) ||
      (month < dayToday.month() + 1 && dayToday.year() === year)
    ) {
      pastCutoff.current = true;
      return 'skip';
    } else {
      return 'continue';
    }
  }

  function handlePassProducts(item: BookingById) {
    let list = item.bookingItems;
    let result: ProductWithAddons[] = [];
    if (list) {
      list.forEach(({ addons, productId, quantity }) => {
        if (quantity > 1) {
          for (let i = 0; i < quantity; i++) {
            result = [
              ...result,
              { product: productId, addons: addons.map(({ id }) => id) },
            ];
          }
        } else {
          result = [
            ...result,
            { product: productId, addons: addons.map(({ id }) => id) },
          ];
        }
      });
    }
    return result;
  }

  useEffect(() => {
    if (
      bookings &&
      bookings[0] &&
      bookings?.length === 1 &&
      pastCutoff.current !== true
    ) {
      let result = handlePassProducts(bookings[0]);
      let id = bookings[0].id;
      navigate('/reschedule', {
        state: { bookingIds: [id], products: result },
      });
    }
  }, [bookings]);

  if (loading) {
    return <LoadingPage />;
  }
  if (!order || error || !bookings) {
    return <ErrorPage error={'Cannot find order'} />;
  }

  return (
    <>
      <TopNavigation title="Reschedule" />
      <div className="p-4">
        <div className="flex justify-center text-gray-900">
          Select a booking to reschedule
        </div>
        {React.Children.toArray(
          bookings.map((item, index) => {
            if (handleCutoffWindow(item) === 'skip') {
              return <></>;
            }
            return (
              <div className="py-2" key={item.id}>
                <BookingSelectItem key={`${item.id}${index}`} item={item} />
              </div>
            );
          })
        )}
        {pastCutoff.current === true && (
          <>
            <div className="pt-2"></div>
            <div className="flex sm:justify-center">
              <div className="flex w-full bg-yellow-100 p-4 sm:w-1/2">
                <div className="flex flex-row text-sm text-yellow-700">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="mt-1"
                  />
                  <span className="pl-2">
                    Don't see all the bookings? One or more bookings are
                    cancelled or past the cutoff to reschedule.
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
