import Dropdown from '../components/dropdown';
import remindersImage from '../img/onboarding/reminder.png';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CHANGE_USER, useMe } from '../services/user.service';
import { handleDay } from '../helpers';
import { useMutation } from '@apollo/client';
import Notification from '../components/notification';
import { NotificationType } from '../constants';
import { toast } from 'react-toastify';

export default function Reminders() {
  const { paymentIntent, clientSecret } = useParams();
  const [dayChanged, setDayChanged] = useState<string | undefined>('Sunday');
  const [loading, setLoading] = useState(false);
  const [updateUser] = useMutation<{ lastName: string }>(CHANGE_USER);
  const navigate = useNavigate();
  const { data: me, refetch } = useMe();
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  function handleRerouting() {
    if (clientSecret) {
      let paymentIntent = clientSecret;
      navigate(`/order-success/${paymentIntent}`);
    }
    if (paymentIntent) {
      navigate(`/order-success/${paymentIntent}`);
    }
  }

  const handleError = () =>
    toast(
      <Notification
        type={NotificationType.Error}
        message={'Error setting up notifications'}
        title="Error"
      />,
      { position: 'top-right', autoClose: 5000 }
    );

  const handleSuccess = () =>
    toast(
      <Notification
        type={NotificationType.Success}
        message={`Reminders have been setup for ${dayChanged} at 5:00PM`}
        title="Success"
      />,
      { position: 'top-right', autoClose: 5000 }
    );

  async function handleSetupReminders() {
    if (dayChanged && me) {
      let day = handleDay(dayChanged);
      try {
        setLoading(true);
        const { data, errors } = await updateUser({
          variables: {
            id: me?.pk,
            input: {
              receiveReminders: 'True',
              reminderTime: '17:00:00',
              reminderDays: day,
              smsReminders: true,
            },
          },
        });
        setLoading(false);
        if (data) {
          await refetch();
          handleSuccess();
          handleRerouting();
        } else {
          handleError();
        }
      } catch (e) {
        handleError();
      }
    }
  }
  return (
    <>
      <div className="flex flex-col px-6 py-6 sm:items-center sm:justify-center">
        <div className="flex justify-center pb-4 text-lg font-medium text-gray-900">
          Payment Processing...
        </div>
        <div className="">
          <img
            src={remindersImage}
            className="max-h-[445.5px]"
            alt="reminders"
          ></img>
        </div>
        <div className="pb-4 text-[42px] font-bold text-gray-700">
          Remind me!
        </div>
        <div className="text-gray-700">
          We get it - life is busy! Cubbi can help by sending you reminders so
          you never miss a meal.
        </div>
        <div className="flex w-full justify-center py-4">
          <Dropdown
            title={`Remind me on: ${dayChanged}s`}
            items={days}
            functionToSend={setDayChanged}
            changed={dayChanged !== 'Sunday' ? true : false}
            width={'w-full sm:w-80'}
            widthOfDropdown={'w-full'}
          />
        </div>
        <div>
          <button
            type="submit"
            onClick={handleSetupReminders}
            className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-80"
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
            {loading ? <p>Loading...</p> : <p>Set me up!</p>}
          </button>
        </div>
        <div className="py-4">
          <button
            type="submit"
            className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-100 p-3 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-80"
            onClick={handleRerouting}
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
            {'Nah, maybe later'}
          </button>
        </div>
      </div>
    </>
  );
}
