import { gql, useQuery } from '@apollo/client';
import { Fridge } from '../types';

export const AVAILABLE_FRIDGES = gql`
  query availableFridges($user: String!, $fromTime: DateTime!) {
    availableFridges(user: $user, fromTime: $fromTime) {
      id
      name
      serialNumber
      image
      app
      deliveryFee
      prestock
      location {
        floor
        room
        zip
        building
        state
        additionalDirectionsText
        additionalDirections
        latitude
        longitude
        street
        city
        description
        building
      }
      owner {
        name
        logo
        fridges {
          edges {
            node {
              id
            }
          }
        }
      }
      isOnline
    }
  }
`;

export const AVAILABLE_FRIDGE_DAYS = gql`
  query unavailableFridgeDays(
    $month: Int
    $fridgeList: [Int]
    $user: String
    $year: Int
  ) {
    unavailableFridgeDays(
      month: $month
      user: $user
      fridgeList: $fridgeList
      year: $year
    ) {
      day
      month
      available
      spaceAvailable
      year
    }
  }
`;

export const OPEN_FRIDGE = gql`
  mutation openCubbi($input: OpenCubbiInput!) {
    openCubbi(input: $input) {
      success
    }
  }
`;

export const OPEN_FRIDGE_ALL = gql`
  mutation openFridge($input: OpenFridgeInput!) {
    openFridge(input: $input) {
      success
    }
  }
`;

export function useAvailableFridgeDates(
  month: number,
  fridgeList: [number],
  email: string,
  year: number
) {
  const { data, loading, error } = useQuery<{
    unavailableFridgeDays: Array<{
      day: number;
      month: number;
      available: boolean;
      spaceAvailable: number;
      year: number;
    }>;
  }>(AVAILABLE_FRIDGE_DAYS, {
    variables: {
      month,
      fridgeList,
      user: email,
      year,
    },
    skip: fridgeList === [0],
  });
  return {
    unavailableDates: data?.unavailableFridgeDays,
    loading,
    error,
  };
}

export function useAvailableFridges(fromTime: string, email?: string) {
  const { data, loading, error, refetch } = useQuery<{
    availableFridges: Fridge[];
  }>(AVAILABLE_FRIDGES, {
    variables: {
      fromTime,
      user: email,
    },
    skip: email === undefined || fromTime === '',
  });
  return { fridges: data?.availableFridges, loading, error, refetch };
}
