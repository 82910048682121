export default function CarouselCardSkeleton() {
  const constList = [0, 0, 0, 0, 0];
  return (
    <div className="flex animate-pulse snap-x snap-mandatory overflow-x-auto pr-4">
      <div className="flex-shrink-0 snap-center">
        <div className="flex flex-row items-center py-4 px-3">
          {constList.map((e, i) => (
            <div
              className="mr-4 h-40 w-80 items-center overflow-clip rounded-xl bg-clip-border shadow-xl"
              key={i}
            >
              <div className="p-4">
                <div className="h-2 w-full bg-slate-400" />
              </div>
              <div className="p-4">
                <div className="h-2 w-full bg-slate-400" />
              </div>
              <div className="p-4">
                <div className="h-2 w-full bg-slate-400" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
