import { useEffect, useMemo, useState } from 'react';

export function useModalToggle() {
  const [visible, setVisible] = useState(false);
  function onCancel() {
    setVisible(false);
  }
  function open() {
    setVisible(true);
  }
  function close() {
    setVisible(false);
  }
  return {
    props: {
      visible,
      onCancel,
    },
    open,
    close,
  };
}

export function useCheckMobileScreen() {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  return width <= 768;
}

export default function useOnScreen(ref: any) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    []
  );
  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);
  return isIntersecting;
}
