import { useState } from 'react';
import PersistentStorage, { PersistentStorageItem } from './persistent-storage';

export function usePersistentStorageItem<K extends string, V>(
  item: PersistentStorageItem<K, V>,
  immediateDefaultValue: V
): [V, () => void];
export function usePersistentStorageItem<K extends string, V>(
  item: PersistentStorageItem<K, V>,
  immediateDefaultValue?: V
): [V | null, () => void];
export function usePersistentStorageItem<K extends string, V>(
  item: PersistentStorageItem<K, V>,
  immediateDefaultValue?: V
): [V | null, () => void] {
  const [persistentStorageValue, setPersistentStorageValue] = useState(
    getPersistentStorageValue()
  );

  function getPersistentStorageValue() {
    return PersistentStorage.get(item) || immediateDefaultValue || null;
  }

  function refreshPersistentStorageValue() {
    setPersistentStorageValue(getPersistentStorageValue());
  }

  return [persistentStorageValue, refreshPersistentStorageValue];
}
