export default function FeaturedCardSkeleton() {
  const constList = [0, 0, 0, 0, 0, 0, 0];
  return (
    <div className="flex animate-pulse">
      <div className="flex snap-x snap-mandatory space-x-3 overflow-x-auto px-3">
        {constList.map((e, i) => (
          <div
            className="mb-4 h-[236px] w-36 flex-shrink-0 snap-center items-center rounded-xl bg-white shadow-lg"
            key={i}
          ></div>
        ))}
      </div>
    </div>
  );
}
