import moment, { Moment } from 'moment';
import { RefObject } from 'react';
import { getNodeDataFromEdges } from '../helpers';
import { CategoryRefType } from '../screens/kitchen-v2';
import { Product, ProductObj } from '../types';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';
import GridProductCard from './grid-product-card';

interface Props {
  category: CategoryRefType;
  handleOpenProductModal: (product: Product) => void;
  addItemToOrder: (product: ProductObj) => void;
  currentDate: Moment;
  showUnavailable: boolean;
  orderItems: OrderState[];
  searchValue: string;
  searchType: string | undefined;
}

export default function SearchCategoryDisplay({
  category: { name, filteredProducts: products, ref },
  showUnavailable,
  handleOpenProductModal,
  addItemToOrder,
  currentDate,
  orderItems,
  searchValue,
  searchType,
}: Props) {
  const currentDayFormatted = currentDate.format('dddd');
  const currentDayByNumber = currentDate.date();
  const today = moment().date();
  const tomorrow = moment().add(1, 'days');
  const tomorrowByNumber = tomorrow.date();
  const tomorrowByMonth = tomorrow.month();
  const todayMonth = moment().month();
  const hour = moment().hour();
  const currentDayByMonth = currentDate.month();
  const filteredProducts = handleFilterProducts();

  function handleFilterProducts() {
    if (searchType === 'Kitchen') {
      return products.filter(({ vendor }) =>
        vendor.toLowerCase().match(searchValue.toLowerCase())
      );
    } else if (searchType === 'Diet') {
      return products.filter(
        (e) =>
          !e.allergens.some(
            (e) => e.name.toLowerCase() === searchValue.toLowerCase()
          )
      );
    } else if (searchType === 'Dish') {
      return products.filter(({ name }) =>
        name.toLowerCase().match(searchValue.toLowerCase())
      );
    } else {
      return products.filter(({ name }) =>
        name.toLowerCase().match(searchValue.toLowerCase())
      );
    }
  }

  return (
    <div ref={ref as RefObject<HTMLDivElement>} className="px-4">
      {filteredProducts &&
        filteredProducts.length > 0 &&
        filteredProducts.filter((e) => e.isAvailable).length > 0 && (
          <div className="relative my-4 items-center">
            <div
              className="absolute inset-0 ml-20 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-start">
              <span className=" pr-3 text-lg font-medium text-gray-900">
                {name}
              </span>
            </div>
          </div>
        )}
      <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8">
        {filteredProducts &&
          filteredProducts
            .filter(({ isAvailable }) => isAvailable)
            .filter(({ availableDates }) => {
              if (showUnavailable) {
                return true;
              }
              if (
                today === currentDayByNumber &&
                todayMonth === currentDayByMonth
              ) {
                if (
                  availableDates &&
                  availableDates.includes(currentDayFormatted)
                ) {
                  return true;
                } else {
                  return false;
                }
              } else if (
                tomorrowByNumber === currentDayByNumber &&
                tomorrowByMonth === currentDayByMonth &&
                hour >= 18
              ) {
                if (
                  availableDates &&
                  availableDates.includes(currentDayFormatted)
                ) {
                  return true;
                } else {
                  return false;
                }
              }
              if (availableDates) {
                return availableDates.includes(currentDayFormatted);
              }
              return false;
            })
            .map((product) => (
              <GridProductCard
                key={product.id}
                product={product}
                currentDate={currentDate}
                categoryName={name}
                handleOpenProductModal={handleOpenProductModal}
                addItemToOrder={addItemToOrder}
                orderItems={orderItems}
              />
            ))}
      </div>
    </div>
  );
}
