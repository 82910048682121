import { useMutation } from '@apollo/client';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import Alert, { AlertType } from '../components/alert';
import { classNames } from '../helpers';
import { CHANGE_USER, CREATE_CREDIT_CARD } from '../services/user.service';
import { CreditCard, User } from '../types';

interface Props {
  defaultCard: CreditCard | null;
  close: () => void;
  user: User;
  setCreditCard: (creditCard: CreditCard) => void;
  onSuccess: () => void;
}

export default function CreditCardForm({
  defaultCard,
  setCreditCard,
  onSuccess,
  close,
  user,
}: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [updateUser] = useMutation(CHANGE_USER);
  const [createCreditCard] = useMutation(CREATE_CREDIT_CARD);
  async function handleSubmit() {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement('card');
    if (!cardElement) {
      setErrors([...errors, 'Please enter a credit card']);
      return;
    }
    setLoading(true);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    if (!paymentMethod || error) {
      setLoading(false);
      setErrors(['Please enter a payment method']);
      return;
    }
    try {
      const { data } = await createCreditCard({
        variables: {
          input: {
            paymentMethodId: paymentMethod.id,
            customer: user.email,
          },
        },
      });
      const defaultSelectedCardId =
        data.createCreditcarddetails.creditcarddetails.id;

      await updateUser({
        variables: {
          id: user.pk,
          input: { defaultSelectedCard: defaultSelectedCardId },
        },
      });
      setCreditCard(data.createCreditcarddetails.creditcarddetails);
      close();
      onSuccess();
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      setErrors([e.graphQLErrors[0].message]);
      return;
    }
  }

  return (
    <div className="p-4">
      <div className="flex items-center justify-between">
        <div className="pointer-cursor" onClick={close}>
          <FontAwesomeIcon icon={faTimes} size="lg" className="text-gray-600" />
        </div>

        <div className="font-medium">Add New Payment Method</div>
        <div />
      </div>
      {errors.length > 0 && (
        <div className="mt-2">
          <Alert type={AlertType.Error} messages={errors} />
        </div>
      )}
      <CardElement
        options={{
          hidePostalCode: true,
        }}
        className="mb-5 mt-8 block w-full  rounded-md border border-gray-300 p-5 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      />

      <button
        type="button"
        onClick={handleSubmit}
        disabled={loading}
        className={classNames(
          loading ? 'bg-indigo-300' : 'bg-indigo-600',
          'inline-flex w-full items-center justify-center rounded-md border border-transparent  px-4 py-3.5 text-center text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        )}
      >
        {loading ? 'Loading...' : 'Add Card'}
      </button>
    </div>
  );
}
