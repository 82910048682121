import { useNavigate } from 'react-router-dom';
import { Product } from '../types';
import { useCloudinaryImage } from '../helpers';

interface Props {
  product: Product;
}

export default function PrestockCheckoutItem({ product }: Props) {
  const navigate = useNavigate();
  return (
    <div className="px-4 pt-5">
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold leading-6">Order Summary</div>
      </div>
      <div className="flex flex-row pt-4">
        <div className="h-24 w-24 rounded-md">
          <img
            src={useCloudinaryImage(product.image)}
            className="h-24 w-24 rounded-md object-cover"
          ></img>
        </div>
        <div className="flex flex-col justify-between px-2">
          <div>
            <div className="font-normal leading-5 text-gray-900">
              {product.name}
            </div>
            <div className="text-sm font-medium leading-5 text-gray-600">
              ${product.price}
            </div>
          </div>
          <div
            className="text-sm font-medium text-indigo-700"
            onClick={() => navigate(-1)}
          >
            Remove
          </div>
        </div>
      </div>
    </div>
  );
}
