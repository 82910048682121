import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import SelectManyAddon from '../add-ons/select-many-addon';
import SingleAddon from '../add-ons/single-addon';
import TwoThreeAddon from '../add-ons/two-three-addon';
import Alert, { AlertType } from '../components/alert';
import { SelectionType } from '../constants';
import { classNames, getSelectionType } from '../helpers';
import NutritionInfo from '../product-info-modal/nutrition-info';
import ProductInfoHeader from '../product-info-modal/product-info-header';
import { AddonGroup, Product, ProductObj } from '../types';

interface Props {
  product: Product;
  close: () => void;
  addItemToOrder: (product: ProductObj) => unknown;
  removeItemFromOrder: (product: ProductObj) => unknown;
  isSelected: boolean;
}

type objectType = {
  [key: string]: Array<number>;
};

//Alternate Product info for meal subscriptions
export default function ProductInfoUnconditional({
  product,
  close,
  addItemToOrder,
  isSelected,
  removeItemFromOrder,
}: Props) {
  const [errors, setErrors] = useState(new Set());
  const [lengthError, setLengthError] = useState(false);
  const addError = (type: SelectionType) => {
    setErrors((prevState) => new Set([...prevState, type]));
  };
  const resetErrors = () => {
    setErrors(new Set());
  };

  function getSelectionComponent(type: SelectionType, addon: AddonGroup) {
    switch (type) {
      case SelectionType.A_1:
        return <SingleAddon addon={addon} />;
      case SelectionType.A_2:
        return <TwoThreeAddon addon={addon} />;
      case SelectionType.A_3:
        return <SelectManyAddon addon={addon} />;
      default:
        return null;
    }
  }

  function removeItem(item: Product) {
    removeItemFromOrder({ product: item, addons: item.addons });
    resetErrors();
    close();
    window.event?.preventDefault();
  }
  function handleBack() {
    resetErrors();
    close();
  }

  async function handleSubmit(values: objectType) {
    resetErrors();
    if (product.addons.length === 0) {
      addItemToOrder({ product, addons: [] });
      return;
    }
    if (
      (!values.A_1 && !values.A_2) ||
      (values.A_1 && values.A_1.length === 0) ||
      (values.A_2 && values.A_2.length === 0)
    ) {
      setLengthError(true);
      return;
    }
    setLengthError(false);
    for (var key in values) {
      if (key === SelectionType.A_1 && Object.keys(values).length !== 1) {
        addError(SelectionType.A_1);
        return;
      }
      if (key === SelectionType.A_2 && Object.keys(values).length !== 2) {
        addError(SelectionType.A_2);
        return;
      }
    }
    const addonIds = Object.keys(values).flatMap((key) => {
      if (values[key] instanceof Array) {
        return values[key].map((id) => Number(id));
      } else {
        return Number(values[key]);
      }
    });
    const addons = product.addons
      .flatMap((addonGroup) => {
        const selectedAddons = addonGroup.addons.filter((addon) =>
          addonIds.includes(+addon.id)
        );
        return selectedAddons.length
          ? [{ ...addonGroup, addons: selectedAddons }]
          : [];
      })
      .flat();
    addItemToOrder({ product, addons });
    close();
  }

  const availableDates = product.availableDates.split(',');
  const weekArray = moment
    .weekdays()
    .filter((day) => day !== 'Sunday' && day !== 'Saturday');
  return (
    <div className="flex min-h-screen flex-col">
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        <Form className="bg-white">
          <ProductInfoHeader
            image={product.image}
            name={product.name}
            close={close}
          />
          {product.availableDates !== null &&
            product.availableDates !== undefined &&
            product.availableDates.length !== 40 && (
              <div
                className="flex items-center border-l-4 border-red-500 bg-red-100 p-4 "
                role="alert"
              >
                <div>
                  <div className="mb-1 flex items-center">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="mr-4 text-red-400"
                    />
                    <p className="text-sm font-medium  text-red-800">
                      This product is only available for delivery on certain
                      days. It will be skipped in your meal plan until the next
                      available date.
                    </p>
                  </div>
                  <div className="ml-9 mb-2 flex">
                    {weekArray.map((date: string) => {
                      const includes = availableDates.includes(date);
                      return (
                        <div
                          className={classNames(
                            'mr-3 text-sm',
                            includes ? 'font-bold text-red-800' : 'text-red-300'
                          )}
                        >
                          {date.slice(0, 1)}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          <NutritionInfo
            name={product.name}
            description={product.description}
            allergens={product.allergens}
            nutritionFacts={product.nutritionfacts}
            almostSoldOut={false}
            totalSize={product.totalSize}
            quantity={product.quantity}
          />
          <div className="flex flex-col">
            {lengthError && (
              <Alert
                type={AlertType.Error}
                title="Required Selection Error"
                messages={[
                  'Missing required addon selection. Please select all required addons.',
                ]}
              />
            )}
            <div className="mx-5">
              {product.addons.map((addon, index) => {
                const error = errors.has(addon.selection);
                return (
                  <div key={index}>
                    <div className="text-base font-medium leading-6 text-gray-700">
                      {addon.description}
                    </div>
                    <div className="flex flex-row justify-between">
                      <div className="text-sm font-normal leading-5 text-gray-500">
                        {getSelectionType(addon.selection)}
                      </div>
                      <div className="text-sm font-normal leading-5 text-gray-500">
                        {addon.selection === SelectionType.A_3
                          ? undefined
                          : 'Required'}
                      </div>
                    </div>
                    {error && (
                      <div className="mt-2">
                        <Alert
                          type={AlertType.Error}
                          title="Required Selection Error"
                          messages={
                            addon.selection === SelectionType.A_1
                              ? [
                                  `Please select at least one option for ${addon.name}`,
                                ]
                              : [
                                  `Please select at least two options for ${addon.name}`,
                                ]
                          }
                        />
                      </div>
                    )}
                    <div className="my-6">
                      {getSelectionComponent(addon.selection, addon)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="pb-96" />

          <div className="fixed inset-x-0 bottom-0 border-t bg-white">
            <footer className="relative inset-x-0 bottom-0 z-50">
              <div className="inline-flex w-full content-center justify-between border border-transparent px-6 pt-4 pb-8">
                <div
                  className={classNames(
                    isSelected ? 'w-1/2 pr-4' : 'w-1/2 pr-4'
                  )}
                >
                  <button
                    className="inline-flex w-full items-center justify-center rounded-md bg-indigo-100 px-5 py-3 text-sm font-medium  text-indigo-700 shadow-sm hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    type={'button'}
                    onClick={() => handleBack()}
                  >
                    Go back
                  </button>
                </div>
                {!isSelected ? (
                  <div className="w-1/2">
                    <button
                      type="submit"
                      className="inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-5 py-3 text-sm font-medium  text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      + Add
                    </button>
                  </div>
                ) : (
                  <div className="w-1/2">
                    <button
                      type={'button'}
                      className="inline-flex w-full items-center justify-center rounded-md border border-gray-200 bg-white px-5 py-3 text-sm font-medium text-red-500 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
                      onClick={() => removeItem(product)}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </div>
            </footer>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

{
  /* <div className="flex min-h-screen flex-col">
  <Formik
    initialValues={{}}
    validate={handleValidation}
    onSubmit={handleSubmit}
  >
    <Form className="bg-white">
      <ProductInfoHeader
        image={product.image}
        name={product.name}
        close={close}
      />
      {soldOut && (
        <div
          className="flex items-center border-l-4 border-red-500 bg-red-100 p-4 "
          role="alert"
        >
          <div>
            <div className="mb-1 flex items-center">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="mr-4 text-red-400"
              />
              <div className="flex">
                <p className="mr-1 text-sm font-medium text-red-800">
                  Sold Out {product.availableEndDate && 'until'}
                </p>
                <p className="text-sm font-medium  text-red-800">
                  {moment(product.availableEndDate).format('MMM Do, YYYY')}
                </p>
              </div>
            </div>
            <a
              href="/date-select"
              className="ml-9  text-sm font-medium text-red-700"
            >
              Edit Delivery Dates →
            </a>
          </div>
        </div>
      )}
      {!isAvailableDay && !(todayLogic && tomorrowLogic) && (
        <div
          className="flex items-center border-l-4 border-red-500 bg-red-100 p-4 "
          role="alert"
        >
          <div>
            <div className="mb-1 flex items-center">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="mr-4 text-red-400"
              />
              <p className="text-sm font-medium  text-red-800">
                Invalid Delivery Day, this product is only available on:
              </p>
            </div>
            <div className="ml-9 mb-2 flex">
              {weekArray.map((date: string) => {
                const includes = availableDates.includes(date);
                return (
                  <div
                    className={classNames(
                      'mr-3 text-sm',
                      includes ? 'font-bold text-red-800' : 'text-red-300'
                    )}
                  >
                    {date.slice(0, 1)}
                  </div>
                );
              })}
            </div>
            <a
              href="/date-select"
              className="ml-9  text-sm font-medium text-red-700"
            >
              Edit Delivery Dates →
            </a>
          </div>
        </div>
      )}
      {isAvailableDay && (todayLogic || tomorrowLogic) && (
        <div
          className="flex items-center border-l-4 border-red-500 bg-red-100 p-4 "
          role="alert"
        >
          <div>
            <div className="mb-1 flex items-center">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="mr-4 text-red-400"
              />
              <p className="text-sm font-medium  text-red-800">
                Invalid Delivery Day, this product is not available for same day
                delivery:
              </p>
            </div>
            <div className="ml-9 mb-2 flex">
              {weekArray.map((date: string) => {
                const includes = availableDates.includes(date);
                return (
                  <div
                    className={classNames(
                      'mr-3 text-sm',
                      includes ? 'font-bold text-red-800' : 'text-red-300'
                    )}
                  >
                    {date.slice(0, 3)}
                  </div>
                );
              })}
            </div>
            <a
              href="/date-select"
              className="ml-9  text-sm font-medium text-red-700"
            >
              Edit Delivery Dates →
            </a>
          </div>
        </div>
      )}
      <NutritionInfo
        name={product.name}
        description={product.description}
        allergens={product.allergens}
        nutritionFacts={product.nutritionfacts}
        almostSoldOut={almostSoldOut}
        totalSize={product.totalSize}
        quantity={product.quantity}
      />

      <div className="flex flex-col">
        {lengthError && (
          <Alert
            type={AlertType.Error}
            title="Required Selection Error"
            messages={[
              'Missing required addon selection. Please select all required addons.',
            ]}
          />
        )}
        <div className="mx-5">
          {product.addons.map((addon, index) => {
            const error = errors.has(addon.selection);
            return (
              <div key={index}>
                <div className="text-base font-medium leading-6 text-gray-700">
                  {addon.description}
                </div>
                <div className="flex flex-row justify-between">
                  <div className="text-sm font-normal leading-5 text-gray-500">
                    {getSelectionType(addon.selection)}
                  </div>
                  <div className="text-sm font-normal leading-5 text-gray-500">
                    {addon.selection === SelectionType.A_3
                      ? undefined
                      : 'Required'}
                  </div>
                </div>
                {error && (
                  <div className="mt-2">
                    <Alert
                      type={AlertType.Error}
                      title="Required Selection Error"
                      messages={
                        addon.selection === SelectionType.A_1
                          ? [
                              `Please select at least one option for ${addon.name}`,
                            ]
                          : [
                              `Please select at least two options for ${addon.name}`,
                            ]
                      }
                    />
                  </div>
                )}
                <div className="my-6">
                  {getSelectionComponent(addon.selection, addon)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <SoldOutInfo
        cutOffDay={cutOffDay}
        productCutOff={product.cutoffTimeString}
      />
      {!greyOut && (
        <footer className="fixed inset-x-0 bottom-0 z-50">
          <div className="inline-flex w-full content-center justify-between border border-transparent bg-indigo-700 px-6 py-6  ">
            <div>
              <div className="font-medium text-white">
                ${Number(priceTotal).toFixed(2)}
              </div>
              <div className="mt-1.5 text-sm text-gray-300">
                {moment(currentDate).format('ddd MMM Do')}
              </div>
            </div>
            <div>
              <button
                type="submit"
                disabled={soldOut}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-5 py-3 text-sm font-medium text-indigo-500  shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Add 1 Item
              </button>
            </div>
          </div>
        </footer>
      )}
      <div className="mb-96" />
    </Form>
  </Formik>
</div>; */
}
