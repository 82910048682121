import { useNavigate } from 'react-router-dom';

export default function TOSText() {
  const navigate = useNavigate();
  function navigateToTOS() {
    window.location.replace('https://www.cub.bi/terms-of-service');
  }
  function navigateToPrivacy() {
    window.location.replace('https://www.cub.bi/privacy-policy');
  }
  return (
    <p className="items-center items-baseline justify-start self-center py-4">
      <span className="inline-flex leading-5 text-graphite-800">
        By signing up, you agree to our{' '}
      </span>
      <span
        onClick={() => navigateToTOS()}
        className="inline-flex px-1 text-primary-700"
      >
        Terms of service
      </span>
      <span className="inline-flex leading-5 text-graphite-800"> and</span>
      <span className="text-primary-700" onClick={() => navigateToPrivacy()}>
        Privacy Policy
      </span>
      <span className="leading-5 text-graphite-800">
        {' '}
        and give consent to receiving communications from Cubbi.
      </span>
    </p>
  );
}
