import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Notification from '../components/notification';
import { NotificationType } from '../constants';
import logo from '../img/logo-black-square.png';
import { FORGOT_PASSWORD } from '../services/auth.service';
import { ResetPassword } from '../types';
interface ResetForm {
  email: string;
}

export default function ForgotPassword() {
  const initialValue: ResetForm = { email: '' };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [forgotPassword] =
    useMutation<{ resetPassword: ResetPassword }>(FORGOT_PASSWORD);

  const handleError = (e?: string) =>
    toast(
      <Notification
        type={NotificationType.Error}
        message={e ? e : 'Error resetting password, please try again'}
        title="Error"
      />,
      { position: 'top-right' }
    );

  const handleSent = () =>
    toast(
      <Notification
        type={NotificationType.Success}
        message={'Please check your email for further instructions. '}
        title="Success"
      />,
      { position: 'top-right' }
    );

  async function handleResetPassword({ email }: ResetForm) {
    try {
      setLoading(true);
      const { data } = await forgotPassword({
        variables: { email: email.toLowerCase().trim() },
      });
      setLoading(false);
      if (!data || data?.resetPassword) {
        return handleError();
      }
      handleSent();
      navigate('/login');
    } catch (e) {
      setLoading(false);
      handleError();
    }
  }
  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img className="mx-auto h-16 w-auto" src={logo} alt="Workflow" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Forgot your password
            </h2>
          </div>
          <Formik
            initialValues={initialValue}
            onSubmit={(values, actions) => {
              handleResetPassword({ ...values });
              actions.setSubmitting(false);
            }}
          >
            <Form className="mt-8 space-y-6" action="#" method="POST">
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="-space-y-px rounded-md shadow-sm">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <Field
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Email address"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  {loading ? 'Loading...' : 'Submit'}
                </button>
              </div>
              <div className="flex items-center justify-center">
                <div className="text-sm">
                  <div
                    onClick={() => navigate('/login')}
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Go Back
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
