import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import NutritionLabelModal from '../components/nutrition-label-modal';
import { classNames } from '../helpers';
import { useModalToggle } from '../hooks';
import { Allergen, NutritionFacts } from '../types';

interface Props {
  name: string;
  description: string;
  allergens: Allergen[];
  nutritionFacts: NutritionFacts | null;
  totalSize: string | null;
  quantity: number | null;
  almostSoldOut: boolean;
}

export default function NutritionInfo({
  name,
  description,
  allergens,
  nutritionFacts,
  quantity,
  almostSoldOut,
  totalSize,
}: Props) {
  const { close: closeModal, props } = useModalToggle();
  const [expandedView, setExpandedView] = useState(false);

  const nutrition = [
    {
      name: 'Calories',
      emoji: '🔥',
      data: nutritionFacts?.calories,
    },
    {
      name: 'Protein',
      emoji: '🍗',
      data: nutritionFacts?.protein,
    },
    {
      name: 'Total Fat',
      emoji: '🥑',
      data: nutritionFacts?.fat,
    },
    {
      name: 'Carbs',
      emoji: '⚡',
      data: nutritionFacts?.carbs,
    },
  ];

  return (
    <>
      <NutritionLabelModal
        visible={props.visible}
        close={closeModal}
        nutritionImage={'xd'}
      />
      <div className="flex flex-col justify-start p-4">
        <div>
          <div className="text-xl font-bold">{name}</div>
          <div className="text-sm font-medium leading-5 text-gray-500">
            {totalSize ?? totalSize}
          </div>
          {/* {almostSoldOut && quantity !== null && (
            <span className="mt-4 mb-2 inline-flex justify-center rounded-xl bg-yellow-100 px-3 py-1 text-xs font-medium uppercase leading-4 text-yellow-800">
              {`Only ${quantity} left!`}
            </span>
          )} */}
        </div>

        <div className="mt-4 border-b pb-4">
          <div className={'text-sm leading-5 text-gray-500'}>{description}</div>
        </div>
        {nutritionFacts && (
          <>
            <div className="flex flex-row justify-between pt-6 text-sm font-medium leading-5 text-gray-400">
              <div>
                <p>
                  Nutrition info per{' '}
                  {nutritionFacts.servingSize ?? 'one serving size'}
                </p>
                <p>(before add-ons)</p>
              </div>
              <div className="text-sm font-medium leading-5 text-indigo-700">
                {expandedView ? (
                  <div onClick={() => setExpandedView(false)}>Hide -</div>
                ) : (
                  <div onClick={() => setExpandedView(true)}>View +</div>
                )}
              </div>
            </div>
            <div
              className={classNames(
                'ml-0.5 grid grid-cols-4 content-start py-6'
              )}
            >
              {nutrition.map(({ name, emoji, data }) => (
                <div>
                  <div className="text-sm font-medium leading-5 text-gray-500">
                    {name}
                  </div>
                  <div className="mt-1.5 text-sm font-normal text-gray-900">{`${emoji} ${data}`}</div>
                </div>
              ))}
            </div>
            <div className={classNames(!expandedView ? 'border-b' : undefined)}>
              {allergens.length > 0 && (
                <div
                  className={classNames(
                    'px-1 pb-5',
                    expandedView ? 'border-b' : undefined
                  )}
                >
                  <div className="text-gray-900">
                    <div className="text-sm">Allergen Warning</div>
                    <div className="mt-2 flex flex-wrap whitespace-pre">
                      {allergens.map(({ name }, i) => {
                        return (
                          <div className="py-1 pr-2" key={i}>
                            <div className="rounded-md bg-gray-100 px-3 text-gray-800">
                              {name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {nutritionFacts && expandedView && (
          <>
            <div className="bg-yellow-50 px-4 py-4">
              <div className="flex flex-row">
                <div className="flex items-center">
                  <FontAwesomeIcon
                    className=" text-sm text-amber-400"
                    icon={faExclamationTriangle}
                  />
                </div>
                <dt className="pl-4 text-sm font-medium leading-5 text-yellow-800">
                  Allergen Information & Disclaimer:
                </dt>
              </div>
              <dd className="pl-8 pt-2 text-sm leading-5 text-yellow-700">
                If you have severe allergies, Cubbi does not advise relying
                solely on the accuracy of the nutrition, ingredients, or
                allergen information provided. Ingredients are subject to change
                and we rely on supplier information to update labels.
              </dd>
            </div>
            {/* 
            put back when we have a proper image
            <div className="pt-4 pb-5">
              <div className="w-full px-4">
                <div
                  className="flex w-full flex-row justify-between rounded-md border border-gray-200 px-4 py-4"
                  onClick={open}
                >
                  <div className="flex items-center">
                    <PaperClipIcon className="h-4 w-4 text-gray-400" />
                    <dt className="pl-3">Nutrition Label</dt>
                  </div>
                  <dd className="flex justify-end text-indigo-700 hover:text-indigo-800">
                    Open
                  </dd>
                </div>
              </div>
            </div> */}
          </>
        )}
      </div>
    </>
  );
}
