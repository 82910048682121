import { useMutation } from '@apollo/client';
import { faArrowLeft, faCog, faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import Notification from '../components/notification';
import { NotificationType } from '../constants';
import { classNames } from '../helpers';
import DeliveryDetails from '../order-summary/delivery-details';
import ErrorPage from '../screens/error';
import LoadingPage from '../screens/loading';
import {
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  useSubByEmail,
} from '../services/subscriptions.service';
import { useMe } from '../services/user.service';
import { Product, Subscription } from '../types';
import MealSubscriptionItem from './meal-sub-product';
import NoFridgeModal from './no-fridge-modal';
import NoPaymentModal from './no-payment-modal';
import PaymentExistingModal from './payment-existing-modal';
import SuccessModal from './success-modal';
import {
  FridgeStatePersistentStorageItem,
  FRIDGE_STATE_KEY,
} from '../persistent-storage/fridge-state.persistent-storage';
import { usePersistentStorageItem } from '../persistent-storage/hooks';
import MealPlanNavigation from './meal-plan-navigation';

export default function MealPlan() {
  const { data: me, loading } = useMe();
  const {
    data: subscriptions,
    loading: subLoading,
    error,
  } = useSubByEmail(me?.email);

  const [enabled, setEnabled] = useState(false);
  const defaultVals = [false, false, false, false, false];
  const [selected, setSelected] = useState<boolean[]>(defaultVals);
  const [daysSelectedArray, setDaysSelectedArray] = useState<number[]>();
  const daysSelected = selected.filter((e) => e === true).length;
  const [rotate, setRotate] = useState(true);
  const [percentDiscount, setPercentDiscount] = useState(0);
  const [hasMealPlan, setHasMealPlan] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState<number>();
  const [paymentMethod, setPaymentMethod] = useState<number>();
  const [noPaymentModal, setNoPaymentModal] = useState(false);
  const [fridgeModal, setFridgeModal] = useState(false);
  const [paymentExistsModal, setPaymentExistsModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const startVals: Product[] = [];
  const [productsList, setProductsList] = useState<Product[]>(() => startVals);
  const productsInOrder = productsList.map(({ id, addons, position }) => ({
    product: id,
    addons: addons.map((e) => e.addons),
    position: position,
  }));
  const [createSubscription] =
    useMutation<{ createSubscription: Subscription }>(CREATE_SUBSCRIPTION);
  const [updateSubscription] =
    useMutation<{ updateSubscription: Subscription }>(UPDATE_SUBSCRIPTION);
  const days = ['M', 'T', 'W', 'T', 'F'];
  const navigate = useNavigate();

  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );
  const [selectedFridge] = usePersistentStorageItem(
    selectedFridgeState,
    undefined
  );

  //toggle on and off not sure if I should make this call the backend
  async function handleUpdateMealPlan() {
    if (subscriptions && subscriptions[0]) {
      try {
        const { data: updateSub, errors: updateSubErrors } =
          await updateSubscription({
            variables: {
              id: currentSubscription,
              deliveryDays: daysSelectedArray,
              input: {
                activate: !enabled,
              },
            },
          });
        if (updateSub && !updateSubErrors) {
          setEnabled(!enabled);
        }
      } catch (e) {}
    } else {
      try {
        const { data: updateSub, errors: updateSubErrors } =
          await updateSubscription({
            variables: {
              id: currentSubscription,
              input: {
                activate: !enabled,
              },
            },
          });
        if (updateSub && !updateSubErrors) {
          setEnabled(!enabled);
        }
      } catch (e) {}
    }
  }

  //adjust days that are selected
  function handleSelected(index: number) {
    if (selected[index]) {
      const newValues = selected.map((item, i) => (i === index ? false : item));
      setSelected(newValues);
      setHasChanged(true);
    } else {
      const newValues = selected.map((item, i) => (i === index ? true : item));
      setSelected(newValues);
      setHasChanged(true);
    }
  }

  // //toggle between rotate + randomize
  // function handleRotate() {
  //   setRotate(!rotate);
  // }

  //Remove an item from the list if it's found
  function handleRemoveItem(index: number) {
    if (productsList && productsList[index]) {
      const returnVal = productsList.filter((_, i) => i !== index);
      setProductsList(returnVal);
      setHasChanged(true);
    }
  }

  // //Default success notification
  // const handleSuccess = () =>
  //   toast(
  //     <Notification
  //       type={NotificationType.Success}
  //       message={'Your meal plan has been updated!'}
  //       title="Success"
  //     />,
  //     { position: 'top-right' }
  //   );

  //Modified success notification
  const handleDeactivateSuccess = () =>
    toast(
      <Notification
        type={NotificationType.Success}
        message={'Your meal plan has been deactivated'}
        title="Success"
      />,
      { position: 'top-right' }
    );

  //Error updating
  const handleError = () =>
    toast(
      <Notification
        type={NotificationType.Error}
        message={'Error updating meal plan'}
        title="Error"
      />,
      { position: 'top-right' }
    );

  async function updateSub() {
    try {
      const { data: updateSub, errors: updateSubErrors } =
        await updateSubscription({
          variables: {
            id: currentSubscription,
            input: {
              activate: enabled,
              mealSelection: 2,
              deliveryDays: daysSelectedArray,
              products: productsInOrder,
            },
          },
        });
      if (updateSub && !updateSubErrors) {
        return true;
      }
    } catch (e) {
      handleError();
      return false;
    }
    return false;
  }
  async function createSub() {
    try {
      await createSubscription({
        variables: {
          input: {
            user: me?.email,
            activate: enabled,
            mealSelection: 2,
            deliveryDays: daysSelectedArray,
            products: productsInOrder,
            fridge: selectedFridge ? selectedFridge.id : null,
          },
        },
      });
    } catch (e) {
      handleError();
    }
  }

  async function applyChanges() {
    const subscriptionExists = subscriptions && subscriptions[0];
    if (subscriptionExists) {
      await updateSub();
    } else {
      await createSub();
    }
    setHasChanged(false);
    navigate('/upcoming');
  }

  async function handleRedirect() {
    const subscriptionExists = subscriptions && subscriptions[0];
    //case for existing sub with an existing payment method
    if (subscriptionExists && !subscriptions[0].fridge) {
      setFridgeModal(true);
    } else if (subscriptionExists && !paymentMethod) {
      setNoPaymentModal(true);
    } else if (paymentMethod && subscriptions && subscriptions[0]) {
      setSuccessModal(true);
    }

    // if no subscription exists, create one else update it
    if (subscriptions && subscriptions[0]) {
      await updateSub();
    } else if (subscriptions && !subscriptions[0]) {
      await createSub();
    }
    setHasChanged(false);
  }

  //save the subscriptions state when users go to add meals.
  async function handleSaveSettings() {
    if (subscriptions && subscriptions.length > 0) {
      await updateSub();
    } else {
      await createSub();
    }
    navigate('/meal-kitchen');
  }

  //turn active = false and give user a notification
  async function handleDeactivate() {
    const { data: updateSub, errors: updateSubErrors } =
      await updateSubscription({
        variables: {
          id: currentSubscription,
          input: {
            activate: false,
          },
        },
      });
    if (updateSub && !updateSubErrors) {
      setEnabled(false);
      handleDeactivateSuccess();
    } else {
      handleError();
    }
  }

  //Calculate discount percentage (select * 5)
  useEffect(() => {
    const daysSelected = selected.filter((e) => e === true);
    setPercentDiscount(daysSelected.length * 5);
  }, [selected]);

  //Check if they have a meal plan
  useEffect(() => {
    if (subscriptions && subscriptions.length > 0 && subscriptions[0]) {
      setHasMealPlan(true);
    }
  }, [subscriptions]);

  useEffect(() => {
    if (subscriptions && subscriptions.length > 0 && hasMealPlan) {
      if (subscriptions[0].products) {
        const vals: Product[] = subscriptions[0].products.sort(
          ({ position: a }, { position: b }) => a - b
        );
        setProductsList(vals);
      }

      const rotation = subscriptions[0].mealSelection;
      const deliveryDays = subscriptions[0].deliveryDays.split(',');
      const active = subscriptions[0].activate;

      //A_1 Means rotate the products A_2 means randomize
      setRotate(rotation === 'A_2' ? false : true);

      //Build a temporary list and assign each index to true where they are enabled
      let daysEnabled = [false, false, false, false, false];
      if (deliveryDays.includes('Monday')) {
        daysEnabled[0] = true;
      }
      if (deliveryDays.includes('Tuesday')) {
        daysEnabled[1] = true;
      }
      if (deliveryDays.includes('Wednesday')) {
        daysEnabled[2] = true;
      }
      if (deliveryDays.includes('Thursday')) {
        daysEnabled[3] = true;
      }
      if (deliveryDays.includes('Friday')) {
        daysEnabled[4] = true;
      }
      setSelected(daysEnabled);

      //Get the id of the current subscription to use when updating subscription
      setCurrentSubscription(subscriptions[0].id);
      //set whether it is active or not
      setEnabled(active);

      if (subscriptions[0].paymentInfo && subscriptions[0].paymentInfo.id)
        setPaymentMethod(subscriptions[0].paymentInfo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMealPlan]);

  useEffect(() => {
    let arrayOfDates = selected.map((e, i) => (e === true ? i : 9));
    arrayOfDates = arrayOfDates.filter((e) => e !== 9);
    setDaysSelectedArray(arrayOfDates);
  }, [selected]);

  if (loading || subLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <>
      <MealPlanNavigation
        applyChanges={applyChanges}
        hasChanged={hasChanged}
        setPaymentExistsModal={setPaymentExistsModal}
      />

      <div className="px-6">
        {subscriptions &&
          subscriptions[0] &&
          subscriptions[0].paymentInfo &&
          subscriptions[0].mealSelection &&
          subscriptions[0].mealSelection.length > 0 &&
          subscriptions[0].deliveryDays.length > 0 && (
            <div className="flex pt-6">
              <Switch
                checked={enabled}
                onChange={handleUpdateMealPlan}
                onColor="#4f46e5"
                checkedIcon={false}
                uncheckedIcon={false}
              />
              <span className="flex items-center pb-4 pl-2 text-gray-900">
                Activate meal plan
              </span>
            </div>
          )}

        <div>
          <div className="pt-4 text-lg font-bold">Delivery days</div>
          <div className="text-md font-medium text-gray-500">Tap to select</div>
        </div>
        <div className="flex flex-row justify-between py-4 px-4 lg:w-1/2">
          {days.map((day, index) => (
            <div
              key={index}
              onClick={() => handleSelected(index)}
              className={classNames(
                selected[index]
                  ? 'flex h-11 w-11 items-center justify-center rounded-full bg-indigo-500 font-semibold text-white'
                  : 'flex h-11 w-11 items-center justify-center font-medium text-gray-900'
              )}
            >
              {day}
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between rounded-md border border-gray-300 p-4 text-sm lg:w-1/2">
          {daysSelected === 0 ? (
            <>
              <span>No days selected</span>
              <span className="rounded-md bg-green-200 px-3 py-2 font-medium text-gray-900">
                Add more days to save!
              </span>
            </>
          ) : (
            <>
              <span>
                {daysSelected > 1
                  ? `${daysSelected} Days Selected`
                  : `${daysSelected} Day Selected`}
              </span>
              <span className="rounded-md bg-green-200 px-4 py-2 font-semibold text-gray-900">
                SAVE {percentDiscount}%
              </span>
            </>
          )}
        </div>
        <div className="py-4 text-sm text-gray-500 lg:w-1/2">
          Payment is taken every Sunday at 11 a.m. You will receive a reminder
          email on Saturday to modify or pause your plan for the upcoming week.
          Submit your changes before 11 a.m. on Sunday to lock-in your meal
          plan. You can also reschedule meal deliveries after your card is
          charged!
        </div>
        {/* <div className="pt-4 text-lg font-bold">Meal Selection</div> */}
        {/* <div className="flex items-center justify-between rounded-md border border-gray-300 py-2 px-4 text-sm">
          <span
            className={classNames(
              rotate
                ? 'flex w-1/2 justify-center rounded-md bg-indigo-600 py-4 text-white'
                : 'flex w-1/2 justify-center bg-white py-4 text-gray-900'
            )}
            onClick={() => handleRotate()}
          >
            Rotate
          </span>
          <span
            className={classNames(
              !rotate
                ? 'flex w-1/2 justify-center rounded-md bg-indigo-600 py-4 text-white'
                : 'flex w-1/2 justify-center bg-white py-4 px-4 text-gray-900'
            )}
            onClick={() => handleRotate()}
          >
            Randomize
          </span>
        </div> */}
        {/* {rotate ? (
          <div className="py-4 text-sm text-gray-500">
            Meals will be delivered in a continuous rotation while supplies
            last.
          </div>
        ) : ( */}
        {/* <div className="pt-2 pb-6 text-sm text-gray-500">
          Meals will be delivered in a random rotation while supplies last.
        </div> */}
        {/* )} */}
        <div className="mb-4 flex flex-row items-center justify-between">
          <div className="pr-12">
            <FontAwesomeIcon icon={faHeart} className="text-pink-400" />
            <span className="pl-2 text-lg font-bold">My meals</span>
          </div>
          <button
            type="button"
            className="shaddow-md flex justify-center whitespace-pre rounded-md border border-transparent bg-indigo-50 px-3 py-2.5 text-base font-semibold leading-4 text-indigo-700 shadow-sm hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-100 focus:ring-offset-2"
            onClick={handleSaveSettings}
          >
            <span className="pr-3  text-indigo-600">+</span>
            {subscriptions && subscriptions[0] && productsList.length > 0 ? (
              <span>Add more</span>
            ) : (
              <span>Add meals</span>
            )}
          </button>
        </div>
        <div className="pb-2 text-sm text-gray-500">
          Add your favourite meals here. Meals from this list will be delivered
          to you in a random order. You can edit this list at any time.
        </div>
        {productsList.length > 0 ? (
          productsList.map((product, index) => (
            <MealSubscriptionItem
              key={index}
              product={product}
              handleRemoveItem={() => handleRemoveItem(index)}
            />
          ))
        ) : (
          <div className="py-4">
            <div className="text-sm">Add one or more meals to get started!</div>
          </div>
        )}
        <div className="my-8" />
        <DeliveryDetails
          padding="0px"
          fromMealPlan={true}
          fridgeFromSub={
            subscriptions && subscriptions[0] && subscriptions[0].fridge
          }
        />
        <div className="pb-32"></div>
        {!hasMealPlan ? (
          <div className="fixed inset-x-0 bottom-0 z-10 bg-white">
            <div className="inline-flex w-full px-6 py-6">
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-sm font-medium text-white opacity-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                disabled={true}
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                Activate meal plan
              </button>
            </div>
          </div>
        ) : (
          <>
            {hasChanged &&
            subscriptions &&
            subscriptions[0] &&
            subscriptions[0].fridge &&
            subscriptions[0].paymentInfo ? (
              <div className="fixed inset-x-0 bottom-0 z-10 block bg-white">
                <div className="inline-flex w-full px-6 py-6">
                  <button
                    type="submit"
                    className={classNames(
                      'group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-sm font-medium text-white  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    )}
                    onClick={handleRedirect}
                  >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3" />
                    Apply changes
                  </button>
                </div>
              </div>
            ) : (
              <>
                {subscriptions &&
                subscriptions[0] &&
                subscriptions[0].fridge &&
                subscriptions[0].paymentInfo ? (
                  <></>
                ) : (
                  <div className="fixed inset-x-4 bottom-4 z-10 bg-white">
                    <div className="inline-flex w-full px-6 py-6">
                      <button
                        type="submit"
                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={handleRedirect}
                      >
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                        Activate meal plan
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
            {/* {enabled && (
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md border border-gray-300 border-transparent bg-white p-4 text-sm font-medium text-red-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={handleDeactivate}
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3" />
                  Deactivate meal plan
                </button>
              )} */}
          </>
        )}
      </div>
      <PaymentExistingModal
        open={paymentExistsModal}
        setOpen={setPaymentExistsModal}
      />
      <SuccessModal open={successModal} setOpen={setSuccessModal} />
      <NoPaymentModal open={noPaymentModal} setOpen={setNoPaymentModal} />
      <NoFridgeModal open={fridgeModal} setOpen={setFridgeModal} />
    </>
  );
}
// <div className="inline-flex w-full content-center justify-center border border-transparent px-6 py-6">
//   <div className="w-full pr-4">
//     <button
//       type="submit"
//       className="inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-5 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
//       onClick={handleRedirect}
//     >
//       Save Changes
//     </button>
//   </div>
// </div>;
