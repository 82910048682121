import TopNavigation from '../navigation/top-navigation';
import ErrorPage from '../screens/error';
import LoadingPage from '../screens/loading';
import { loadStripe } from '@stripe/stripe-js';
import { useMe } from '../services/user.service';
import { useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import ProfileCreditCard from './profile-credit-card';
const stripe = loadStripe(process.env.REACT_APP_STRIPE_PK!);

export default function CreditCardDetails() {
  const { data: me, cc, loading, refetch } = useMe();
  const lastCard = handleLastUsedCard();
  const [edited, setEdited] = useState(false);

  if (loading) {
    return <LoadingPage />;
  }

  if (!me || !cc) {
    return <ErrorPage error={'User not found'} />;
  }

  function handleLastUsedCard() {
    if (cc) {
      let ids = cc.map((e) => {
        return e.id;
      });
      const max = Math.max(...ids);

      let lastUsedCard = cc.filter((e) => e.id == max);
      return lastUsedCard.pop();
    }
    return undefined;
  }

  return (
    <>
      <TopNavigation title="Stored Payment" />
      <div className="flex flex-col items-center justify-start p-4">
        <div className="py-4">Stored Credit Card Details</div>
        {lastCard && !edited ? (
          <>
            <div className="flex w-full flex-row items-center">
              <div className="mt-2 flex h-12 w-11/12 items-center justify-between rounded-md border border-gray-300 px-4 text-gray-500">
                <div>
                  <span className="pr-1">**** **** ****</span>
                  <span>{lastCard.last4}</span>
                </div>
                <div>
                  <span>
                    {lastCard.expMonth}/{lastCard.expYear.substring(2, 4)}
                  </span>
                </div>
              </div>
              <span
                className="px-4 text-indigo-700"
                onClick={() => setEdited(true)}
              >
                Edit
              </span>
            </div>
          </>
        ) : (
          <Elements stripe={stripe}>
            <ProfileCreditCard setEdited={setEdited} />
          </Elements>
        )}
      </div>
    </>
  );
}
