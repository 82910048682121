import { useEffect, useState } from 'react';
import DiscountModal from '../components/discount-modal';
import { PriceTotal, ProductWithAddons } from '../types';

interface Props {
  priceTotal: PriceTotal;
  code?: string;
  setDiscountCode?: (code: string | null) => unknown;
  checkout?: boolean;
  fridgeId?: number;
  user?: string;
  productIds?: number[];
  orderDates?: string[];
  productWithAddons?: ProductWithAddons[];
}

export default function PriceTotals({
  priceTotal,
  setDiscountCode,
  code,
  checkout,
  productIds,
  user,
  fridgeId,
  orderDates,
  productWithAddons,
}: Props) {
  const {
    subTotal,
    discountTotal,
    taxTotal,
    total,
    shippingTotal,
    incartAdditionsDiscount,
  } = priceTotal;
  const [addingCode, setAddingCode] = useState(false);
  const [open, setOpen] = useState(false);

  function handleRemoveCode() {
    localStorage.removeItem('discountCode');
    if (setDiscountCode) {
      setDiscountCode(null);
      setAddingCode(true);
    }
  }

  //Set discount code on page load, and prevent memory leak.
  useEffect(() => {
    let isMounted = true;
    if (isMounted && setDiscountCode && addingCode) {
      setDiscountCode(localStorage.getItem('discountCode'));
    }
    return () => {
      isMounted = false;
    };
  });
  return (
    <>
      <div className="mb-4 flex justify-between">
        <div className="text-gray-400">Subtotal</div>
        <div className="text-gray-900">${subTotal.toFixed(2)}</div>
      </div>
      {discountTotal > 0 && (
        <div className="mb-4 flex justify-between">
          <div className="text-gray-400">Discount</div>
          <div>
            {discountTotal === 0 ? (
              addingCode && !checkout && setDiscountCode ? (
                <>
                  <DiscountModal
                    open={open}
                    setOpen={setOpen}
                    setAddingCode={setAddingCode}
                    productIds={productIds}
                    user={user}
                    fridgeId={fridgeId}
                    orderDates={orderDates}
                    productWithAddons={productWithAddons}
                  />
                  <button
                    className="text-md text-indigo-500"
                    onClick={() => setOpen(true)}
                  >
                    + Add Discount Code
                  </button>
                </>
              ) : !checkout ? (
                <>
                  <DiscountModal
                    open={open}
                    setOpen={setOpen}
                    setAddingCode={setAddingCode}
                    productIds={productIds}
                    user={user}
                    fridgeId={fridgeId}
                    orderDates={orderDates}
                    productWithAddons={productWithAddons}
                  />
                  <button
                    className="text-md text-indigo-500"
                    onClick={() => setOpen(true)}
                  >
                    + Add Discount Code
                  </button>
                </>
              ) : (
                <div className="flex flex-row">{discountTotal.toFixed(2)}</div>
              )
            ) : !checkout && code ? (
              <>
                <div className="flex flex-row">
                  <div
                    className="pr-1 text-red-500"
                    onClick={() => handleRemoveCode()}
                  >
                    Remove
                  </div>
                  ({code}) -${discountTotal.toFixed(2)}
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-row">
                  <span> ({code})</span>
                  <span className="pl-1 text-green-700">
                    -${discountTotal.toFixed(2)}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {incartAdditionsDiscount > 0 && subTotal !== discountTotal && (
        <div className="mb-4 flex justify-between">
          <div className="text-gray-400">In Cart Savings</div>
          <div className="text-green-700">
            -${incartAdditionsDiscount.toFixed(2)}
          </div>
        </div>
      )}
      <div className="mb-4 flex justify-between">
        <div className="text-gray-400">Taxes</div>
        <div className="text-gray-900">${taxTotal.toFixed(2)}</div>
      </div>
      <div className="flex justify-between">
        <div className="text-gray-400">Delivery</div>
        <div className="text-gray-900">
          {shippingTotal ? `$${shippingTotal.toFixed(2)}` : 'FREE'}
        </div>
      </div>
      <div className="my-4 flex justify-between">
        <div className="font-medium text-gray-900">Total</div>
        <div className="font-medium text-indigo-600">${total.toFixed(2)}</div>
      </div>
    </>
  );
}
