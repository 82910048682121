import { BookingItem } from '../types';
import { useCloudinaryImage } from '../helpers';

interface Props {
  item: BookingItem;
  cancelled: boolean;
  orderId: number;
}

export default function FridgeOrderItem({ item, cancelled, orderId }: Props) {
  return (
    <>
      <div className="flex w-full pb-5">
        <div className="min-h-10 min-w-10 max-w-10 h-10 max-h-10 w-10 overflow-hidden rounded border">
          <img
            className="min-h-10 min-w-10 max-w-10 h-10 max-h-10 w-10 object-contain"
            src={useCloudinaryImage(item.image)}
            alt={item.name}
          ></img>
        </div>
        {cancelled ? (
          <div className="flex flex-col px-4">
            <dt className="text-sm text-gray-900 line-through">
              {item.name}
              <br />
            </dt>
            <dd className="text-sm text-gray-500 line-through">
              x{item.quantity}
            </dd>
          </div>
        ) : (
          <div className="flex flex-col px-4">
            <dt className="text-sm text-gray-900">
              {item.name}
              <br />
            </dt>
            <dd className="text-sm text-gray-500">x{item.quantity}</dd>
          </div>
        )}
      </div>
    </>
  );
}
