import { ReactNode } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';

interface Props {
  footer?: ReactNode;
  visible: boolean;
  close: () => void;
  children: ReactNode;
  height?: number;
  blocked: boolean;
  snapPoints?: number[];
}

export default function BottomSheetModal({
  footer,
  visible,
  close,
  children,
  height,
  blocked,
}: Props) {
  return (
    <BottomSheet
      blocking={blocked}
      open={visible}
      snapPoints={({ maxHeight }) => (height ?? 0.925) * maxHeight}
      onDismiss={close}
      footer={footer}
      style={{
        overflow: 'hidden',
        background: '#4f46e5',
        position: 'absolute',
        zIndex: 30,
      }}
    >
      {children}
    </BottomSheet>
  );
}
