import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { classNames, useCloudinaryImage } from '../helpers';
import { Product } from '../types';

interface Props {
  product: Product;
  handleRemoveItem: () => void;
}

export default function MealSubscriptionItem({
  product,
  handleRemoveItem,
}: Props) {
  return (
    <div className="bg-white px-4 py-4">
      <div className="flex w-full flex-row justify-between">
        <span className="truncate">
          <img
            src={useCloudinaryImage(product.image)}
            className="float-left h-12 w-12 rounded-md object-cover"
            alt={product.name}
          />
          <>
            <span className="pl-4 font-medium">{product.name}</span>
            <br />
            <span className="pl-4 text-sm text-gray-500">
              {product.totalSize ?? product.description}
            </span>
          </>
        </span>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full justify-center px-2 py-1 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
              <FontAwesomeIcon icon={faEllipsisV} />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-50 mt-2 w-20 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item onClick={handleRemoveItem}>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active
                          ? 'z-50 block bg-red-50 text-red-600'
                          : 'text-red-500',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      Remove
                    </div>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
