import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '../helpers';
import logo from '../img/logo.png';
import onboardingOne from '../img/onboarding/onboarding-one.png';
import onboardingTwo from '../img/onboarding/onboarding-two.png';
import onboardingThree from '../img/onboarding/onboarding-three.png';
import onboardingFour from '../img/onboarding/onboarding-four.png';

const content = [
  {
    title: 'Welcome to Cubbi!',
    subtitle:
      "Delicious meals are waiting for you!\n Let's get started by creating your account.",
    image: onboardingOne,
  },
  {
    title: 'Place your order',
    subtitle: `Shop a wide range of menu\n options from our favourite local kitchens!`,
    image: onboardingTwo,
  },
  {
    title: 'We deliver to your work',
    subtitle: `You select the days you want Cubbi meals and we offer convenient delivery to your workplace.`,
    image: onboardingThree,
  },
  {
    title: 'Pick up your order',
    subtitle: `You will receive a notification when your meal is delivered. Head to the Cubbi fridge, collect your meal and enjoy!`,
    image: onboardingFour,
  },
];

export default function Onboarding() {
  const navigate = useNavigate();
  const { height } = useWindowDimensions();
  const [index, setIndex] = useState(0);

  return (
    <div className="flex flex-col items-center justify-center overflow-x-hidden">
      <div className="flex-start flex w-1/4 justify-start">
        <img
          src={logo}
          alt="logo"
          className="my-6 h-8 justify-start object-contain"
        />
      </div>
      <Carousel
        showThumbs={false}
        showStatus={false}
        swipeable
        autoPlay
        interval={3000}
        onChange={(i) => setIndex(i)}
        selectedItem={index}
        infiniteLoop={true}
        transitionTime={1500}
        renderIndicator={(_, isSelected: boolean, index: number) => {
          if (isSelected) {
            setIndex(index);
          }
          return null;
        }}
        className="w-96"
      >
        {content.map(({ image, title, subtitle }, i) => (
          <div className="w-3/8 mb-4 flex flex-col items-center justify-between lg:px-0">
            <div className="mx-4 flex flex-col items-center lg:px-0">
              <div className="flex-start flex px-4">
                <img
                  src={image}
                  style={{
                    height: height / 2.75,
                    objectFit: 'contain',
                    width: '100%',
                  }}
                  alt="lottie"
                />
              </div>
              <div className="flex flex-row justify-center ">
                <div className="text-center text-3xl font-semibold text-gray-900">
                  {title}
                </div>
              </div>
            </div>
            <div className="mt-7 flex px-4">
              <div className="flex-start justify-center text-lg text-gray-500">
                {subtitle}
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <div role="list" className="flex items-center space-x-3">
        {[...new Array(4)].map((_, i) =>
          i === index ? (
            <div
              className="relative flex items-center justify-center"
              aria-current="step"
            >
              <span
                className="relative block h-3 w-3 rounded-full bg-indigo-600"
                aria-hidden="true"
              />
            </div>
          ) : (
            <div
              className="block h-3 w-3 rounded-full bg-gray-200 hover:bg-gray-400"
              onClick={() => setIndex(i)}
            />
          )
        )}
      </div>
      <div className="flex flex-col items-center justify-center px-4">
        <button
          onClick={() => navigate('/signup-phone')}
          type="button"
          className="mb-5 mt-8 inline-flex w-96 justify-center rounded-md border border-transparent bg-indigo-500 px-6 py-3 text-center text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Get Started
        </button>
        <div
          onClick={() => navigate('/login')}
          className="text-md mb-36 font-medium text-indigo-600 hover:text-indigo-500"
        >
          Already a Member? Login
        </div>
      </div>
    </div>
  );
}
