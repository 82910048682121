import TopNavigation from '../navigation/top-navigation';
import { useLocation, useNavigate } from 'react-router-dom';
import { Product } from '../types';
import ErrorPage from '../screens/error';
import CouponCode from '../checkout/coupon-code';
import { useState, useEffect } from 'react';
import PrestockCheckoutItem from './prestock-checkout-item';
import { useParams } from 'react-router-dom';
import { usePriceTotalsWithDiscount } from '../services/payment.service';
import { useMe } from '../services/user.service';
import { FridgeStatePersistentStorageItem } from '../persistent-storage/fridge-state.persistent-storage';
import { FRIDGE_STATE_KEY } from '../persistent-storage/fridge-state.persistent-storage';
import PersistentStorage from '../persistent-storage/persistent-storage';
import { useMutation } from '@apollo/client';
import { GET_PRESTOCK_PAYMENT_INTENT } from '../services/prestock.service';
import { GraphQLError } from 'graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PrestockCheckoutForm from './prestock-checkout-form';
import { classNames } from '../helpers';
import LoadingPage from '../screens/loading';
import PrestockPriceTotals from './prestock-pricetotals';
import { DISCOUNT_ORDER_VERIFY } from '../services/payment.service';
import moment from 'moment';

interface productState {
  product: Product;
  bookingId: number;
}

export default function PrestockCheckout() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as productState;
  const product = state.product;
  const { clientSecret } = useParams();
  const discountCode = localStorage.getItem('prestockDiscount') ?? undefined;
  const [code, setCode] = useState<string | undefined>(discountCode);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { data: me, loading: meLoading, refetch } = useMe();
  const [paymentIntentBulkError, setPaymentIntentBulkError] =
    useState<string>();

  const [discountOrderVerify] = useMutation(DISCOUNT_ORDER_VERIFY);

  const stripe = loadStripe(process.env.REACT_APP_STRIPE_PK!);

  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );
  const fridge = PersistentStorage.get(selectedFridgeState);
  const dateTimeString = moment().toISOString(true).substring(0, 23);
  const productWithAddon = { product: product.id, addons: [] };
  const [time, setTime] = useState<string | undefined>();

  const [getPaymentIntent] = useMutation<{
    getPaymentIntentPrestock: { clientSecret: string };
  }>(GET_PRESTOCK_PAYMENT_INTENT);

  const paymentIntentExists = clientSecret?.startsWith('pi_');

  const {
    priceTotal,
    error,
    loading: priceLoading,
  } = usePriceTotalsWithDiscount(
    [productWithAddon],
    fridge?.id,
    me?.email,
    discountCode,
    time ? [time] : undefined,
    state.bookingId
  );

  async function handleUpdateDiscountCode() {
    if (me) {
      const input = [
        {
          payee: me.email,
          booking: state.bookingId,
          discountCode: code ?? null,
          creditCardDetailsId: null,
        },
      ];

      const product = {
        product: state.product.id,
        addons: [],
      };

      try {
        const { data, errors } = await getPaymentIntent({
          variables: {
            input: input,
          },
        });
        if (data && !errors) {
          const newClientSecret = data.getPaymentIntentPrestock.clientSecret;
          if (code) {
            localStorage.setItem('prestockDiscount', code);
          }
          navigate(`/prestock-checkout/${newClientSecret}`, {
            replace: true,
            state: { product: state.product, bookingId: state.bookingId },
          });
        } else {
          setPaymentIntentBulkError(
            'An error occurred getting the payment intent. Please try again.'
          );
        }
      } catch (error: any) {
        const errorMessage = (error as GraphQLError).message;
        setPaymentIntentBulkError(errorMessage);
      }
    }
  }

  async function submitAndVerifyDiscountedOrder() {
    try {
      setSubmitLoading(true);
      const { data } = await discountOrderVerify({
        variables: { input: { uuid: clientSecret } },
      });
      setSubmitLoading(false);
      if (data) {
        localStorage.removeItem('prestockDiscount');
        navigate(`/prestock-success/${clientSecret}`);
      }
    } catch (e) {
      setSubmitLoading(false);
      alert(e);
    }
  }

  async function handleGetPaymentIntent() {
    handleUpdateDiscountCode();
  }

  useEffect(() => {
    setTime(dateTimeString);
  }, []);

  useEffect(() => {});

  if (loading || priceLoading || meLoading) {
    return <LoadingPage />;
  }
  if (!state || !me || !priceTotal || !clientSecret) {
    return <ErrorPage />;
  }
  return (
    <div>
      <TopNavigation title="Checkout" url="/prestock-menu" />
      <div className="bg-blue-50">
        <div className="py-2 px-4 text-gray-900">
          <div className="inline-flex">
            <div className="pt-[2px]">
              <FontAwesomeIcon icon={faClock} className="text-blue-400" />
            </div>
            <div className="pl-[14px]">
              <span className="font-medium text-blue-800">
                Grab ’n Go meals must be collected within 15 minutes after
                placing the order.
              </span>
            </div>
          </div>
          <br />
        </div>
      </div>
      <PrestockCheckoutItem product={state.product} />
      {paymentIntentBulkError && (
        <div className="px-4 pt-4">
          <div className="flex h-[72px] bg-yellow-50">
            <div className="flex flex-row items-center px-4 py-4">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="flex items-center justify-start text-center text-yellow-400"
              />
              <p className="flex items-center justify-center px-2 text-sm text-yellow-800">
                {paymentIntentBulkError}
              </p>
            </div>
          </div>
        </div>
      )}
      {priceTotal.error && (
        <div className="px-4 pt-4">
          <div className="flex h-[72px] bg-yellow-50">
            <div className="flex flex-row px-4 py-4">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="flex items-center justify-start text-yellow-400"
              />
              <p className="items-center justify-center px-2 text-sm text-yellow-800">
                {priceTotal.error}
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="-mx-2 pt-4">
        <CouponCode
          code={code ?? ''}
          setCode={setCode}
          submit={handleUpdateDiscountCode}
        />
      </div>
      <div className="p-4">
        <PrestockPriceTotals priceTotal={priceTotal} code={discountCode} />
      </div>
      <footer className="bg-white">
        {paymentIntentExists ? (
          <div className="px-4">
            <Elements stripe={stripe}>
              <PrestockCheckoutForm
                total={priceTotal.total}
                user={me}
                clientSecret={clientSecret}
                handleGetPaymentIntent={handleGetPaymentIntent}
                defaultCard={me.defaultSelectedCard}
                refetchUser={refetch}
              />
            </Elements>
          </div>
        ) : (
          <div className="mt-8 mb-6 px-4">
            <button
              onClick={submitAndVerifyDiscountedOrder}
              type="button"
              disabled={loading}
              className={classNames(
                me.defaultSelectedCard
                  ? 'bg-indigo-600'
                  : 'bg-black hover:bg-gray-800',
                'w-full rounded-lg border border-transparent  py-3 px-4 text-base font-medium text-white shadow-sm  '
              )}
            >
              {submitLoading ? 'Submitting...' : 'Submit Order'}
            </button>
          </div>
        )}
      </footer>
    </div>
  );
}
