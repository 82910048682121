import presentOnboarding from '../../img/present-onboarding.png';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

interface Props {
  step: number;
  dayOfWeek: string;
}

export default function WalkthroughSteps({ step, dayOfWeek }: Props) {
  const [closePrompt, setClosePrompt] = useState(false);
  function handleClosePrompt() {
    setClosePrompt(true);
  }
  return (
    <>
      {step === 1 && <div className=""></div>}
      {step === 2 && !closePrompt && (
        <div className="flex flex-col justify-between bg-indigo-600">
          <div className="flex flex-row justify-between px-4">
            <div></div>
            <p className="pt-4 text-white">
              When you're happy with {dayOfWeek}'s meal choices, continue to the
              next step.
            </p>
            <div className="flex pt-4">
              <div className="flex h-12 animate-bounce items-center rounded-md bg-indigo-900 px-4 py-4">
                <div className="text-center text-white">
                  <FontAwesomeIcon icon={faArrowUp} className={'text-2xl'} />
                </div>
              </div>
            </div>
          </div>

          <div className="sm:flex sm:justify-center">
            <div className="px-4 pt-2 pb-3">
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-white p-2 text-base font-medium text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-96"
                onClick={() => handleClosePrompt()}
              >
                <span className="flex items-center text-indigo-600"></span>
                {'Got it!'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
