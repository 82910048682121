export default function FridgeSelectSkeleton() {
  return (
    <div className="mx-auto mb-6 h-[220px] w-full max-w-sm rounded-md px-4 shadow">
      <div className="flex animate-pulse space-x-4">
        <div className="bg-slate-400 pt-2"></div>
        <div className="flex-1 space-y-6 py-1">
          <div className="">
            <div className="flex flex-row py-4">
              <div className="h-[96px] w-[96px] rounded bg-slate-400 py-8"></div>
              <div className="py-2">
                <div className="h-2 w-1/2 rounded bg-slate-400"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
