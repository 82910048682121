import { useContext } from 'react';
import {
  Navigate,
  Route,
  HashRouter as Router,
  Routes as Switch,
} from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import AccountEditItem from './components/account-edit-item';
import FridgeOrder from './components/fridge-order';
import OrderDetail from './components/order-detail';
import ProfileNotifications from './components/profile-notifications';
import { Route as RouteEnum } from './constants';
import { AuthContext } from './providers/auth-provider';
import Account from './screens/account';
import FeedbackDelivery from './feedback/feedback-delivery';
import FeedbackMeal from './feedback/feedback-meal';
import PrestockCheckout from './pre-stock/prestock-checkout';
import PrestockFridges from './pre-stock/prestock-fridges';
import PrestockMenu from './pre-stock/prestock-menu';
import PrestockOrderSuccess from './pre-stock/prestock-order-success';
import CreditCardDetails from './profile/credit-card-details';
import BookingSelect from './reschedule-meals/booking-select';
import RescheduleCalendar from './reschedule-meals/reschedule-calendar';
import RescheduleItems from './reschedule-meals/reschedule-items';
import AppRedirect from './screens/app-redirect';
import RedirectToAppStore from './screens/app-store-redirect';
import CheckoutV4 from './screens/checkout-v4';
import DateSelect from './screens/date-select';
import Deliveries from './screens/deliveries';
import EmailVerification from './screens/email-verification';
import ForgotPassword from './screens/forgot-password';
import FridgeSelect from './screens/fridge-select';
import GroupOrdersLink from './screens/group-orders-redirect';
import KitchenV2 from './screens/kitchen-v2';
import NotFound from './screens/not-found';
import OldAppRedirect from './screens/old-app-redirect';
import Onboarding from './screens/onboarding';
import OrderSuccess from './screens/order-success';
import OrderSummaryV2 from './screens/order-summary-v2';
import PasswordResetConfirmation from './screens/password-reset-confirmation';
import PasswordResetForm from './screens/password-reset-form';
import Receipt from './screens/receipt';
import RedirectScreen from './screens/redirect';
import Reminders from './screens/reminders';
import MealPlan from './subscriptions/meal-plan';
import SubscriptionCheckout from './subscriptions/subscription-checkout';
import SubscriptionKitchen from './subscriptions/subscription-kitchen';
import SignupPhone from './screens/onboarding/signup-phone';
import SignupPhoneVerify from './screens/onboarding/signup-phone-verify';
import SignupName from './screens/onboarding/signup-name';
import SignupEmail from './screens/onboarding/signup-email';
import LoginCode from './screens/login/login-code';
import Login from './screens/login/login';

export default function Routes() {
  const { token } = useContext(AuthContext);
  function ProtectedRoute(element: JSX.Element) {
    if (token === 'null' || !token || token === 'undefined') {
      return <Navigate to={{ pathname: '/onboarding' }} />;
    }
    return element;
  }

  function redirectAccount() {
    return token ? (
      <Navigate to={{ pathname: '/upcoming' }} />
    ) : (
      <Navigate to={{ pathname: '/onboarding' }} />
    );
  }
  smoothscroll.polyfill();

  return (
    <Router>
      <Switch>
        <Route
          path={'/notifications'}
          element={ProtectedRoute(<ProfileNotifications />)}
        />
        <Route path={'/'} element={redirectAccount()} />
        <Route path={'*'} element={<NotFound />} />
        <Route path=".well-known/apple-developer-merchantid-domain-association" />
        <Route key={RouteEnum.Login} path="/login" element={<Login />} />
        <Route
          key={RouteEnum.Login}
          path="/login/:rerouteLocation"
          element={<Login />}
        />
        <Route
          key={RouteEnum.Onboarding}
          path="/onboarding"
          element={<Onboarding />}
        />
        <Route
          key={RouteEnum.Kitchen}
          path="/kitchen"
          element={ProtectedRoute(<KitchenV2 />)}
        />
        <Route
          key={RouteEnum.Account}
          path="/account"
          element={ProtectedRoute(<Account />)}
        />
        <Route
          key={RouteEnum.Checkout}
          path="/checkout/:clientSecret"
          element={ProtectedRoute(<CheckoutV4 />)}
        />
        <Route
          key={RouteEnum.OrderSuccess}
          path="/order-success/:paymentIntent"
          element={ProtectedRoute(<OrderSuccess />)}
        />
        <Route
          key={RouteEnum.Fridges}
          path="/fridges"
          element={ProtectedRoute(<FridgeSelect />)}
        />
        <Route path="/prestock-fridges" element={<PrestockFridges />} />
        <Route path="/prestock-menu" element={<PrestockMenu />} />
        <Route
          key={RouteEnum.OrderSuccess}
          path="/prestock-success/:paymentIntent"
          element={ProtectedRoute(<PrestockOrderSuccess />)}
        />
        <Route
          path="/prestock-checkout/:clientSecret"
          element={<PrestockCheckout />}
        />
        <Route path="/verify" element={<EmailVerification />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/date-select" element={<DateSelect />} />
        <Route path="/order-summary" element={<OrderSummaryV2 />} />
        <Route path="/fridge-select" element={<FridgeSelect />} />
        <Route
          path="/edit-profile"
          element={ProtectedRoute(<AccountEditItem />)}
        />
        <Route
          key={RouteEnum.Upcoming}
          path="/upcoming"
          element={ProtectedRoute(<Deliveries />)}
        />
        <Route
          key={RouteEnum.Upcoming}
          path="/upcoming:tab"
          element={ProtectedRoute(<Deliveries />)}
        />
        <Route
          path="/reminders/:clientSecret"
          element={ProtectedRoute(<Reminders />)}
        ></Route>
        <Route
          path="/reminders/:paymentIntent"
          element={ProtectedRoute(<Reminders />)}
        ></Route>
        <Route
          path="/password-reset/:resetToken"
          element={<PasswordResetForm />}
        />
        <Route
          path="rating/:bookingId"
          element={ProtectedRoute(<FeedbackMeal />)}
        ></Route>
        <Route
          path="rating/delivery/:bookingId"
          element={ProtectedRoute(<FeedbackDelivery />)}
        ></Route>
        <Route
          path="/password-confirmation"
          element={<PasswordResetConfirmation />}
        />
        <Route path="/fridge-order" element={<FridgeOrder />} />
        <Route path="/receipt/:orderId" element={ProtectedRoute(<Receipt />)} />
        <Route
          path="/order-details/:orderId"
          element={ProtectedRoute(<OrderDetail />)}
        />
        <Route
          path="/booking-select"
          element={ProtectedRoute(<BookingSelect />)}
        />
        <Route path="/support:redirect" element={<RedirectScreen />} />
        <Route
          path="/reschedule"
          element={ProtectedRoute(<RescheduleCalendar />)}
        />
        <Route
          path="/reschedule-items"
          element={ProtectedRoute(<RescheduleItems />)}
        />
        <Route path="/meal-plan" element={ProtectedRoute(<MealPlan />)} />
        <Route
          path="/meal-kitchen"
          element={ProtectedRoute(<SubscriptionKitchen />)}
        />
        <Route
          key={RouteEnum.Upcoming}
          path="/subscription-checkout"
          element={ProtectedRoute(<SubscriptionCheckout />)}
        />
        <Route
          path="/credit-card"
          element={ProtectedRoute(<CreditCardDetails />)}
        />
        <Route path="/app" element={<AppRedirect />} />
        <Route path="/app-store" element={<RedirectToAppStore />} />
        <Route path="/appone" element={<OldAppRedirect />} />
        <Route path="/group-orders" element={<GroupOrdersLink />} />
        <Route path="/signup-phone" element={<SignupPhone />} />
        <Route path="/signup-phone-verify" element={<SignupPhoneVerify />} />
        <Route path="/signup-name" element={<SignupName />} />
        <Route path="/signup-email" element={<SignupEmail />} />
        <Route path="/signup-login-code" element={<LoginCode />} />
      </Switch>
    </Router>
  );
}
