import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { User } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import ProgressIndicator from '../../onboarding/progress-indicator';
import { classNames } from '../../helpers';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CHANGE_USER } from '../../services/user.service';
import Notification from '../../components/notification';
import { toast } from 'react-toastify';
import { NotificationType } from '../../constants';

interface userState {
  user: User;
}

export default function SignupName() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as userState;
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { email, pk } = state.user;

  const [updateUser] =
    useMutation<{ updateUser: { ok: boolean } }>(CHANGE_USER);

  const handleError = (message: string) =>
    toast(
      <Notification
        type={NotificationType.Error}
        message={`${message}`}
        title="Error"
      />,
      { position: 'top-center', autoClose: 5000 }
    );

  async function updateName() {
    setLoading(true);
    await updateUser({
      variables: {
        id: pk,
        input: {
          firstName,
          lastName,
          orderUpdatesEmail: false,
        },
      },
      onError: (err) => {
        handleError(err.message);
        setLoading(false);
      },
      onCompleted: (data) => {
        if (data.updateUser?.ok) {
          navigate('/signup-email', { state: { user: state.user } });
        }
        setLoading(false);
      },
    });
  }

  async function handleNameRedirect() {
    if (firstName && lastName) {
      await updateName();
    } else {
      handleError('First name and last name are required.');
    }
  }
  return (
    <div className={`flex h-screen w-screen justify-center bg-background p-4`}>
      <div className="lg:w-3/8">
        <div className="items-center">
          <ProgressIndicator filled={3} length={4} />
          <div
            className="flex flex-row items-center text-center"
            onClick={() => navigate('/login')}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="text-xl text-gray-500"
            />
            <div className={`pl-4 text-2xl font-bold text-graphite-900`}>
              Your name
            </div>
          </div>
          <div className="py-4 text-graphite-900">
            <span>Please enter your name.</span>
          </div>
          <div className="flex flex-col rounded-lg bg-white py-4 px-6 pt-4 shadow-lg sm:w-96">
            <div className="py-2">
              <input
                className={
                  'h-[42px] w-full rounded-lg border border-graphite-300 px-4 text-graphite-500 shadow-sm'
                }
                placeholder="First name"
                type="name"
                autoComplete="firstName"
                onChange={(e) => setFirstName(e.target.value)}
              ></input>
              <div className="pb-4" />
              <input
                className={
                  'h-[42px] w-full rounded-lg border border-graphite-300 px-4 text-graphite-500 shadow-sm'
                }
                placeholder="Last name"
                type="name"
                autoComplete="lastName"
                onChange={(e) => setLastName(e.target.value)}
              ></input>
              <div className="py-4">
                <button
                  type="submit"
                  disabled={firstName.length === 0 || lastName.length === 0}
                  className={classNames(
                    firstName.length === 0 || lastName.length === 0
                      ? 'bg-indigo-600/20'
                      : 'bg-indigo-600',
                    'group relative flex w-full justify-center rounded-md border border-transparent p-3 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  )}
                  onClick={() => handleNameRedirect()}
                >
                  <span className="flex items-center text-base"></span>
                  {loading ? 'Loading...' : 'Next'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
