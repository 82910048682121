import { useCloudinaryImage } from '../helpers';
import { Fridge } from '../types';

interface Props {
  fridgeFromSub: Fridge;
}

export default function FridgeFromSub({ fridgeFromSub }: Props) {
  return (
    <img
      src={useCloudinaryImage(fridgeFromSub.image)}
      alt={fridgeFromSub.name}
      className="h-16 w-16 justify-center rounded-lg border border-gray-100 object-cover shadow-sm"
    />
  );
}
