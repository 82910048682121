import { useLocation, useNavigate } from 'react-router-dom';
import { useBookingById } from '../services/orders.service';
import { useState, useEffect } from 'react';
import RescheduleItem from './reschedule-item';
import { ProductWithAddons } from '../types';
import ErrorPage from '../screens/error';
import LoadingPage from '../screens/loading';
import TopNavigation from '../navigation/top-navigation';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface bookingState {
  bookingId: number;
}

export default function RescheduleItems() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as bookingState;
  const { data: booking, loading, error } = useBookingById(state.bookingId);
  const [selected, setSelected] = useState<boolean[]>();
  const [enableButton, setEnableButton] = useState(false);
  const [listOfItems, setListOfItems] = useState<ProductWithAddons[]>();
  const runningTotal = booking?.booking.bookingItems
    .map((e) => e.quantity)
    .reduce((partialSum, a) => partialSum + a, 0);

  let increment = 0;

  function handleChangeItem(position: number) {
    let index = position - 1;
    if (selected && selected?.length >= index) {
      let tempSelected = selected;
      tempSelected[index] = !tempSelected[index];
      setSelected([...tempSelected]);
    }
  }

  function handleSelectAll() {
    let list = selected;
    list = list?.map(() => true);
    setSelected(list);
  }

  //Pass items through state to the next screen so it can calculate unavailable days
  function handlePassItems() {
    if (selected && selected.includes(true)) {
      let list: ProductWithAddons[] = [];
      for (let i = 0; i < selected.length; i++) {
        if (listOfItems && listOfItems[i] && selected[i] === true) {
          list = [...list, listOfItems[i]];
        }
      }
      navigate('/reschedule', {
        state: { bookingId: state.bookingId, products: list },
      });
    }
  }

  //Build initial list to reference when passing items to backend based on true conditions
  useEffect(() => {
    if (booking && booking.booking.bookingItems) {
      let list = booking.booking.bookingItems;
      let result: ProductWithAddons[] = [];
      if (list) {
        list.forEach((e) => {
          if (e.quantity > 1) {
            for (let i = 0; i < e.quantity; i++) {
              result = [
                ...result,
                { product: e.productId, addons: e.addons.map((e) => e.id) },
              ];
            }
          } else {
            result = [
              ...result,
              { product: e.productId, addons: e.addons.map((e) => e.id) },
            ];
          }
        });
        setListOfItems(result);
      }
    }
  }, [booking]);

  //get the total number of items (includes calcuation for quantity)
  useEffect(() => {
    if (runningTotal) {
      let selectedLength: boolean[] = [];
      for (let i = 0; i < runningTotal; i++) {
        if (selectedLength) {
          selectedLength = [...selectedLength, false];
        } else {
          selectedLength = [false];
        }
      }
      setSelected(selectedLength);
    }
  }, [runningTotal]);

  useEffect(() => {
    if (selected && selected.includes(true)) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  }, [selected]);

  if (loading) {
    return <LoadingPage />;
  }

  if (!booking || error) {
    return <ErrorPage />;
  }

  return (
    <>
      <TopNavigation title="Reschedule Items" key={0} />
      <div className="flex w-full flex-row items-center justify-center py-4 text-sm text-gray-900">
        <div className="text-gray-90 flex flex-row justify-between text-sm">
          <div className="flex flex-row items-center bg-blue-50 p-4">
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="flex items-start justify-center text-indigo-500"
            />
            <div className="pl-2 text-blue-800">
              Select the items you want to reschedule.
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex justify-end px-4 pt-4 text-indigo-600 sm:w-3/4"
        onClick={() => handleSelectAll()}
      >
        + Select all
      </div>

      {booking &&
        booking.booking.bookingItems?.map((item) => {
          let jsxElements: JSX.Element[] = [<></>];

          if (
            item.quantity > 1 &&
            selected &&
            selected.length >= increment - 1
          ) {
            for (let i = 0; i < item.quantity; i++) {
              increment++;
              jsxElements.push(
                <RescheduleItem
                  key={increment}
                  item={item}
                  position={increment}
                  adjustItem={handleChangeItem}
                  checked={selected[increment - 1]}
                />
              );
            }
            return jsxElements;
          }
          increment++;
          return (
            <RescheduleItem
              key={increment}
              item={item}
              position={increment}
              adjustItem={handleChangeItem}
              checked={selected ? selected[increment - 1] : false}
            />
          );
        })}
      <div className="p-4 sm:flex sm:justify-center">
        {enableButton ? (
          <button
            type="submit"
            onClick={() => handlePassItems()}
            className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-1/4"
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
            {'Continue'}
          </button>
        ) : (
          <button
            type="submit"
            disabled={true}
            onClick={() => handlePassItems()}
            className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-sm font-medium text-white opacity-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-1/4"
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
            {'Continue'}
          </button>
        )}
      </div>
    </>
  );
}
