import { useMutation } from '@apollo/client';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert, { AlertType } from '../components/alert';
import LoadingSkeleton from '../components/loading-skeleton';
import { classNames } from '../helpers';
import { toast } from 'react-toastify';
import Notification from '../components/notification';
import { NotificationType } from '../constants';
import {
  UPDATE_SUBSCRIPTION,
  useSubByEmail,
} from '../services/subscriptions.service';
import { CREATE_CREDIT_CARD, useMe } from '../services/user.service';
import { Subscription } from '../types';

export default function StripeElements() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const { data: me, cc, loading } = useMe();
  const [edited, setEdited] = useState(false);
  const [loadingSub, setLoadingSub] = useState(false);
  const { data: subscriptions, loading: subLoading } = useSubByEmail(me?.email);
  const [createCreditCard] = useMutation(CREATE_CREDIT_CARD);
  const lastCard = handleLastUsedCard();
  const [lastPaymentMethod, setLastPaymentMethod] = useState(false);

  //updateSubscription takes two variables: id (id# of subscription from subscriptions[0].id) and input, input is an object that you can pass paymentMethod to.
  //Make sure when you updateSubscription that you set active: true as part of the input object
  const [updateSubscription] =
    useMutation<{ updateSubscription: Subscription }>(UPDATE_SUBSCRIPTION);

  function handleLastUsedCard() {
    if (cc) {
      let ids = cc.map((e) => {
        return e.id;
      });
      const max = Math.max(...ids);

      let lastUsedCard = cc.filter((e) => e.id === max);
      return lastUsedCard.pop();
    }
    return undefined;
  }

  if (loading || subLoading) {
    return <LoadingSkeleton size={1} />;
  }

  function handleError(error: string) {
    setErrors([error]);
    setLoadingSub(false);
  }

  async function handleSubmit() {
    setErrors([]);
    if (!subscriptions || !stripe || !elements) {
      handleError('Could not find subscription');
      return;
    }
    setLoadingSub(true);
    if (lastCard && lastPaymentMethod) {
      try {
        const paymentInfo = lastCard.id;

        const { data, errors } = await updateSubscription({
          variables: {
            id: subscriptions[0].id,
            input: { paymentInfo, activate: true },
          },
        });
        setLoadingSub(false);

        if (!data && errors) {
          setErrors(['Something went wrong. Could not update subscription']);
          return;
        }
        handleSuccess();
        navigate('/upcoming', { state: { fromMealPayment: true } });
      } catch (error: any) {
        const message = error.graphQLErrors[0].message.split(': ')[1];
        handleError(`Card Error: ${message}`);
        return;
      }
    } else {
      const cardElement = elements.getElement('card');
      if (!cardElement) {
        handleError('Please enter a valid credit card');
        return;
      }
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (!paymentMethod || error || !me) {
        handleError('Please enter a valid credit card');
        return;
      }

      try {
        const { data: cardData } = await createCreditCard({
          variables: {
            input: {
              paymentMethodId: paymentMethod.id,
              customer: me.email,
            },
          },
        });
        const paymentInfo =
          cardData?.createCreditcarddetails.creditcarddetails.id;

        const { data, errors } = await updateSubscription({
          variables: {
            id: subscriptions[0].id,
            input: { paymentInfo, activate: true },
          },
        });
        setLoadingSub(false);

        if (!data && errors) {
          setErrors(['Something went wrong. Could not update subscription']);
          return;
        }
        handleSuccess();
        navigate('/upcoming', { state: { fromMealPayment: true } });
      } catch (error: any) {
        const message = error.graphQLErrors[0].message.split(': ')[1];
        handleError(`Card Error: ${message}`);
        return;
      }
    }
  }

  const handleSuccess = () =>
    toast(
      <Notification
        type={NotificationType.Success}
        message={'Added payment method and activated meal plan!'}
        title="Success"
      />,
      { position: 'top-right' }
    );

  return (
    <div className="py-6">
      {subscriptions &&
        subscriptions[0] &&
        subscriptions[0].paymentInfo &&
        !edited && (
          <>
            <div className="flex flex-row items-center">
              <div className="mt-2 flex h-12 w-11/12 items-center justify-between rounded-md border border-gray-300 px-4 text-gray-500">
                <div>
                  <span className="pr-1">**** **** ****</span>
                  <span>{subscriptions[0].paymentInfo.last4}</span>
                </div>
                <div>
                  <span>
                    {subscriptions[0].paymentInfo.expMonth}/
                    {subscriptions[0].paymentInfo.expYear.substring(2, 4)}
                  </span>
                </div>
              </div>
              <span
                className="px-4 text-indigo-700"
                onClick={() => setEdited(true)}
              >
                Edit
              </span>
            </div>
          </>
        )}
      <div className="mb-2">
        {errors.length > 0 && (
          <Alert
            type={AlertType.Error}
            title={'Error adding payment method'}
            messages={errors}
          />
        )}
      </div>
      {lastCard &&
        subscriptions &&
        subscriptions[0] &&
        !subscriptions[0].paymentInfo &&
        !lastPaymentMethod && (
          <>
            <div className="flex flex-row items-center pb-4">
              <div className="mt-2 flex h-12 w-11/12 items-center justify-between rounded-md border border-gray-300 px-4 text-gray-500">
                <div>
                  <span className="pr-1">**** **** ****</span>
                  <span>{lastCard.last4}</span>
                </div>
                <div>
                  <span>
                    {lastCard.expMonth}/{lastCard.expYear.substring(2, 4)}
                  </span>
                </div>
              </div>
              <span
                className="px-4 text-indigo-700"
                onClick={() => setLastPaymentMethod(true)}
              >
                Select
              </span>
            </div>
          </>
        )}
      {lastCard &&
        lastPaymentMethod &&
        subscriptions &&
        subscriptions[0] &&
        !subscriptions[0].paymentInfo && (
          <div>
            <div>Selected Card</div>
            <div className="flex flex-row items-center pb-4">
              <div className="mt-2 flex h-12 w-full items-center justify-between rounded-md border border-gray-300 px-4 text-gray-500">
                <div>
                  <span className="pr-1">**** **** ****</span>
                  <span>{lastCard.last4}</span>
                </div>
                <div>
                  <span>
                    {lastCard.expMonth}/{lastCard.expYear.substring(2, 4)}
                  </span>
                </div>
              </div>
            </div>
            <button
              type="button"
              onClick={handleSubmit}
              disabled={loadingSub || loading || !stripe || !elements}
              className={classNames(
                loadingSub ? 'bg-indigo-300' : 'bg-indigo-600',
                'mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent  px-4 py-3 text-center text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              )}
            >
              {loadingSub ? 'Loading...' : 'Activate Now'}
            </button>
          </div>
        )}
      {((subscriptions &&
        subscriptions[0] &&
        !subscriptions[0].paymentInfo &&
        !lastPaymentMethod) ||
        (edited &&
          subscriptions &&
          subscriptions[0] &&
          !lastPaymentMethod)) && (
        <>
          <CardElement
            onFocus={() => {}}
            options={{ hidePostalCode: true }}
            className="block w-full rounded-md border border-gray-300 p-4 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          <button
            type="button"
            onClick={handleSubmit}
            disabled={loadingSub || loading || !stripe || !elements}
            className={classNames(
              loadingSub ? 'bg-indigo-300' : 'bg-indigo-600',
              'mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent  px-4 py-3 text-center text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
            )}
          >
            {loadingSub ? 'Loading...' : 'Activate Now'}
          </button>
        </>
      )}
    </div>
  );
}
