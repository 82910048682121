import moment, { Moment } from 'moment';
import { RefObject, useMemo } from 'react';
import { getNodeDataFromEdges } from '../helpers';
import useOnScreen from '../hooks';
import { CategoryRefType } from '../screens/kitchen-v2';
import { Product, ProductObj } from '../types';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';
import GridProductCard from './grid-product-card';

interface Props {
  category: CategoryRefType;
  handleOpenProductModal: (product: Product) => void;
  addItemToOrder: (product: ProductObj) => void;
  setSelectedTab: () => void;
  currentDate: Moment;
  showUnavailable: boolean;
  orderItems: OrderState[];
  search: boolean;
  searchValue: string;
}

export default function CategoryDisplay({
  category: { name, filteredProducts, ref },
  showUnavailable,
  handleOpenProductModal,
  addItemToOrder,
  setSelectedTab,
  currentDate,
  orderItems,
  search,
  searchValue,
}: Props) {
  const isVisible = useOnScreen(ref);
  useMemo(() => {
    if (isVisible) {
      setSelectedTab();
    }
  }, [isVisible, setSelectedTab]);
  const products = [...filteredProducts];
  const currentDayFormatted = currentDate.format('dddd');
  const currentDayByNumber = currentDate.date();
  const today = moment().date();
  const tomorrow = moment().add(1, 'days');
  const tomorrowByNumber = tomorrow.date();
  const tomorrowByMonth = tomorrow.month();
  const todayMonth = moment().month();
  const hour = moment().hour();
  const currentDayByMonth = currentDate.month();
  return (
    <div ref={ref as RefObject<HTMLDivElement>} className="px-4">
      <div className="relative my-4 items-center">
        <div
          className="absolute inset-0 ml-20 flex items-center"
          aria-hidden="true"
        >
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-start">
          <span className=" pr-3 text-lg font-medium text-gray-900">
            {name}
          </span>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8">
        {products
          .sort(({ position: a }, { position: b }) => {
            // @ts-ignore
            return (a === null) - (b === null) || +(a > b) || -(a < b);
          })
          // .sort(
          //   ({
          //     id,
          //     shelfLife,
          //     arrivalDate,
          //     nextArrivalDate,
          //     quantity,
          //     nextQuantity,
          //     sameDayOrders,
          //     prepTime,
          //   }) => {
          //     const productOrderedQuantity = orderItems.filter(
          //       (e) => e.product.product.id === id
          //     );

          //     const shelfLifeNumber = shelfLife?.substring(0, 1);
          //     const timeNow = moment();

          //     const nextArrivalFirst =
          //       nextArrivalDate !== undefined &&
          //       arrivalDate !== undefined &&
          //       moment(nextArrivalDate).isBefore(arrivalDate);

          //     const nextArrival =
          //       nextArrivalDate !== null &&
          //       currentDate.isBefore(moment(nextArrivalDate));
          //     const productArrival =
          //       arrivalDate !== null &&
          //       currentDate.isBefore(moment(arrivalDate));
          //     const infiniteQuantity =
          //       arrivalDate !== null &&
          //       shelfLife !== 'None' &&
          //       currentDate.isAfter(
          //         moment(arrivalDate).add(shelfLifeNumber, 'days')
          //       );
          //     const nextArrivalInfinite =
          //       nextArrivalDate !== null &&
          //       shelfLife !== 'None' &&
          //       currentDate.isAfter(
          //         moment(nextArrivalDate).add(shelfLifeNumber, 'days')
          //       );
          //     const nextArrivalFinite =
          //       nextArrivalDate !== null &&
          //       nextQuantity !== null &&
          //       currentDate.isAfter(moment(nextArrivalDate)) &&
          //       currentDate.isBefore(
          //         moment(nextArrivalDate).add(shelfLifeNumber, 'days')
          //       );
          //     const finiteQuantity =
          //       arrivalDate !== null &&
          //       currentDate.isAfter(moment(arrivalDate)) &&
          //       !infiniteQuantity;
          //     const quantityCheck =
          //       quantity !== null &&
          //       quantity >= 0 &&
          //       productOrderedQuantity.length >= quantity;

          //     const nextArrivalQuantityCheck =
          //       nextQuantity !== null &&
          //       nextQuantity >= 0 &&
          //       productOrderedQuantity.length >= nextQuantity;

          //     const currentDateAt9 = moment(currentDate).set({
          //       hour: 9,
          //       minute: 0,
          //       second: 0,
          //     });

          //     const currentDateCutoff = currentDateAt9.subtract(
          //       prepTime,
          //       'hours'
          //     );
          //     if (sameDayOrders && timeNow.isBefore(currentDateCutoff)) {
          //       return -1;
          //     } else {
          //       if (!nextArrivalFirst) {
          //         if (productArrival) {
          //           return 1;
          //         } else if (infiniteQuantity) {
          //           if (
          //             nextArrivalDate !== null &&
          //             currentDate.isBefore(moment(nextArrivalDate))
          //           ) {
          //             return 1;
          //           } else if (nextArrivalInfinite) {
          //             return -1;
          //           } else if (nextArrivalFinite) {
          //             if (nextArrivalQuantityCheck) {
          //               return 1;
          //             } else {
          //               return -1;
          //             }
          //           } else {
          //             return -1;
          //           }
          //         } else if (finiteQuantity) {
          //           if (quantityCheck) {
          //             return 1;
          //           } else {
          //             return -1;
          //           }
          //         } else {
          //           if (quantityCheck) {
          //             return 1;
          //           } else {
          //             return -1;
          //           }
          //         }
          //       } else {
          //         if (nextArrival) {
          //           return 1;
          //         } else if (nextArrivalInfinite) {
          //           if (productArrival) {
          //             return 1;
          //           } else if (infiniteQuantity) {
          //             return -1;
          //           } else if (finiteQuantity) {
          //             if (quantityCheck) {
          //               return 1;
          //             } else {
          //               return -1;
          //             }
          //           } else {
          //             return -1;
          //           }
          //         } else if (nextArrivalFinite) {
          //           if (nextArrivalQuantityCheck) {
          //             return 1;
          //           } else {
          //             return -1;
          //           }
          //         } else {
          //           if (nextArrivalQuantityCheck) {
          //             return 1;
          //           } else {
          //             return -1;
          //           }
          //         }
          //       }
          //     }
          //   }
          // )
          .filter(({ name }) =>
            search ? name.toLowerCase().match(searchValue.toLowerCase()) : true
          )
          .filter(({ isAvailable }) => isAvailable)
          .filter(({ availableDates }) => {
            if (showUnavailable) {
              return true;
            }
            if (
              today === currentDayByNumber &&
              todayMonth === currentDayByMonth
            ) {
              if (
                availableDates &&
                availableDates.includes(currentDayFormatted)
              ) {
                return true;
              } else {
                return false;
              }
            } else if (
              tomorrowByNumber === currentDayByNumber &&
              tomorrowByMonth === currentDayByMonth &&
              hour >= 18
            ) {
              if (
                availableDates &&
                availableDates.includes(currentDayFormatted)
              ) {
                return true;
              } else {
                return false;
              }
            }
            if (availableDates) {
              return availableDates.includes(currentDayFormatted);
            }
            return false;
          })
          .map((product) => (
            <GridProductCard
              key={product.id}
              product={product}
              currentDate={currentDate}
              categoryName={name}
              handleOpenProductModal={handleOpenProductModal}
              addItemToOrder={addItemToOrder}
              orderItems={orderItems}
            />
          ))}
      </div>
    </div>
  );
}
