import { BookingItem } from '../types';
import { useCloudinaryImage } from '../helpers';

interface Props {
  item: BookingItem;
}

export default function OrderItemImage({ item }: Props) {
  const { name, image, quantity } = item;
  return (
    <div className="flex shrink-0 justify-between py-4">
      <dl className="flex justify-between divide-gray-200 text-sm">
        <div className="min-h-10 flex max-h-10 min-w-[40px] max-w-[40px] justify-between">
          <img
            className="min-h-10 flex max-h-10 min-w-[40px] max-w-[40px] rounded border object-contain"
            src={useCloudinaryImage(image)}
            alt={name}
          ></img>
        </div>
        <div className="flex w-full flex-col justify-start px-4">
          <dt className="font-medium text-gray-900">{name}</dt>
          <dd className=" text-gray-700">x{quantity}</dd>
        </div>
      </dl>
    </div>
  );
}
