import moment from 'moment';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';

interface Props {
  date: moment.Moment;
  products: OrderState[];
}

export default function SubtotalCard({ date, products }: Props) {
  const day = moment(date).format('D');
  const month = moment(date).format('MMM');
  const currentDayItems = products.filter(
    ({ date: curDate }) =>
      moment(curDate).format('L') === moment(date).format('L')
  );
  const currentDaySubtotal = currentDayItems
    .reduce(
      (
        sum,
        {
          product: {
            product: { price },
          },
        }
      ) => sum + +price,
      0
    )
    .toFixed(2);

  return (
    <div className="mt-4 flex items-center">
      <div className="mr-3 flex h-12 w-12 flex-col items-center justify-center rounded-md bg-indigo-100 text-sm font-medium leading-5 text-indigo-600">
        <div>{month}</div>
        <div>{day}</div>
      </div>
      <div>
        <div className="text-sm leading-5 text-gray-600">{`${products.length} items`}</div>
        <div className="text-sm font-semibold leading-5 text-gray-900">{`Subtotal: $${currentDaySubtotal}`}</div>
      </div>
    </div>
  );
}
