import { DeviceType, getMobileOperatingSystem } from '../helpers';
import Deliveries from './deliveries';
import Login from './login';

const IOS_URL = 'https://apps.apple.com/ca/app/cubbi-2-0/id6444135609';
const ANDROID_URL = 'https://play.google.com/store/apps/details?id=com.cubbi';

export default function AppRedirect() {
  const token = localStorage.getItem('token');
  const device: DeviceType = getMobileOperatingSystem();
  if (device === DeviceType.iOS) {
    window.location.replace(IOS_URL);
    return null;
  } else if (device === DeviceType.Android) {
    window.location.replace(ANDROID_URL);
    return null;
  }

  return token ? <Deliveries /> : <Login />;
}
