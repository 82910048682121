import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames, useCloudinaryImage } from '../helpers';

interface Props {
  image?: string;
  name: string;
  close: () => void;
  fullWidth?: boolean;
}

export default function ProductInfoHeader({
  image,
  fullWidth,
  name,
  close,
}: Props) {
  return (
    <div className="relative flex h-52 w-full justify-center overflow-hidden sm:h-[280px]">
      <img
        src={useCloudinaryImage(image)}
        alt={name}
        className={classNames(
          'mb-4 h-52 object-cover sm:h-[280px]',
          !fullWidth ? 'w-full' : 'w-32'
        )}
      />
      <button
        type="button"
        onClick={close}
        className="absolute inset-y-0 top-0 right-6 mt-4 h-11 items-center rounded-md border  border-gray-300 bg-white px-4 py-3 text-sm font-medium text-white"
      >
        <FontAwesomeIcon icon={faTimes} className="text-gray-400" size="1x" />
      </button>
    </div>
  );
}
