import { useNavigate } from 'react-router-dom';
import { useCloudinaryImage, handleAdditionalDirections } from '../helpers';
import fridgePlaceholderImage from '../img/fridge.png';
import { Fridge } from '../types';
import FridgeImageModal from './fridge-image-modal';
import { useModalToggle } from '../hooks';

interface Props {
  fridge: Fridge;
  change?: boolean;
}

export default function FridgeOrderFridge({ fridge, change }: Props) {
  const navigate = useNavigate();
  const building =
    fridge?.location?.building !== null ? fridge?.location?.building : '';
  const secondLine = `${fridge?.location?.street} ${building}`;
  const floorWithInterpunct =
    fridge?.location?.floor && fridge?.location.room
      ? `Floor ${fridge.location.floor} · `
      : '';
  const floorWithoutInterpunct =
    fridge?.location?.floor && !fridge.location.room
      ? `Floor ${fridge.location.floor}`
      : '';
  const room =
    fridge?.location?.room && !fridge?.location.additionalDirectionsText
      ? `In ${fridge.location?.room} `
      : '';
  const roomWithComma =
    fridge?.location?.room && fridge?.location.additionalDirectionsText
      ? `In ${fridge.location.room},`
      : '';
  const additionalDirections = fridge?.location?.additionalDirections
    ? handleAdditionalDirections(fridge)
    : '';
  const additionalDirectionsText = fridge?.location?.additionalDirectionsText
    ? `${fridge?.location.additionalDirectionsText}`
    : '';
  const thirdLine = `${floorWithInterpunct} ${floorWithoutInterpunct} ${roomWithComma} ${room} ${additionalDirections} ${additionalDirectionsText} `;
  const fridgeImage = useCloudinaryImage(fridge?.image);

  const { open, close, props } = useModalToggle();

  return (
    <>
      <div className="">
        <div className="flex justify-between px-4">
          <div className="text-gray-500">Pickup Pod</div>
        </div>
        <div className="flex cursor-pointer justify-between bg-white px-4 py-4">
          <div className="flex justify-start text-left">
            <div className="mr-4 flex-shrink-0">
              <img
                src={fridgeImage ?? fridgePlaceholderImage}
                alt="cubbi"
                className="h-20 w-20 rounded-xl object-cover"
                onClick={open}
              />
            </div>
            <div>
              <h4 className="text-md text-gray-900">{`${fridge.name}`}</h4>
              <p className="mt-1 text-sm text-gray-500">{secondLine}</p>
              <p className="mt-1 text-sm text-gray-500">{thirdLine}</p>
            </div>
          </div>
        </div>
      </div>
      <FridgeImageModal
        fridge={fridge}
        fridgeImage={fridgeImage}
        visible={props.visible}
        close={close}
        centered
      />
    </>
  );
}
