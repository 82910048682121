import { useState } from 'react';
import { useCloudinaryImage, handleAdditionalDirections } from '../helpers';
import { useModalToggle } from '../hooks';
import { FridgeWithDistance } from '../screens/fridge-select';
import FridgeMapMoreDetails from '../fridges/fridge-map-more-details';
interface Props {
  fridge: FridgeWithDistance;
  handleFridgeSelect: (fridge: FridgeWithDistance) => void;
}

export default function PrestockFridgeMapCard({
  fridge,
  handleFridgeSelect,
}: Props) {
  const { open, close, props } = useModalToggle();
  const [moreDetails, setMoreDetails] = useState(false);
  const logo = useCloudinaryImage(fridge?.owner?.logo);
  const building =
    fridge?.location?.building !== null ? fridge?.location?.building : '';
  const secondLine = `${fridge?.location?.street} ${building}`;
  const floorWithInterpunct =
    fridge?.location?.floor && fridge?.location.room
      ? `Floor ${fridge.location.floor} · `
      : '';
  const floorWithoutInterpunct =
    fridge?.location?.floor && !fridge.location.room
      ? `Floor ${fridge.location.floor}`
      : '';
  const room =
    fridge?.location?.room && !fridge?.location.additionalDirectionsText
      ? `In ${fridge.location?.room} `
      : '';
  const roomWithComma =
    fridge?.location?.room && fridge?.location.additionalDirectionsText
      ? `In ${fridge.location.room},`
      : '';
  const additionalDirections = fridge?.location?.additionalDirections
    ? handleAdditionalDirections(fridge)
    : '';
  const additionalDirectionsText = fridge?.location?.additionalDirectionsText
    ? `${fridge?.location.additionalDirectionsText}`
    : '';
  const thirdLine = `${floorWithInterpunct} ${floorWithoutInterpunct} ${roomWithComma} ${room} ${additionalDirections} ${additionalDirectionsText} `;

  return (
    <>
      <div className="divide-y rounded-xl shadow-xl">
        <div className="w-full">
          <div className="mt-4 flex flex-row items-center py-4 px-4">
            <div>
              <div className="flex flex-row">
                <div
                  className="flex-none cursor-pointer overflow-hidden rounded-xl border-4 border-white shadow-md"
                  onClick={open}
                ></div>
              </div>
            </div>
            <div className=" flex-1 content-end divide-y divide-gray-200">
              <div>
                <div className="flex items-start justify-between">
                  <div className="">
                    <div className="text-sm font-medium leading-5 text-gray-900">
                      {fridge?.name}
                    </div>
                  </div>
                </div>
                {fridge?.serialNumber && (
                  <div className="text-sm leading-[16px] text-gray-500">
                    Pod #{fridge?.serialNumber}
                  </div>
                )}

                {!moreDetails ? (
                  <>
                    <p className="text-sm text-gray-500">{secondLine}</p>
                    <p className=" text-sm text-gray-500">{thirdLine}</p>
                  </>
                ) : (
                  <FridgeMapMoreDetails
                    fridge={fridge}
                    secondLine={secondLine}
                    thirdLine={thirdLine}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="divide-x divide-gray-200">
            <div className="flex items-end divide-x divide-gray-200">
              <button
                className="-ml-px flex w-0 flex-1"
                onClick={() => handleFridgeSelect(fridge)}
              >
                <div className="relative w-0 flex-1 grow items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700">
                  <span className="ml-3">Select</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
