import {
  faAppleAlt,
  faMugHot,
  faShoppingBasket,
  faStar,
  faUtensils,
  IconDefinition,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment';
import { createRef, RefObject, useEffect, useState } from 'react';
import { Day } from 'react-modern-calendar-datepicker';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BottomSheetModal from '../components/bottom-sheet-modal';
import Notification from '../components/notification';
import ProductInfo from '../components/product-info';
import { BOOKING_DAYS, NotificationType } from '../constants';
import { classNames, DateStream, getNodeDataFromEdges } from '../helpers';
import { useModalToggle } from '../hooks';
import CategoryDisplay from '../kitchen/category-display';
import DeliveryDateSelect from '../kitchen/delivery-date-select';
import FeaturedProducts from '../kitchen/featured-products';
import KitchenSearch from '../kitchen/kitchen-search';
import PromoCarousel from '../kitchen/promo-carousel';
import CategoriesSkeleton from '../loading-skeletons/kitchen/categories-skeleton';
import MainContentSkeleton from '../loading-skeletons/kitchen/main-content-skeleton';
import TopNavigation from '../navigation/top-navigation';
import {
  FridgeStatePersistentStorageItem,
  FRIDGE_STATE_KEY,
} from '../persistent-storage/fridge-state.persistent-storage';
import { usePersistentStorageItem } from '../persistent-storage/hooks';
import {
  OrderStatePersistentStorageItem,
  ORDER_STATE_KEY,
} from '../persistent-storage/order-state.persistent-storage';
import PersistentStorage from '../persistent-storage/persistent-storage';
import LayoutProvider from '../providers/layout-provider';
import { useAvailableFridgeDates } from '../services/fridges.service';
import { useCategoriesWithProducts } from '../services/products.service';
import { useMe } from '../services/user.service';
import PlanFirstMealModal from '../signup/onboardingModals/plan-first-meal-modal';
import WalkthroughSteps from '../signup/onboardingModals/walkthrough-steps';
import { Category, Product, ProductObj } from '../types';
import ErrorPage from './error';
import { useFeaturedProducts } from '../services/products.service';

export type CategoryRefType = Category & {
  ref: RefObject<HTMLDivElement>;
  icon: IconDefinition;
};

function GetCategoryIcons(name: string) {
  switch (name) {
    case 'Featured':
      return faStar;
    case 'Snacks':
      return faAppleAlt;
    case 'Drinks':
      return faMugHot;
    case 'Grocery':
      return faShoppingBasket;
    default:
      return faUtensils;
  }
}

export default function KitchenV2() {
  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );
  const fridge = PersistentStorage.get(selectedFridgeState);
  const { categories, loading, error } = useCategoriesWithProducts(
    undefined,
    fridge?.location?.city
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const productModal = useModalToggle();
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const orderState = new OrderStatePersistentStorageItem(ORDER_STATE_KEY);
  const dates = localStorage.getItem(BOOKING_DAYS);
  const today = moment();
  const { data: me } = useMe();
  const [firstTimeUser, setFirstTimeUser] = useState(false);
  const [openPlanMeal, setOpenPlanMeal] = useState(false);
  const [toggledModal, setToggledModal] = useState(false);
  const [showUnavailable, setShowUnavailable] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [categoriesWithRef, setCategoriesWithRef] =
    useState<CategoryRefType[]>();

  const convertedDates: Moment[] = dates
    ? JSON.parse(dates)
        .sort(
          ({ day: a, month: c }: Day, { day: b, month: d }: Day) =>
            c - d || a - b
        )
        .map(({ day, month, year }: Day) => {
          return moment().set({
            date: day,
            months: month - 1,
            year: year,
            hours: today.hours(),
            minutes: today.minutes(),
          });
        })
    : [];
  const [dateStream, setDateStream] = useState(new DateStream(convertedDates));
  const [previous, current, next] = dateStream.slice();
  const {
    featuredProducts,
    loading: featuredLoading,
    error: featuredError,
  } = useFeaturedProducts(fridge?.id, current.toISOString(true));

  const { unavailableDates: currMonth, loading: fridgeDatesLoading } =
    useAvailableFridgeDates(
      convertedDates[0].month() + 1,
      [fridge ? fridge.id : 0],
      me?.email ? me.email : '',
      convertedDates[0].year()
    );

  function checkDates() {
    const dates = convertedDates.map(
      (e) => e.date().toString() + (e.month() + 1).toString()
    );
    if (currMonth && convertedDates) {
      const vals = currMonth.filter((e) =>
        dates?.includes(e.day.toString() + e.month.toString())
      );

      return vals;
    }
  }

  const [orderItems, refreshOrderItems] = usePersistentStorageItem(
    orderState,
    []
  );
  const handleAdded = () =>
    toast(
      <Notification
        type={NotificationType.Success}
        message={''}
        title="Added"
      />,
      {
        position: 'bottom-center',
        closeButton: false,
        autoClose: 1000,
        style: {
          width: '128px',
          position: 'absolute',
          bottom: '16px',
          marginLeft: '-64px',
          left: '50%',
          height: '6px',
          display: 'flex',
          borderRadius: '24px',
          justifyContent: 'center',
          justifyItems: 'center',
        },
      }
    );

  //When the page loads update the cart based on the days selected.
  //This useEffect converts the dates in orderItems and convertedDates to be the same date string, if they exist remove them.
  useEffect(() => {
    if (orderItems) {
      const datesByConversion = convertedDates.map((element) =>
        element.toISOString(true).slice(0, 10)
      );
      const itemsToRemove = orderItems.filter((element) =>
        datesByConversion.includes(element.date.toString().slice(0, 10))
      );
      PersistentStorage.update(orderState, itemsToRemove);
    }
    refreshOrderItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (me?.firstTimeUser) {
      setFirstTimeUser(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstTimeUser && orderItems.length === 0) {
      setOpenPlanMeal(true);
    }
  }, [firstTimeUser]);

  useEffect(() => {
    if (categories) {
      const categoriesWithRefs: CategoryRefType[] = categories.map(
        (category) => ({
          ...category,
          icon: GetCategoryIcons(category.name),
          ref: createRef(),
        })
      );
      setCategoriesWithRef(categoriesWithRefs);
    }
  }, [loading]);

  // if (loading || fridgeDatesLoading) {
  //   return <LoadingPage />;
  // }

  if (error) {
    return (
      <ErrorPage
        error={error?.graphQLErrors.map(({ message }) => `${message}`)}
      />
    );
  }

  function scrollToRef({ current }: React.RefObject<HTMLDivElement>) {
    if (!current) {
      return;
    }
    const offset = 170;
    window.scroll({
      top: current.offsetTop - offset,
      left: 0,
      behavior: 'smooth',
    });
  }

  function handleOpenProductModal(product: Product) {
    setSelectedProduct(product);
    productModal.open();
  }

  function addItemToOrder({ product, addons }: ProductObj) {
    PersistentStorage.update(orderState, [
      ...orderItems,
      {
        product: { product, addons },
        date: current.toISOString(true).substring(0, 10),
      },
    ]);
    refreshOrderItems();
    handleAdded();
    productModal.close();
  }
  const visible = productModal.props.visible;

  if (!dates) {
    navigate('/date-select');
    return null;
  }

  function handleTabChange(
    index: number,
    ref: React.RefObject<HTMLDivElement>
  ) {
    setSelectedTab(index);
    scrollToRef(ref);
  }
  function handleWalkthroughSteps() {
    if (toggledModal && orderItems.length === 0) {
      return 1;
    }
    if (orderItems.length > 0 && toggledModal) {
      return 2;
    }
    return 0;
  }

  return (
    <LayoutProvider cart={orderItems}>
      {!search ? (
        <>
          <TopNavigation
            kitchen
            orderItems={orderItems.length}
            url="/date-select"
          />
          <div
            className={classNames(
              visible ? undefined : 'sticky top-[65px] z-20',
              'bg-gray-50'
            )}
          >
            <DeliveryDateSelect
              dateStream={dateStream}
              setDateStream={setDateStream}
              previous={previous}
              current={current}
              next={next}
              orderItems={orderItems}
              isFullVals={checkDates()}
              firstTimeUser={
                orderItems.length > 0 && firstTimeUser ? true : false
              }
            />
            <WalkthroughSteps
              step={handleWalkthroughSteps()}
              dayOfWeek={moment(current).format('dddd')}
            />
            <div className="flex items-center space-x-4 divide-x">
              {/* <div>
                <ProductFilters
                  availableProductCount={availableProductCount}
                  unavailableProductCount={unavailableProductCount}
                  showUnavailable={showUnavailable}
                  toggleShowUnavailable={() =>
                    setShowUnavailable(!showUnavailable)
                  }
                />
              </div> */}
              <div className="pl-4" onClick={() => setSearch(!search)}>
                <FontAwesomeIcon icon={faSearch} className={'text-gray-500'} />
              </div>
              <nav
                className="-mb-px flex space-x-8 overflow-auto  px-4"
                aria-label="Tabs"
              >
                {!search && (
                  <>
                    {categoriesWithRef ? (
                      categoriesWithRef
                        .sort(({ position: a }, { position: b }) => a - b)
                        .map(({ name, ref, icon }, index) => {
                          return (
                            <div
                              key={name}
                              ref={ref}
                              onClick={() => handleTabChange(index, ref)}
                              className={classNames(
                                index === selectedTab
                                  ? 'border-indigo-500 text-indigo-600'
                                  : 'border-transparent text-gray-500 hover:border-gray-500 hover:text-gray-700',
                                'group mb-2 inline-flex cursor-pointer items-center border-b-2 py-4 px-1 text-sm font-medium'
                              )}
                              aria-current={
                                index === selectedTab ? 'page' : undefined
                              }
                            >
                              <FontAwesomeIcon
                                icon={icon}
                                className={classNames(
                                  index === selectedTab
                                    ? 'text-indigo-500'
                                    : 'text-gray-400 group-hover:text-gray-500',
                                  'mx-1 -ml-0.5 h-4 w-4'
                                )}
                                size="2x"
                                aria-hidden="true"
                              />
                              <span>{name}</span>
                            </div>
                          );
                        })
                    ) : (
                      <CategoriesSkeleton />
                    )}
                  </>
                )}
              </nav>
            </div>
          </div>

          <div className="min-h-full bg-gray-50 ">
            {categoriesWithRef ? (
              categoriesWithRef
                .sort(({ position: a }, { position: b }) => a - b)
                .map((category, index) => {
                  if (index === 0) {
                    return (
                      <div className="mt-2" key={index}>
                        <div className="relative flex justify-start px-4">
                          <span className="pr-4 text-lg font-medium text-gray-900">
                            {category.name}
                          </span>
                        </div>
                        <PromoCarousel email={me?.email} fridge={fridge} />
                        {featuredProducts && featuredProducts.length > 0 && (
                          <FeaturedProducts
                            handleOpenProductModal={handleOpenProductModal}
                            products={featuredProducts
                              .map((e) => e.product)
                              .sort(
                                ({ position: a }, { position: b }) => b - a
                              )}
                            currentDate={current}
                            orderItems={orderItems}
                          />
                        )}
                      </div>
                    );
                  }
                  return (
                    <CategoryDisplay
                      key={index}
                      category={category}
                      currentDate={current}
                      addItemToOrder={addItemToOrder}
                      setSelectedTab={() => setSelectedTab(index)}
                      handleOpenProductModal={handleOpenProductModal}
                      showUnavailable={showUnavailable}
                      orderItems={orderItems}
                      search={search}
                      searchValue={searchValue}
                    />
                  );
                })
            ) : (
              <MainContentSkeleton />
            )}
          </div>
          <PlanFirstMealModal
            open={openPlanMeal}
            setOpen={setOpenPlanMeal}
            message={'Choose your meal and any snacks and drinks'}
            title={'Plan your first meal!'}
            setToggledModal={setToggledModal}
            date={moment(current).format('dddd')}
          />
        </>
      ) : (
        <KitchenSearch
          categoriesWithRef={categoriesWithRef}
          addItemToOrder={addItemToOrder}
          handleOpenProductModal={handleOpenProductModal}
          currentDate={current}
          orderItems={orderItems}
          showUnavailable={showUnavailable}
          setSearchKitchen={setSearch}
        />
      )}
      <div className="max-w-[538px]">
        <BottomSheetModal
          visible={visible}
          close={productModal.close}
          height={0.935}
          blocked={true}
        >
          {selectedProduct && (
            <ProductInfo
              addItemToOrder={addItemToOrder}
              close={productModal.close}
              currentDate={current}
              product={selectedProduct}
              orderItems={orderItems}
            />
          )}
        </BottomSheetModal>
      </div>
    </LayoutProvider>
  );
}
