import { gql, useQuery, useMutation } from '@apollo/client';
import { Fridge, Prestock } from '../types';

export const GET_PRESTOCK_PAYMENT_INTENT = gql`
  mutation getPaymentIntentPrestock($input: [GetPaymentIntentPrestockInput]!) {
    getPaymentIntentPrestock(input: $input) {
      clientSecret
    }
  }
`;

export const GET_PRESTOCK_ITEMS = gql`
  query preStock($fridgeList: [Int], $date: DateTime) {
    preStock(fridgeList: $fridgeList, date: $date) {
      booking {
        id
        startAt
        deliveredAt
        endAt
        cubbi {
          id
          position
          fridge {
            id
            name
          }
        }
        items {
          edges {
            node {
              name
              image
              price
              description
              discountPrice
              vendor
              id
              allergens {
                name
                description
              }
              nutritionfacts {
                servingSize
                calories
                protein
                carbs
                fat
              }
              totalSize
            }
          }
        }
      }
      sold
    }
  }
`;

export function usePrestockItems(fridge: number | undefined, date: string) {
  const { data, loading, error, refetch } = useQuery<{
    preStock: Prestock[];
  }>(GET_PRESTOCK_ITEMS, {
    variables: { fridgeList: [fridge], date },
    skip: !fridge,
    fetchPolicy: 'no-cache',
  });
  const items = data?.preStock;
  return { data: items, loading, error, refetch };
}
