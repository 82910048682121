import TopNavigation from '../navigation/top-navigation';
import { Calendar, Day, utils } from 'react-modern-calendar-datepicker';
import { useEffect, useState, useRef } from 'react';
import {
  faInfoCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useBookingById,
  useUnavailableRescheduleDays,
  useUnavailableRescheduleAll,
} from '../services/orders.service';
import '../calendarconfig/Calendar.css';
import { useLocation } from 'react-router-dom';
import LoadingPage from '../screens/loading';
import { handleMaxDate, handleReformatMonth } from '../helpers';
import moment from 'moment';
import ErrorPage from '../screens/error';
import { ProductWithAddons, RescheduleBooking } from '../types';
import { useMutation } from '@apollo/client';
import { RESCHEDULE_BOOKING } from '../services/orders.service';
import Notification from '../components/notification';
import { NotificationType } from '../constants';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

type CustomDayClassNameItem = Day & { className: string };

interface bookingState {
  bookingIds: number[];
}

export default function RescheduleCalendar() {
  const dayToday = utils('en').getToday();
  const dayTodayMoment = moment();
  const dayTomorrow = moment().add(1, 'days');
  const navigate = useNavigate();
  const dayOfWeek = parseInt(moment().format('d'));
  const currentYear = utils('en').getToday().year;
  const [selectedDay, setSelectedDay] = useState<Day>();
  const convertedDateString = handleConvertDay();
  const [minDate, setMinDate] = useState<Day>();
  const location = useLocation();
  const state = location.state as bookingState;
  const pastCutoff = useRef(false);

  const { data: booking, loading, error } = useBookingById(state.bookingIds[0]);
  const {
    data: unavailableDays,
    loading: unavailableLoading,
    error: unavailableError,
  } = useUnavailableRescheduleAll(
    dayToday.month,
    booking?.booking.owner.email ? booking?.booking.owner.email : '',
    booking?.booking.cubbi.fridge.id
      ? [booking?.booking.cubbi.fridge.id]
      : null,
    dayToday.year,
    state.bookingIds
  );

  const handleSuccess = () =>
    toast(
      <Notification
        type={NotificationType.Success}
        message={`Rescheduled booking to ${convertedDateString}`}
        title="Success"
      />,
      { position: 'top-right', autoClose: 5000 }
    );

  const handleError = () =>
    toast(
      <Notification
        type={NotificationType.Error}
        message={`Error rescheduling booking`}
        title="Success"
      />,
      { position: 'top-right', autoClose: 5000 }
    );

  const [sendRescheduleBooking] =
    useMutation<{ rescheduleBooking: RescheduleBooking }>(RESCHEDULE_BOOKING);

  //Adds custom day formatting for current day of delivery + availableDays
  //I needed to do this because I cannot have two instances of CSS as they apply globally and I didn't want to refactor everything with modules
  function assignAvailableDays(minDate: Day) {
    let availableDays: CustomDayClassNameItem[] = [];
    let endOfMonth = utils('en').getMonthLength(dayToday);
    let totalDays = 28 + (5 - dayOfWeek);
    let tillEndOfMonth = endOfMonth - dayToday.day;
    availableDays = [
      ...availableDays,
      {
        day: minDate.day,
        month: minDate.month,
        year: minDate.year,
        className: 'currentDay',
      },
    ];
    if (totalDays <= endOfMonth - dayToday.day) {
      for (let i = 1; i <= totalDays; i++) {
        availableDays = [
          ...availableDays,
          {
            day: dayToday.day + i,
            month: dayToday.month,
            year: dayToday.year,
            className: 'availableDay',
          },
        ];
      }
    } else if (dayToday.month !== 12) {
      if (totalDays > endOfMonth - dayToday.day) {
        for (let i = 1; i <= tillEndOfMonth; i++) {
          availableDays = [
            ...availableDays,
            {
              day: dayToday.day + i,
              month: dayToday.month,
              year: dayToday.year,
              className: 'availableDay',
            },
          ];
        }
        for (let i = 1; i <= totalDays - tillEndOfMonth; i++) {
          availableDays = [
            ...availableDays,
            {
              day: i,
              month: dayToday.month + 1,
              year: dayToday.year,
              className: 'availableDay',
            },
          ];
        }
      }
    } else if (
      dayToday.month === 12 &&
      totalDays >= endOfMonth - dayToday.day
    ) {
      if (totalDays > endOfMonth - dayToday.day) {
        for (let i = 1; i <= tillEndOfMonth; i++) {
          availableDays = [
            ...availableDays,
            {
              day: dayToday.day + i,
              month: dayToday.month,
              year: dayToday.year,
              className: 'availableDay',
            },
          ];
        }
        for (let i = 1; i <= totalDays - tillEndOfMonth; i++) {
          availableDays = [
            ...availableDays,
            {
              day: i,
              month: 1,
              year: dayToday.year + 1,
              className: 'availableDay',
            },
          ];
        }
      }
    }
    return availableDays;
  }
  //convert the current day to a readable string to display
  function handleConvertDay() {
    if (selectedDay) {
      const month = handleReformatMonth(selectedDay.month);
      const returnString = `${month} ${selectedDay.day}`;
      return returnString;
    }
    return '';
  }

  function handleUnavailableDays() {
    let convertedDays: Day[] = [];
    if (unavailableDays) {
      unavailableDays.unavailableFridgeDaysRescheduleList.forEach((currDay) => {
        if (!currDay.available || !currDay.productAvailable) {
          convertedDays = [
            ...convertedDays,
            { day: currDay.day, month: currDay.month, year: currDay.year },
          ];
        }
      });
    }

    return convertedDays;
  }

  async function handleReschedule() {
    let newDate = '';
    let successCount = 0;
    if (selectedDay) {
      if (selectedDay.month < 10) {
        if (selectedDay.day < 10) {
          newDate = `${selectedDay.year}-0${selectedDay?.month}-0${selectedDay?.day}T00:00:00`;
        } else {
          newDate = `${selectedDay.year}-0${selectedDay?.month}-${selectedDay?.day}T00:00:00`;
        }
      } else {
        if (selectedDay.day < 10) {
          newDate = `${selectedDay.year}-${selectedDay?.month}-0${selectedDay?.day}T00:00:00`;
        } else {
          newDate = `${selectedDay.year}-${selectedDay?.month}-${selectedDay?.day}T00:00:00`;
        }
      }
    }
    for (let i = 0; i < state.bookingIds.length; i++) {
      try {
        const { data, errors } = await sendRescheduleBooking({
          variables: {
            input: {
              bookingId: state.bookingIds[i],
              newTime: newDate,
            },
            skip: newDate === '',
          },
        });
        if (data && !errors) {
          successCount++;
        }
      } catch (e) {
        handleError();
      }
    }
    if (successCount === state.bookingIds.length) {
      navigate('/upcoming');
      handleSuccess();
    }
  }

  //Get min date and convert to calendar date
  useEffect(() => {
    if (booking) {
      let convertedStartAt = booking.booking.startAt;
      let day = parseInt(convertedStartAt.substring(8, 10));
      let month = parseInt(convertedStartAt.substring(5, 7));
      let year = parseInt(convertedStartAt.substring(0, 4));
      let returnVal: Day = {
        day,
        month,
        year,
      };
      setMinDate(returnVal);
      if (
        (day <= dayTodayMoment.date() &&
          dayTodayMoment.month() + 1 === month) ||
        (dayTomorrow.date() === day &&
          dayTomorrow.month() + 1 === month &&
          dayTomorrow.hour() >= 18) ||
        (day <= dayTodayMoment.date() && month <= dayTodayMoment.month() + 1) ||
        (month < dayTodayMoment.month() + 1 && dayTodayMoment.year() === year)
      ) {
        pastCutoff.current = true;
      } else {
        pastCutoff.current = false;
      }
    }
  }, [booking]);

  if (loading || unavailableLoading) {
    return <LoadingPage />;
  }
  if (!booking || error || unavailableError) {
    console.log(booking, error, unavailableError);
    return <ErrorPage error={'Booking does not exist'} />;
  }

  return (
    <>
      <TopNavigation title="Reschedule" />
      <div className="flex flex-row items-center justify-center py-4 px-4 text-sm text-gray-900">
        <div className="text-gray-90 flex flex-row justify-between px-4 text-sm">
          <div className="flex flex-row items-center rounded-lg bg-blue-50 p-4">
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="flex items-center text-xl text-indigo-500"
            />
            <div className="pl-2 text-blue-800">
              Select a new date below. Dates highlighted in green are available
              for reschedule.
            </div>
          </div>
        </div>
      </div>
      {!pastCutoff.current ? (
        <div className="flex justify-center">
          <Calendar
            colorPrimary="#6366f1"
            shouldHighlightWeekends={true}
            value={selectedDay ? selectedDay : null}
            onChange={(value: Day) => setSelectedDay(value)}
            minimumDate={dayToday}
            disabledDays={handleUnavailableDays()}
            maximumDate={handleMaxDate()}
            selectorEndingYear={currentYear}
            selectorStartingYear={currentYear}
            customDaysClassName={minDate ? assignAvailableDays(minDate) : []}
          ></Calendar>
        </div>
      ) : (
        <>
          <div className="pt-2"></div>
          <div className="flex px-8 sm:justify-center">
            <div className="flex w-full rounded-md bg-yellow-100 p-4 sm:w-1/2">
              <div className="flex flex-row text-sm text-yellow-700">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="mt-1"
                />
                <span className="pl-2">
                  Don't see the calendar? One or more bookings are cancelled or
                  past the cutoff to reschedule.
                </span>
              </div>
            </div>
          </div>
        </>
      )}
      {selectedDay ? (
        <div className="flex px-4 sm:justify-center">
          <div className="flex w-full flex-row items-center justify-between py-4 px-4 text-sm text-gray-900 sm:w-1/2">
            <div>Date Selected:</div>
            <span className="px-2">{convertedDateString}</span>
            <button
              type="submit"
              className="group relative flex justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => handleReschedule()}
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
              {'Reschedule'}
            </button>
          </div>
        </div>
      ) : (
        <div className="flex px-4 sm:justify-center">
          <div className="flex w-full flex-row items-center justify-between py-4 px-4 text-sm text-gray-900 sm:w-1/2">
            <div className="sm:px-4">No Date selected</div>
            <span className="px-2">{''}</span>
            <button
              type="submit"
              className="group relative flex justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-sm font-medium text-white opacity-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              disabled={true}
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
              {'Reschedule'}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
