import TopNavigation from '../navigation/top-navigation';
import Dropdown from './dropdown';
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { CHANGE_USER, useMe } from '../services/user.service';
import { useMutation } from '@apollo/client';
import { classNames } from '../helpers';
import LoadingPage from '../screens/loading';
import ErrorPage from '../screens/error';
import { handleDay, handleBackendReminderDays } from '../helpers';

export default function ProfileNotifications() {
  const { data: me, error, loading, refetch } = useMe();
  const [dayChanged, setDayChanged] = useState<string>();
  const [hourChanged, setHourChanged] = useState<string>();
  const [, setHourExists] = useState<string>();
  const [checkedSms, setCheckedSms] = useState(false);
  const [checkedEmail, setCheckedEmail] = useState<boolean>(false);
  const [enabled, setEnabled] = useState(false);
  const [updateUser] = useMutation<{ lastName: string }>(CHANGE_USER);

  const DaysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const hours = [
    '12:00 am',
    '1:00 am',
    '2:00 am',
    '3:00 am',
    '4:00 am',
    '5:00 am',
    '6:00 am',
    '7:00 am',
    '8:00 am',
    '9:00 am',
    '10:00 am',
    '11:00 am',
    '12:00 pm',
    '1:00 pm',
    '2:00 pm',
    '3:00 pm',
    '4:00 pm',
    '5:00 pm',
    '6:00 pm',
    '7:00 pm',
    '8:00 pm',
    '9:00 pm',
    '10:00 pm',
    '11:00 pm',
  ];

  async function handleUpdateNotifications() {
    if (me?.receiveReminders !== undefined) {
      const { data, errors } = await updateUser({
        variables: {
          id: me?.pk,
          input: {
            receiveReminders: enabled === false ? 'True' : 'False',
          },
        },
      });
      if (data) {
        await refetch();
      } else {
        console.log(errors);
      }
    }
  }

  // async function handleEmail() {
  //   if (me?.emailReminders !== undefined) {
  //     if (!checkedEmail && checkedSms) {
  //       try {
  //         const { data, errors } = await updateUser({
  //           variables: {
  //             id: me?.pk,
  //             input: {
  //               emailReminders: !checkedEmail,
  //               smsReminders: false,
  //             },
  //           },
  //         });
  //         if (data) {
  //           await refetch();
  //         } else {
  //           console.log(errors);
  //         }
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     } else {
  //       try {
  //         const { data, errors } = await updateUser({
  //           variables: {
  //             id: me?.pk,
  //             input: {
  //               emailReminders: !checkedEmail,
  //             },
  //           },
  //         });
  //         if (data) {
  //           await refetch();
  //         } else {
  //           console.log(errors);
  //         }
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     }
  //   }
  // }

  // async function handleSms() {
  //   if (me?.smsReminders !== undefined) {
  //     if (checkedEmail && !checkedSms) {
  //       try {
  //         const { data, errors } = await updateUser({
  //           variables: {
  //             id: me?.pk,
  //             input: {
  //               smsReminders: !checkedSms,
  //               emailReminders: false,
  //             },
  //           },
  //         });
  //         if (data) {
  //           await refetch();
  //         } else {
  //           console.log(errors);
  //         }
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     } else {
  //       try {
  //         const { data, errors } = await updateUser({
  //           variables: {
  //             id: me?.pk,
  //             input: {
  //               smsReminders: !checkedSms,
  //             },
  //           },
  //         });
  //         if (data) {
  //           await refetch();
  //         } else {
  //           console.log(errors);
  //         }
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     }
  //   }
  // }

  //convert time to correct time string for backend
  function handleHour() {
    if (hourChanged) {
      let hour = hourChanged.substring(0, 2);
      let type = hourChanged.slice(hourChanged.length - 2);
      if (hour.substring(1) === ':') {
        hour = hour.substring(0, 1);
      }
      if (type === 'am' && hour === '12') {
        return '00:00:00';
      } else if (type === 'pm' && hour === '12') {
        return '12:00:00';
      } else if (type === 'am' && hour.length === 1) {
        return `0${hour}:00:00`;
      } else if (type === 'am' && hour.length === 2) {
        return `${hour}:00:00`;
      } else if (type === 'pm' && hour.length === 1) {
        return `${parseInt(hour) + 12}:00:00`;
      } else {
        return `${parseInt(hour) + 12}:00:00`;
      }
    }
  }

  function handleBackendHours(hour: string | undefined) {
    if (hour) {
      let hours = parseInt(hour.substring(0, 2));
      if (hours === 0) {
        return '12:00 am';
      } else if (hours === 12) {
        return '12:00 pm';
      } else if (hours < 12) {
        return `${hours}:00 am`;
      } else if (hours > 12) {
        return `${hours - 12}:00 pm`;
      }
    }
    return '';
  }

  async function handleTime() {
    if (hourChanged && dayChanged) {
      if (
        me &&
        (handleBackendReminderDays(me?.reminderDays) !== dayChanged ||
          handleBackendHours(me?.reminderTime) !== hourChanged)
      ) {
        let hour = handleHour();
        let day = handleDay(dayChanged);
        try {
          const { data, errors } = await updateUser({
            variables: {
              id: me?.pk,
              input: {
                reminderDays: day,
                reminderTime: hour,
              },
            },
          });
          if (data) {
            await refetch();
          } else {
            console.log(errors);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  useEffect(() => {
    refetch();
  }, [refetch]);

  //enabling + disabling if its checked also toggles opacity and functionality
  useEffect(() => {
    if (me?.receiveReminders === true) {
      setEnabled(true);
    } else if (me?.receiveReminders === false) {
      setEnabled(false);
    }
  }, [me]);
  //enabling + disabling smsReminders
  // useEffect(() => {
  //   if (me?.smsReminders) {
  //     setCheckedSms(true);
  //   } else {
  //     setCheckedSms(false);
  //   }
  // }, [me]);
  //enabling + disabling emailReminders
  // useEffect(() => {
  //   if (me?.emailReminders) {
  //     setCheckedEmail(true);
  //   } else {
  //     setCheckedEmail(false);
  //   }
  // }, [me]);
  //setting date and time
  useEffect(() => {
    if (me?.reminderTime) {
      setHourExists(me?.reminderTime);
    }
  }, [me]);

  useEffect(() => {
    if (me?.reminderDays) {
      let day = handleBackendReminderDays(me?.reminderDays);
      setDayChanged(day);
    }
    if (me?.reminderTime) {
      let hour = handleBackendHours(me?.reminderTime);
      setHourChanged(hour);
    }
  }, [me]);

  //automatically send info to the backend and convert it to a format backend can understand
  useEffect(() => {
    if (hourChanged && dayChanged) {
      handleTime();
    }
  }, [hourChanged, dayChanged]);

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage error={error.graphQLErrors.map((e) => e.message)} />;
  }
  return (
    <div className="flex min-h-full min-w-full flex-col">
      <div className="sm:flex sm:justify-center">
        <div className="sm:w-1/2">
          <TopNavigation title="Reminders" url="/account" />
        </div>
      </div>
      <div className="px-6">
        <div className="sm:flex sm:justify-center">
          <div className="flex items-center py-6 sm:w-1/2">
            <Switch
              checked={enabled}
              onChange={handleUpdateNotifications}
              onColor="#4f46e5"
              checkedIcon={false}
              uncheckedIcon={false}
            />
            <span className="pl-3">Receive Reminders</span>
          </div>
        </div>
        <div className="sm:flex sm:justify-center">
          <div
            className={classNames(
              enabled
                ? 'opacity-100 sm:flex sm:w-1/2 sm:flex-col'
                : 'opacity-50 sm:flex sm:w-1/2 sm:flex-col'
            )}
          >
            <div className="pb-4">Receive Reminders via:</div>
            <div className="pb-4">
              {me && me?.receiveReminders ? (
                <input
                  type={'checkbox'}
                  // onChange={handleEmail}
                  checked={checkedEmail}
                ></input>
              ) : (
                <input
                  type={'checkbox'}
                  // onChange={handleEmail}
                  disabled
                  checked={false}
                ></input>
              )}
              <span className="pl-2">Email</span>
            </div>
            <div>
              {me && me?.receiveReminders ? (
                <input
                  type="checkbox"
                  // onChange={handleSms}
                  checked={checkedSms}
                ></input>
              ) : (
                <input
                  type="checkbox"
                  disabled
                  // onChange={handleSms}
                  checked={false}
                ></input>
              )}

              <span className="pl-2">SMS</span>
            </div>

            <div className="pt-6 pb-4">Send reminders on:</div>
            <div className="min-w-screen">
              <div
                className={classNames(
                  enabled
                    ? 'flex w-full items-center justify-between'
                    : 'pointer-events-none flex w-full items-center justify-between'
                )}
              >
                <Dropdown
                  title={enabled ? (dayChanged ? dayChanged : 'Day') : 'Day'}
                  items={DaysOfWeek}
                  functionToSend={setDayChanged}
                  changed={dayChanged ? true : false}
                  width={'w-[131.5px]'}
                  widthOfDropdown={'w-32'}
                />
                <span>at</span>
                <Dropdown
                  title={
                    enabled ? (hourChanged ? hourChanged : 'Time') : 'Time'
                  }
                  items={hours}
                  functionToSend={setHourChanged}
                  changed={hourChanged ? true : false}
                  width={'w-[131.5px]'}
                  widthOfDropdown={'w-32'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
