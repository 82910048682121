import { useState } from 'react';
import React from 'react';
import ProgressIndicator from '../../onboarding/progress-indicator';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import AlreadyMemberButton from '../../onboarding/already-member-button';
import { classNames } from '../../helpers';
import TOSText from '../../onboarding/tos-text';
import { PhoneErrorCodes } from '../../constants';
import { toast } from 'react-toastify';
import Notification from '../../components/notification';
import { NotificationType } from '../../constants';
import { useMutation } from '@apollo/client';
import { RegisterResponse } from '../../types';
import { REGISTER } from '../../services/auth.service';
import { GenericResponse } from '../../types';
import { SEND_VERIFY_SMS } from '../../services/user.service';
import { SEND_SMS_LOGIN_TOKEN } from '../../services/auth.service';

export default function SignupPhone() {
  const [phone, setPhone] = useState<string>();
  const [phoneChanged, setPhoneChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [register] = useMutation<{ register: RegisterResponse }>(REGISTER);
  const [sendSms] = useMutation<{
    sendVerifySms: GenericResponse;
  }>(SEND_VERIFY_SMS);
  const [sendLoginToken] =
    useMutation<{ sendSmsLoginToken: GenericResponse }>(SEND_SMS_LOGIN_TOKEN);

  const handleError = (message: string) =>
    toast(
      <Notification
        type={NotificationType.Error}
        message={`${message}`}
        title="Error"
      />,
      { position: 'top-right', autoClose: 5000 }
    );

  //@ts-ignore
  function beforeMaskedStateChange({ nextState }) {
    let { value } = nextState;
    if (
      value.startsWith('+1 (') &&
      nextState.enteredString === '' &&
      nextState.selection.end === 4
    ) {
      value = '';
    }

    return {
      ...nextState,
      value,
    };
  }

  async function registerPhoneNumber() {
    setLoading(true);
    const { data } = await register({
      variables: {
        firstName: '',
        lastName: '',
        email: '',
        phone: phone,
        birthDate: '1111-11-11',
        password1: 'password123',
        password2: 'password123',
      },
      onError: (e) => {
        handleError(e.message);
      },
    });
    setLoading(false);
    return data;
  }

  function handleChangePhone(val: string) {
    if (val === '' || val === '(') {
      setPhoneChanged(false);
    } else {
      setPhone(val);
      setPhoneChanged(true);
    }
  }

  async function handleNext() {
    const data = await registerPhoneNumber();
    // If user already exists and verified, send login code
    if (
      data?.register?.errors?.phone?.some(
        (e: any) => e.code === PhoneErrorCodes.AleadyExistsVerified
      )
    ) {
      sendLoginCode();
    } else {
      sendSignupCode();
    }
  }

  async function sendSignupCode() {
    setLoading(true);
    await sendSms({
      variables: {
        input: {
          phone,
        },
      },
      onError: (e: any) => {
        handleError(e.message);
      },
      onCompleted: (_) => {
        navigate('/signup-phone-verify', {
          state: {
            phone: phone,
          },
        });
        setLoading(false);
      },
    });
  }

  async function sendLoginCode() {
    setLoading(true);
    await sendLoginToken({
      variables: {
        input: { phone },
      },
      onError: (e: any) => {
        handleError(e.message);
        setLoading(false);
      },
      onCompleted: (_) => {
        navigate('/signup-login-code', {
          state: { phone: phone, isEmail: false },
        });
        setLoading(false);
      },
    });
  }

  return (
    <div className={`flex h-screen w-screen justify-center bg-background p-4`}>
      <div className="lg:w-3/8">
        <div className="items-center">
          <ProgressIndicator filled={1} length={4} />
          <div
            className="flex flex-row items-center text-center"
            onClick={() => navigate('/login')}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="text-xl text-gray-500"
            />
            <div className={`pl-4 text-2xl font-bold text-graphite-900`}>
              Phone number
            </div>
          </div>
          <div className="py-4 text-graphite-900">
            Enter your phone number for delivery status updates.
          </div>
          <div className="flex flex-col rounded-lg bg-white py-4 px-6 pt-4 shadow-lg sm:w-96">
            <div className="py-2">
              <InputMask
                mask={phoneChanged ? '+1 (999) 999-9999' : ''}
                autoFocus={true}
                maskPlaceholder={null}
                onChange={(e) => handleChangePhone(e.target.value)}
                beforeMaskedStateChange={beforeMaskedStateChange}
                placeholder={'+1 (555) 987-6543'}
                className={
                  'h-[42px] w-full rounded-lg border px-4 text-gray-500 shadow-sm'
                }
                inputMode={'numeric'}
                type="text"
                autoComplete="tel"
              ></InputMask>
              <div className="py-4">
                <button
                  type="submit"
                  disabled={!phone || phone.length < 17}
                  className={classNames(
                    phone && phone.length === 17
                      ? 'bg-indigo-600'
                      : 'bg-indigo-600/20',
                    'group relative flex w-full justify-center rounded-md border border-transparent p-3 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  )}
                  onClick={() => handleNext()}
                >
                  <span className="flex items-center text-base"></span>
                  {loading ? 'Loading...' : 'Next'}
                </button>
              </div>
              <AlreadyMemberButton />
            </div>
          </div>
          <div className="w-96">
            <TOSText />
          </div>
        </div>
      </div>
    </div>
  );
}
