import { useCloudinaryImage } from '../helpers';

interface Props {
  image: string | undefined;
  name: string;
}

export default function FeedbackProductImage({ image, name }: Props) {
  return (
    <div className="flex items-center justify-center">
      <img
        src={useCloudinaryImage(image)}
        className="h-[100px] w-[100px] object-contain"
      ></img>
    </div>
  );
}
