import {
  faChevronRight,
  faCreditCard,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../components/alert';
import BottomSheetModal from '../components/bottom-sheet-modal';
import Money, {
  classNames,
  DeviceType,
  getMobileOperatingSystem,
} from '../helpers';
import { useModalToggle } from '../hooks';
import {
  OrderStatePersistentStorageItem,
  ORDER_STATE_KEY,
} from '../persistent-storage/order-state.persistent-storage';
import PersistentStorage from '../persistent-storage/persistent-storage';
import { CreditCard, User } from '../types';
import PaymentMethodSelect from './payment-method-select';
import { useMutation } from '@apollo/client';
import { CHANGE_USER } from '../services/user.service';

interface Props {
  clientSecret: string;
  defaultCard: CreditCard | null;
  user: User;
  refetchUser: () => void;
  total: number;
  handleGetPaymentIntent: () => void;
}

export enum PaymentTypeSelection {
  CreditCard = 'CreditCard',
  PaymentRequest = 'PaymentRequest',
}

export default function CheckoutForm({
  clientSecret,
  defaultCard,
  user,
  refetchUser,
  total,
  handleGetPaymentIntent,
}: Props) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const { props, close, open } = useModalToggle();
  const [paymentType, setPaymentType] = useState<PaymentTypeSelection>(
    PaymentTypeSelection.CreditCard
  );
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const elements = useElements();
  const [creditCardPaymentLoading, setCreditCardPaymentLoading] =
    useState(false);
  const [creditCard, setCreditCard] = useState<CreditCard | null>(defaultCard);
  const [errors, setErrors] = useState<string[]>([]);
  const [updateUser] = useMutation<{ lastName: string }>(CHANGE_USER);

  //Reroutes the user conditionally to reminders every 3rd without reminders setup
  function handleRerouting(paymentIntent: string) {
    if (!user.receiveReminders) {
      if (user.reminderCounter % 3 === 0) {
        return `/reminders/${paymentIntent}`;
      }
      return `/order-success/${paymentIntent}`;
    } else {
      return `/order-success/${paymentIntent}`;
    }
  }

  const submitCreditCardPayment = async (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();

    try {
      const { data, errors } = await updateUser({
        variables: {
          id: user.pk,
          input: {
            reminderCounter: user ? user?.reminderCounter + 1 : 1,
          },
        },
      });
      if (!data || errors) {
      }
    } catch (e) {}

    if (!stripe || !elements) {
      return alert('Stripe is not loaded');
    }

    if (!creditCard) {
      return alert('Please enter a credit card');
    }
    setCreditCardPaymentLoading(true);
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: creditCard.paymentMethodId,
    });

    if (!result || result?.error?.message || !result?.paymentIntent) {
      setErrors([result.error.message!]);
      setCreditCardPaymentLoading(false);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        console.log('Payment succeeded');
        setTimeout(() => {
          setCreditCardPaymentLoading(false);
          PersistentStorage.clear(
            new OrderStatePersistentStorageItem(ORDER_STATE_KEY)
          );
          navigate(handleRerouting(result.paymentIntent.id));
        }, 3500);
      }
    }
  };

  // put getPaymentRequestButton into a use callback to avoid re-rendering
  // when the payment request is not ready
  const getPaymentRequestButton = useCallback(() => {
    if (!stripe || !clientSecret) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'CA',
      currency: 'cad',
      total: {
        label: 'Total',
        amount: new Money(total).stripe(),
      },
    });

    pr.canMakePayment().then((canMakePaymentRes) => {
      if (canMakePaymentRes) {
        // @ts-ignore
        setPaymentRequest(pr);
        setPaymentType(PaymentTypeSelection.PaymentRequest);
      }
    });
  }, [clientSecret, stripe, total]);

  useEffect(() => {
    if (!paymentRequest || !clientSecret || !stripe) {
      return;
    }

    // @ts-ignore
    paymentRequest.on('paymentmethod', async (event: any) => {
      event.complete('success');
      setPaymentMethod(event.paymentMethod);
      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: event.paymentMethod.id },
          { handleActions: false }
        );
      if (confirmError) {
        alert(confirmError.message);
        return;
      }
      if (!paymentIntent) {
        alert('Error no payment found');
        return;
      }
      localStorage.removeItem('discountCode');
      setTimeout(() => {
        PersistentStorage.clear(
          new OrderStatePersistentStorageItem(ORDER_STATE_KEY)
        );
        navigate(`/order-success/${paymentIntent.id}`);
      }, 3500);
    });
  }, [paymentRequest, clientSecret, stripe, navigate]);

  useEffect(() => {
    getPaymentRequestButton();
  }, [getPaymentRequestButton]);

  const deviceType = getMobileOperatingSystem();
  const payWith =
    deviceType === DeviceType.Android ? 'Google Pay' : 'Apple Pay';
  return (
    <>
      <BottomSheetModal
        visible={props.visible}
        close={close}
        blocked={true}
        height={0.401}
      >
        <PaymentMethodSelect
          paymentType={paymentType}
          setPaymentType={setPaymentType}
          hasPaymentRequest={!!paymentRequest}
          defaultCard={creditCard}
          handleGetPaymentIntent={handleGetPaymentIntent}
          user={user}
          close={() => {
            getPaymentRequestButton();
            close();
          }}
          setCreditCard={setCreditCard}
          refetchUser={refetchUser}
        />
      </BottomSheetModal>
      <div className="mb-12 rounded-md py-4 px-6 shadow-md">
        {errors.length > 0 && <Alert messages={errors} />}
        <div className="flex cursor-pointer justify-between" onClick={open}>
          <div>
            <FontAwesomeIcon icon={faCreditCard} className="mr-1.5" />
            Payment
          </div>
          <div>
            {paymentType === PaymentTypeSelection.CreditCard
              ? creditCard
                ? creditCard.last4
                : 'Add Credit Card'
              : payWith}
            <FontAwesomeIcon icon={faChevronRight} className="ml-2.5" />
          </div>
        </div>
        <div className="my-8">
          {paymentRequest &&
          paymentType === PaymentTypeSelection.PaymentRequest ? (
            <PaymentRequestButtonElement
              onClick={(event) => {
                if (paymentMethod) {
                  event.preventDefault();
                  setErrors([
                    'You already paid, go to home and refresh your cart to process a new order',
                  ]);
                }
              }}
              options={{
                style: {
                  paymentRequestButton: {
                    type: 'order',
                    theme: 'dark',
                    height: '50px',
                  },
                },
                paymentRequest,
              }}
            />
          ) : (
            <button
              onClick={submitCreditCardPayment}
              type="button"
              disabled={!defaultCard}
              className={classNames(
                defaultCard ? 'bg-black hover:bg-gray-800' : 'bg-gray-300',
                'w-full rounded-lg border border-transparent  py-3.5 px-4 text-base font-medium text-white shadow-sm  '
              )}
            >
              {defaultCard
                ? creditCardPaymentLoading
                  ? 'Submitting Order...'
                  : 'Submit Order'
                : 'Credit Card Required'}
            </button>
          )}
        </div>
      </div>
    </>
  );
}
