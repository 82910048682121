import OrderReceipt from '../components/order-receipt';
import LayoutProvider from '../providers/layout-provider';

export default function Receipt() {
  return (
    <LayoutProvider>
      <OrderReceipt />
    </LayoutProvider>
  );
}
