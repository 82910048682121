import { Fragment, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Dispatch, SetStateAction } from 'react';
import { useMutation } from '@apollo/client';
import { CHANGE_USER } from '../services/user.service';
import { User } from '../types';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  me: User | undefined;
}

export default function PrestockTooltipModal({ open, setOpen, me }: Props) {
  const cancelButtonRef = useRef(null);

  const [updateUser] = useMutation<{ lastName: string }>(CHANGE_USER);

  async function handleClose() {
    setOpen(false);
  }

  async function update() {
    if (me) {
      try {
        const { data, errors } = await updateUser({
          variables: {
            id: me?.pk,
            input: {
              prestockPrompt: false,
            },
          },
        });
      } catch (e) {}
    }
  }

  useEffect(() => {
    update();
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 flex overflow-y-auto pt-64 sm:-my-4 sm:justify-end sm:pr-4 lg:justify-center"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
        aria-hidden="true"
      >
        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="flex px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="h-3/4">
              <div className="relative inline-block transform rounded-lg bg-[#DBEAFE] px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:justify-end sm:p-6 sm:align-middle">
                <div className="flex justify-end pr-4">
                  <div className="-mt-[36px] flex h-4 w-4 origin-bottom-left rotate-45 transform bg-[#DBEAFE]"></div>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="text-start sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-bold leading-6 text-blue-800"
                    >
                      📣 Introducing Grab 'n Go
                    </Dialog.Title>
                    <div className="mt-3">
                      <span className="text-[15px] font-medium leading-[22px] text-blue-800">
                        Our most popular items are stocked daily at
                      </span>
                      <span className="text-[15px] font-bold leading-[22px] text-blue-800">
                        {' '}
                        11:30AM.
                      </span>
                      <span className="text-[15px] font-medium leading-[22px] text-blue-800">
                        {' '}
                        Choose from the Grab 'n Go menu and pick up within 15
                        minutes. Best part—order at any time and no cutoff
                        times!
                      </span>
                    </div>
                    <div className="pt-4 text-base font-medium leading-[22px] text-blue-800">
                      *Only available at select locations.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
