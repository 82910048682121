import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  cutOffDay: string;
  productCutOff: string;
}

export default function SoldOutInfo({ cutOffDay, productCutOff }: Props) {
  return (
    <div className="mx-4 rounded-lg bg-blue-50 px-4 py-3 text-blue-900">
      <div className="flex items-center">
        <div className="pt-0.5 pr-4">
          <FontAwesomeIcon icon={faClock} className="text-blue-400" />
        </div>
        <div>
          <p className="text-sm font-medium text-blue-800">{productCutOff}</p>
        </div>
      </div>
    </div>
  );
}
