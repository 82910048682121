import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { User } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import ProgressIndicator from '../../onboarding/progress-indicator';
import { classNames } from '../../helpers';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CHANGE_USER } from '../../services/user.service';
import Notification from '../../components/notification';
import { toast } from 'react-toastify';
import { NotificationType } from '../../constants';
import { LOGIN } from '../../services/auth.service';
import { AuthResponse } from '../../types';
import { TOKEN, REFRESH_TOKEN } from '../../constants';
import { valideEmailBoolean } from '../../helpers';
import { useContext } from 'react';
import { AuthContext } from '../../providers/auth-provider';

interface userState {
  user: User;
}

export default function SignupEmail() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as userState;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const { setAuthState } = useContext(AuthContext);
  const { pk, phone, firstName } = state.user;

  const [updateUser] =
    useMutation<{ updateUser: { ok: boolean } }>(CHANGE_USER);

  const handleError = (message: string) =>
    toast(
      <Notification
        type={NotificationType.Error}
        message={`${message}`}
        title="Error"
      />,
      { position: 'top-center', autoClose: 5000 }
    );

  const [login] = useMutation<{
    tokenAuth: AuthResponse;
  }>(LOGIN);

  async function tempGetNewTokens() {
    const { data } = await login({
      variables: {
        phone: phone,
        password: 'password123',
      },
      onError: (e) => {
        handleError(e.message);
      },
    });
    if (data) {
      localStorage.setItem(TOKEN, data.tokenAuth.token);
      localStorage.setItem(REFRESH_TOKEN, data.tokenAuth.refreshToken);
      setAuthState({ token: data.tokenAuth.token, setAuthState });
    }
  }

  async function updateEmail() {
    setLoading(true);
    const { data, errors } = await updateUser({
      variables: {
        id: pk,
        input: {
          email: email,
        },
      },
      onError: (e) => {
        if (e.message.includes('already exists')) {
          setEmailError(
            'This email address already exists. Please log-in using this email address or enter a new one.'
          );
        } else {
          handleError(e.message);
        }
        setLoading(false);
      },
    });
    return { data };
  }

  async function handleNext() {
    if (valideEmailBoolean(email)) {
      const { data } = await updateEmail();
      if (data && !emailError) {
        //Remove once token issue is fixed
        await tempGetNewTokens();
        navigate('/upcoming');
        setLoading(false);
      }
    } else {
      handleError('Please enter a valid email');
    }
  }
  return (
    <div className={`flex h-screen w-screen justify-center bg-background p-4`}>
      <div className="lg:w-3/8">
        <div className="items-center">
          <ProgressIndicator filled={4} length={4} />
          <div
            className="flex flex-row items-center text-center"
            onClick={() => navigate('/signup-name')}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="text-xl text-gray-500"
            />
            <div className={`pl-4 text-2xl font-bold text-graphite-900`}>
              Your name
            </div>
          </div>
          <div className="py-4 text-graphite-900">
            <span>Please enter your name.</span>
          </div>
          <div className="flex flex-col rounded-lg bg-white py-4 px-6 pt-4 shadow-lg sm:w-96">
            <div className="py-2">
              <input
                className={
                  'h-[42px] w-full rounded-lg border border-graphite-300 px-4 text-graphite-500 shadow-sm'
                }
                placeholder="you@example.com"
                type="name"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              ></input>
              <div className="pb-4" />
              <div className="py-4">
                <button
                  type="submit"
                  disabled={email.length === 0}
                  className={classNames(
                    email.length === 0 ? 'bg-indigo-600/20' : 'bg-indigo-600',
                    'group relative flex w-full justify-center rounded-md border border-transparent p-3 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  )}
                  onClick={() => handleNext()}
                >
                  <span className="flex items-center text-base"></span>
                  {loading ? 'Loading...' : 'Next'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
