import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { classNames } from '../helpers';
import { Fridge } from '../types';
import PrestockDropdown from './prestock-dropdown';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  fridges: Fridge[] | undefined;
  selectedFridge: Fridge | undefined;
  setSelectedFridge: Dispatch<SetStateAction<Fridge | undefined>>;
  title?: string;
  shadow?: boolean;
  url?: string;
  kitchen?: boolean;
  orderItems?: number;
}

export default function PrestockTopNavigation({
  title,
  url,
  shadow,
  fridges,
  selectedFridge,
  setSelectedFridge,
}: Props) {
  const navigate = useNavigate();
  const navigationURL = url
    ? () => navigate(url)
    : () => navigate('/prestock-fridges');
  return (
    <div className="sticky top-0 z-20 bg-white">
      <div
        key={'navigation'}
        className={classNames(
          shadow ? 'shadow-sm' : undefined,
          'flex justify-between bg-white py-5 px-6'
        )}
      >
        <button onClick={navigationURL}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            size="lg"
            className="text-gray-400"
          />
        </button>
        <div className="text-base font-medium leading-6 text-gray-900">
          {title ?? 'Order Food'}
        </div>
        <div />
      </div>
      <div className="px-4">
        <div className="text-sm text-gray-500">Pickup Location</div>
        <PrestockDropdown
          title={selectedFridge ? selectedFridge.name : 'Pods'}
          changed={selectedFridge ? true : false}
          items={fridges}
          functionToSend={setSelectedFridge}
        />
      </div>
      <div className="-mx-4 border-b"></div>
    </div>
  );
}
