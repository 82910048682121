import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import TopNavigation from '../navigation/top-navigation';
import DeliveryDetails from '../order-summary/delivery-details';
import StripeElements from './stripe-elements';
import { useMe } from '../services/user.service';
import { useSubByEmail } from '../services/subscriptions.service';
import LoadingPage from '../screens/loading';
import ErrorPage from '../screens/error';
const stripe = loadStripe(process.env.REACT_APP_STRIPE_PK!);

export default function SubscriptionCheckout() {
  const { data: me, loading } = useMe();
  const {
    data: subscriptions,
    loading: subLoading,
    error,
  } = useSubByEmail(me?.email);

  if (loading || subLoading || !subscriptions) {
    return <LoadingPage />;
  }
  if (error) {
    return <ErrorPage />;
  }
  return (
    <Elements stripe={stripe}>
      <TopNavigation title="Checkout" url="/order-summary" />
      <div className="px-5">
        <div className="my-2 flex items-center leading-8">
          <div className="mr-4 mt-0.5 text-xl text-indigo-500">$</div>
          <div className="text-2xl font-semibold">Payment details</div>
        </div>
        <div className="leading-6 text-gray-700">
          <span className="font-semibold">
            Don't worry! We won't charge your credit card now.
          </span>
          <span className="pl-1 leading-6 ">
            Your card details are needed to secure and plan your upcoming
            orders. Payment is taken every Sunday at 11 a.m. All discounts are
            automatically applied.
          </span>
        </div>
        <StripeElements />
      </div>
      <DeliveryDetails
        fromMealPlan={true}
        fridgeFromSub={
          subscriptions && subscriptions[0] && subscriptions[0].fridge
        }
      />
    </Elements>
  );
}
