import { useMutation, useLazyQuery } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Notification from '../components/notification';
import { NotificationType, REFRESH_TOKEN, TOKEN } from '../constants';
import logo from '../img/logo-black-square.png';
import { AuthContext } from '../providers/auth-provider';
import { LOGIN } from '../services/auth.service';
import { AuthResponse, User } from '../types';
import { useTranslation } from 'react-i18next';
import LanguageControl from '../components/language-control';
import {
  FridgeStatePersistentStorageItem,
  FRIDGE_STATE_KEY,
} from '../persistent-storage/fridge-state.persistent-storage';
import { usePersistentStorageItem } from '../persistent-storage/hooks';
import { BOOKING_DAYS } from '../constants';
import { ORDER_STATE_KEY } from '../persistent-storage/order-state.persistent-storage';
import { SEND_VERIFY_SMS } from '../services/user.service';
import sign from 'jwt-encode';
import { CHECK_PHONE } from '../services/auth.service';
interface LoginForm {
  email: string;
  password: string;
}
const initialValues: LoginForm = { email: '', password: '' };

const handleError = () =>
  toast(
    <Notification
      type={NotificationType.Error}
      message={'Error logging in, please try again'}
      title="Error"
    />,
    { position: 'top-right', autoClose: 5000 }
  );

export default function Login() {
  const { rerouteLocation } = useParams();
  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );
  const [selectedFridge] = usePersistentStorageItem(
    selectedFridgeState,
    undefined
  );
  const [login] = useMutation<{ tokenAuth: AuthResponse }>(LOGIN);
  const [sendSms] =
    useMutation<{ sendVerifySms: { ok: boolean; success: string } }>(
      SEND_VERIFY_SMS
    );
  const [checkPhone] = useLazyQuery<{
    phoneNumber: User;
  }>(CHECK_PHONE);
  const { setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  localStorage.removeItem(BOOKING_DAYS);
  localStorage.removeItem(ORDER_STATE_KEY);

  async function handleLogin({ email, password }: LoginForm) {
    try {
      setLoading(true);
      const { data } = await login({
        variables: { email: email.toLowerCase().trim(), password },
      });
      setLoading(false);
      if (!data || data?.tokenAuth.errors) {
        if (data) {
          let errors = data.tokenAuth.errors?.nonFieldErrors.map(
            (e) => e.message
          );
          let result = errors?.filter((e) =>
            errors?.includes('Please verify your account.')
          );
          if (result && result.length > 0) {
            handleRedirectWithSms(email, password);
            return;
          }
        }
        return void handleError();
      }
      const { token, refreshToken } = data.tokenAuth;
      localStorage.setItem(TOKEN, token);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      setAuthState({ token, setAuthState });
      if (rerouteLocation) {
        let rerouteVal = rerouteLocation.substring(9);
        navigate(`/${rerouteVal}`);
        return;
      }
      if (!selectedFridge) {
        navigate('/upcoming');
        return;
      }
      navigate('/upcoming');
    } catch (e) {
      setLoading(false);
      handleError();
    }
  }
  async function handleRedirectWithSms(email: string, password: string) {
    const { data } = await checkPhone({ variables: { email: email } });
    const secret = 'encodingSecretValue';
    const pw = {
      password: password,
    };
    const jwt = sign(pw, secret);
    try {
      const { data: sendText } = await sendSms({
        variables: {
          input: {
            email: email,
          },
        },
      });
      if (!sendText) {
        handleError();
        return;
      }
    } catch (e) {
      console.log(e);
    }
    navigate('/register-phone-verify', {
      state: {
        phone: data?.phoneNumber.phone,
        email: email,
        password: jwt,
      },
    });
  }

  useEffect(() => {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  }, []);

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img className="mx-auto h-16 w-auto" src={logo} alt="cubbi" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {t('Sign in to your account')}
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              {t('Or')}{' '}
              <div
                onClick={() => navigate('/signup-phone')}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                {t('sign up for free here')}
              </div>
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              handleLogin({ ...values });
              actions.setSubmitting(false);
            }}
          >
            <Form className="mt-8 space-y-6">
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="-space-y-px rounded-md shadow-sm">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    {t('Email address')}
                  </label>
                  <Field
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-4 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder={t('Email address')}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    {t('Password')}
                  </label>
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-4 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder={t('Password')}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {/* <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    {t('Remember me')}
                  </label> */}
                </div>

                <div className="text-sm">
                  <div
                    onClick={() => navigate('/forgot-password')}
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    {t('Forgot your password?')}
                  </div>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                  {loading ? <p>{t('Loading...')}</p> : <p>{t('Sign in')}</p>}
                </button>
              </div>
            </Form>
          </Formik>
          <LanguageControl />
        </div>
      </div>
    </>
  );
}
