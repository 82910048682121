import { useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AlertModal from '../components/alert-modal';
import FridgeMapCard from '../fridges/fridge-map-card';
import { calculateDistance, classNames } from '../helpers';
import TopNavigation from '../navigation/top-navigation';
import {
  FridgeStatePersistentStorageItem,
  FRIDGE_STATE_KEY,
} from '../persistent-storage/fridge-state.persistent-storage';
import PersistentStorage from '../persistent-storage/persistent-storage';
import ErrorPage from '../screens/error';
import LoadingPage from '../screens/loading';
import { useAvailableFridges } from '../services/fridges.service';
import { useMe } from '../services/user.service';
import { Fridge, Subscription } from '../types';
import { toast } from 'react-toastify';
import Notification from '../components/notification';
import { NotificationType } from '../constants';
import AltLoadingPage from '../signup/questionnaire/alt-loading';
import {
  useSubByEmail,
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} from '../services/subscriptions.service';
import { useMutation } from '@apollo/client';
import FridgeSelectSkeleton from '../loading-skeletons/fridge-select-skeleton';
import fridgeImage from '../img/fridge.png';

export type FridgeWithDistance = Fridge & { distance: number };

interface fromQuestionnaire {
  fromQuestionnaire: boolean;
  fromMealPlan: boolean;
  fromEditFridge?: boolean;
}

export default function FridgeSelect() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as fromQuestionnaire;
  const constList = [0, 0, 0, 0, 0, 0, 0, 0];
  const [searchValue, setSearchValue] = useState<string>('');
  const [search, setSearch] = useState(false);

  const [modalToggle, setModalToggle] = useState(false);
  const [selectedFridge, setSelectedFridge] = useState<
    FridgeWithDistance | Fridge
  >();
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDzhkJvncEZgJDwjdiyBtKlcI--uWj3ETA',
  });
  const { data: me, loading } = useMe();
  const {
    data: subscriptions,
    loading: subLoading,
    error: subError,
  } = useSubByEmail(me?.email);
  const [createSubscription] =
    useMutation<{ createSubscription: Subscription }>(CREATE_SUBSCRIPTION);
  const [updateSubscription] =
    useMutation<{ updateSubscription: Subscription }>(UPDATE_SUBSCRIPTION);
  const [dateTime] = useState(new Date().toISOString());
  const [sortedFridges, setSortedFridges] = useState<FridgeWithDistance[]>();
  const {
    fridges,
    loading: fridgeLoading,
    error,
  } = useAvailableFridges(dateTime, me?.email);

  const [userLocation, setUserLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const handleAdded = () =>
    toast(
      <Notification
        type={NotificationType.Success}
        message={`Added ${selectedFridge?.name} for delivery.`}
        title="Added"
      />,
      { position: 'top-right', autoClose: 2000 }
    );

  //Default success notification
  const handleSuccess = () =>
    toast(
      <Notification
        type={NotificationType.Success}
        message={'Added pod to meal plan!'}
        title="Success"
      />,
      { position: 'top-right' }
    );

  //Error updating
  const handleError = () =>
    toast(
      <Notification
        type={NotificationType.Error}
        message={'Error adding pod to meal plan.'}
        title="Error"
      />,
      { position: 'top-right' }
    );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setUserLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);
  //Recall fridge after confirmation
  useEffect(() => {
    let isMounted = true;
    if (confirmed && selectedFridge && isMounted) {
      handleFridgeSelect(selectedFridge);
    }
    return () => {
      isMounted = false;
    };
  }, [confirmed === true]);

  // if (fridgeLoading || loading || !isLoaded) {
  //   if (state && state.fromQuestionnaire) {
  //     return <AltLoadingPage />;
  //   } else {
  //     return <LoadingPage />;
  //   }
  // }

  // if (error || !fridges) {
  //   return (
  //     <ErrorPage
  //       error={error?.graphQLErrors.map(({ message }) => `${message}`)}
  //     />
  //   );
  // }
  const ref = useRef<HTMLInputElement>(null);

  function handleClick() {
    if (ref.current) {
      ref.current.focus();
    }
    setSearchValue('');
  }

  useEffect(() => {
    if (fridges) {
      const sortedFridges: FridgeWithDistance[] = fridges
        .filter((e) => e.app !== 'A_2')
        .map((fridge) => {
          const { location } = fridge;
          const distance = calculateDistance(
            location?.latitude!,
            location?.longitude!,
            userLocation?.lat!,
            userLocation?.lng!
          );
          return {
            ...fridge,
            distance,
          };
        })
        .sort((a, b) => a.distance - b.distance);

      setSortedFridges([...sortedFridges]);
    }
  }, [fridges]);

  async function handleFridgeSelect(fridge: FridgeWithDistance | Fridge) {
    setModalToggle(true);
    setSelectedFridge(fridge);
    if (confirmed) {
      //Condition for from meal plan and have an existing subscription and not updating the existing fridge
      if (state && state.fromMealPlan && subscriptions) {
        setConfirmed(false);
        if (subscriptions[0]) {
          const { data: updateSub, errors: updateSubErrors } =
            await updateSubscription({
              variables: {
                id: subscriptions[0].id,
                input: {
                  fridge: selectedFridge?.id,
                },
                skip: !selectedFridge,
              },
            });
          if (updateSub && !updateSubErrors) {
            handleSuccess();
            PersistentStorage.update(selectedFridgeState, selectedFridge);
            navigate('/subscription-checkout');
          } else {
            handleError();
          }
        }
        //Condition for from meal plan with no existing subscription
        else {
          try {
            const { data: createSub, errors: createSubErrors } =
              await createSubscription({
                variables: {
                  input: {
                    user: me?.email,
                    fridge: selectedFridge?.id,
                  },
                  skip: !selectedFridge,
                },
              });
            if (createSub && !createSubErrors) {
              handleSuccess();
              PersistentStorage.update(selectedFridgeState, selectedFridge);
              navigate('/subscription-checkout');
            }
          } catch (e) {
            handleError();
          }
        }
      }
      //condition for edit button on existing subscription with existing fridge.
      else if (state && state.fromEditFridge && subscriptions) {
        if (subscriptions[0]) {
          const { data: updateSub, errors: updateSubErrors } =
            await updateSubscription({
              variables: {
                id: subscriptions[0].id,
                input: {
                  fridge: selectedFridge?.id,
                },
                skip: !selectedFridge,
              },
            });
          if (updateSub && !updateSubErrors) {
            handleSuccess();
            PersistentStorage.update(selectedFridgeState, selectedFridge);
            navigate('/meal-plan');
          } else {
            handleError();
          }
        }
      } else {
        setConfirmed(false);
        PersistentStorage.update(selectedFridgeState, selectedFridge);
        handleAdded();
        navigate('/date-select');
      }
    }
  }

  function handleCancelSearch() {
    setSearch(false);
    setSearchValue('');
  }

  return (
    <div>
      <TopNavigation title="Pod Select" url="/date-select" />
      <div className="sticky top-16 z-20 bg-white px-4 py-3 shadow-lg">
        <div className="flex">
          <div className="flex-shrink-0"></div>
          <img className="px-4 " src={fridgeImage} alt={'fridge'}></img>
          <div>
            <h4 className=" text-lg font-medium">Select Delivery Location</h4>
            <p className="text-gray-500">
              Select the closest pickup location for this order.
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center py-4">
          <input
            className={
              'h-[42px] w-full rounded-md border px-4 text-gray-500 outline-0'
            }
            placeholder="Search by city or company name"
            onChange={(e) => setSearchValue(e.target.value)}
            type="kitchen"
            value={searchValue}
            autoComplete="kitchen"
            onFocus={() => setSearch(true)}
            ref={ref}
          ></input>
          {searchValue && searchValue.length > 0 && (
            <>
              <div onClick={() => handleClick()}>
                <div className="z-20 -ml-6 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-gray-400 ">
                  <div className="-mt-[2px] text-xs text-white">x</div>
                </div>
              </div>
            </>
          )}
          {search && (
            <div
              className="px-4 text-indigo-500"
              onClick={() => handleCancelSearch()}
            >
              Cancel
            </div>
          )}
        </div>
      </div>
      <div
        role="list"
        className="grid auto-cols-auto grid-cols-1 gap-6 px-6 py-4 shadow-lg sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      >
        {fridges && sortedFridges ? (
          <>
            {sortedFridges
              .filter((e) =>
                search
                  ? e.owner?.name
                      .toLowerCase()
                      .match(searchValue.toLowerCase()) ||
                    e.location?.city
                      .toLowerCase()
                      .match(searchValue.toLowerCase())
                  : true
              )
              .map((fridge, index) => (
                <FridgeMapCard
                  key={index}
                  isLoaded={isLoaded}
                  fridge={fridge}
                  handleFridgeSelect={handleFridgeSelect}
                />
              ))}
          </>
        ) : (
          <>
            {constList?.map((e, i) => {
              return <FridgeSelectSkeleton key={i} />;
            })}
          </>
        )}

        <AlertModal
          open={modalToggle}
          setOpen={setModalToggle}
          title="Confirm Pickup Pod"
          setConfirmed={setConfirmed}
          message={`Are you sure you want to select ${selectedFridge?.name}?`}
        />
      </div>
    </div>
  );
}
