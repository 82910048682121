import { useState } from 'react';
import React from 'react';
import ProgressIndicator from '../../onboarding/progress-indicator';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import AlreadyMemberButton from '../../onboarding/already-member-button';
import { classNames } from '../../helpers';
import TOSText from '../../onboarding/tos-text';
import { PhoneErrorCodes } from '../../constants';
import { toast } from 'react-toastify';
import Notification from '../../components/notification';
import { NotificationType } from '../../constants';
import { useMutation } from '@apollo/client';
import { RegisterResponse } from '../../types';
import { REGISTER } from '../../services/auth.service';
import { GenericResponse } from '../../types';
import { SEND_VERIFY_SMS } from '../../services/user.service';
import { SEND_SMS_LOGIN_TOKEN } from '../../services/auth.service';
import cubbiPng from '../../img/logo.png';
import NoAccountButton from '../../onboarding/no-account-button';
import { SEND_EMAIL_LOGIN_TOKEN } from '../../services/auth.service';
import { formatPhoneNumber } from '../../helpers';
import { valideEmailBoolean, validPhoneBoolean } from '../../helpers';

export default function Login() {
  const [phone, setPhone] = useState<string>();
  const [phoneChanged, setPhoneChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');
  const navigate = useNavigate();

  const [register] = useMutation<{ register: RegisterResponse }>(REGISTER);
  const [sendSms] = useMutation<{
    sendVerifySms: GenericResponse;
  }>(SEND_VERIFY_SMS);
  const [sendSmsLoginToken] =
    useMutation<{ sendSmsLoginToken: GenericResponse }>(SEND_SMS_LOGIN_TOKEN);
  const [sendEmailLoginToken] = useMutation<{
    sendEmailLoginToken: GenericResponse;
  }>(SEND_EMAIL_LOGIN_TOKEN);

  const handleError = (message: string) =>
    toast(
      <Notification
        type={NotificationType.Error}
        message={`${message}`}
        title="Error"
      />,
      { position: 'top-right', autoClose: 5000 }
    );
  async function sendEmailCode() {
    setLoading(true);
    await sendEmailLoginToken({
      variables: {
        input: { email: input.toLowerCase() },
      },
      onError: (e) => {
        if (e.message === 'Customer matching query does not exist.') {
          handleError(
            "This email address isn't registered. Please enter a different email or sign up."
          );
        } else {
          handleError(e.message);
        }
        setLoading(false);
      },
      onCompleted: (_) => {
        navigate('/signup-login-code', {
          state: { email: input.toLowerCase(), isEmail: true },
        });
        setLoading(false);
      },
    });
  }

  async function sendPhoneCode() {
    setLoading(true);
    const phone = formatPhoneNumber(input);
    await sendSmsLoginToken({
      variables: {
        input: { phone },
      },
      onError: (e) => {
        if (e.message === 'Customer matching query does not exist.') {
          handleError(
            "This phone number isn't registered. Please enter a different phone number or sign up."
          );
        } else {
          handleError(e.message);
        }
        setLoading(false);
      },
      onCompleted: (_) => {
        navigate('/signup-login-code', {
          state: { phone: phone, isEmail: false },
        });
        setLoading(false);
      },
    });
  }

  async function handleNext() {
    if (validPhoneBoolean(input)) {
      await sendPhoneCode();
    } else if (valideEmailBoolean(input.toLowerCase())) {
      await sendEmailCode();
    } else {
      handleError('Please enter a valid email or phone number');
    }
  }

  return (
    <div className={`flex h-screen w-screen justify-center bg-background p-4`}>
      <div className="lg:w-3/8">
        <div className="py-4 pb-8">
          <img className="px-6, h-6" src={cubbiPng} alt="cubbi" />
        </div>
        <div className="items-center">
          <div
            className="flex flex-row items-center text-center"
            onClick={() => navigate('/onboarding')}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="text-xl text-gray-500"
            />
            <div className={`pl-4 text-2xl font-bold text-graphite-900`}>
              Log in
            </div>
          </div>
          <div className="w-96 py-4 text-graphite-900">
            Please enter the email address or phone number associated with your
            account.
          </div>
          <div className="flex flex-col rounded-lg bg-white py-4 px-6 pt-4 shadow-lg sm:w-96">
            <div className="py-2">
              <input
                className={
                  'h-[42px] w-full rounded-lg border border-graphite-300 px-4 text-graphite-500 shadow-sm'
                }
                placeholder="Phone number or email address"
                type="name"
                onChange={(e) => setInput(e.target.value)}
              ></input>
              <div className="py-4">
                <button
                  type="submit"
                  disabled={loading}
                  className={classNames(
                    !loading ? 'bg-indigo-600' : 'bg-indigo-600/20',
                    'group relative flex w-full justify-center rounded-md border border-transparent p-3 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  )}
                  onClick={() => handleNext()}
                >
                  <span className="flex items-center text-base"></span>
                  {loading ? 'Loading...' : 'Next'}
                </button>
              </div>
              <NoAccountButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
