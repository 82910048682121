import { useNavigate } from 'react-router-dom';
import { useCloudinaryImage } from '../helpers';
import fridgePlaceholderImage from '../img/fridge.png';
import { Fridge } from '../types';

interface Props {
  fridge: Fridge;
  change?: boolean;
}

export default function ModifiedFridgeItem({ fridge, change }: Props) {
  const navigate = useNavigate();
  const secondLine = fridge.serialNumber ? `#${fridge.serialNumber}` : '';
  const thirdLine = `11:30AM Pickup`;
  const fridgeImage = useCloudinaryImage(fridge?.image);

  return (
    <>
      <div className="flex justify-between p-4">
        <div className="text-gray-500">Selected Pickup Pod</div>
        {change && (
          <div
            className="font-medium text-indigo-500"
            onClick={() => navigate('/fridges')}
          >
            Change
          </div>
        )}
      </div>
      <div className="flex cursor-pointer justify-between border-b bg-white px-6 py-3">
        <div className="flex justify-start text-left">
          <div className="mr-4 flex-shrink-0">
            <img
              src={fridgeImage ?? fridgePlaceholderImage}
              alt="cubbi"
              className="h-20 w-20 rounded-xl object-cover"
            />
          </div>
          <div>
            <h4 className="text-md text-gray-900">{`${fridge.name}`}</h4>
            <p className="mt-1 text-sm text-gray-500">{secondLine}</p>
            <p className="mt-1 text-sm text-gray-500">{thirdLine}</p>
          </div>
        </div>
      </div>
    </>
  );
}
