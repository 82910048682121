import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import jwt_decode from 'jwt-decode';
import { ReactNode } from 'react';
import { REFRESH_TOKEN, TOKEN } from '../constants';
import { REFRESH_TOKEN as REFRESH_MUTATION } from '../services/auth.service';
import { AuthResponse, JWT } from '../types';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const tempClient = new ApolloClient({
    uri: process.env.REACT_APP_API_URL,
    cache: new InMemoryCache(),
  });
  let token = localStorage.getItem(TOKEN);
  if (token) {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    const { exp }: JWT = jwt_decode(token!);
    if (Date.now() >= exp && refreshToken && token) {
      const { data } = await tempClient.mutate<{ refreshToken: AuthResponse }>({
        mutation: REFRESH_MUTATION,
        variables: { refreshToken },
      });
      if (!data || !data.refreshToken) {
        return;
      }
      token = data.refreshToken.token;
      localStorage.setItem(TOKEN, token);
      localStorage.setItem(REFRESH_TOKEN, data.refreshToken.refreshToken);
    }
  }
  return {
    headers: {
      ...headers,
      authorization: `JWT ${token}` ?? undefined,
    },
  };
});

interface Props {
  children: ReactNode;
}

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default function GraphQLProvider({ children }: Props) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
