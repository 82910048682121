import { gql, useQuery } from '@apollo/client';
import { User } from '../types';
import { getNodeDataFromEdges } from '../helpers';

export const ME = gql`
  query me {
    me {
      pk
      id
      firstName
      lastName
      email
      phone
      archived
      verified
      reminderTime
      reminderCounter
      onboardingComplete
      prestockPrompt
      firstTimeUser
      defaultSelectedCard {
        id
        paymentMethodId
        last4
        expYear
        expMonth
      }
      orgUsers {
        id
        name
        logo
      }
      creditcarddetailsSet {
        edges {
          node {
            paymentMethodId
            last4
            expYear
            expMonth
            id
          }
        }
      }
    }
  }
`;
export const CHECK_EMAIL = gql`
  mutation checkEmail($input: VerifySMSInput!) {
    checkEmail(input: $input) {
      ok
      success
    }
  }
`;
export const CHANGE_USER = gql`
  mutation updateUser($id: String!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      ok
    }
  }
`;

export const JOIN_ORG = gql`
  mutation joinOrganization($input: JoinOrganizationInput!) {
    joinOrganization(input: $input) {
      ok
    }
  }
`;
export const SEND_VERIFY_SMS = gql`
  mutation sendVerifySms($input: SendVerifySMSInput!) {
    sendVerifySms(input: $input) {
      ok
      success
    }
  }
`;

export const VERIFY_SMS = gql`
  mutation verifySms($input: VerifySMSInput!) {
    verifySms(input: $input) {
      ok
      success
    }
  }
`;

export function useMe() {
  const { data, loading, error, refetch } = useQuery<{ me: User }>(ME);
  const me: User | undefined = data?.me;
  const cc =
    me?.creditcarddetailsSet.edges &&
    getNodeDataFromEdges(me.creditcarddetailsSet.edges);
  return { data: me, cc, loading, error, refetch };
}

export const CREATE_CREDIT_CARD = gql`
  mutation createCreditcarddetails($input: CreditCardDetailsInput!) {
    createCreditcarddetails(input: $input) {
      creditcarddetails {
        paymentMethodId
        last4
        expYear
        expMonth
        id
      }
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($password: String!) {
    deleteAccount(password: $password) {
      success
      errors
    }
  }
`;
