import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '../helpers';
import logo from '../img/logo.png';
import passwordSuccess from '../img/lotties/password-success.json';
export default function PasswordResetConfirmation() {
  const navigate = useNavigate();

  const { height } = useWindowDimensions();

  return (
    <>
      <div className="flex min-h-full flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <img
          className="object-fit mx-auto h-[84px] w-[84px] object-scale-down"
          src={logo}
          alt="Workflow"
        />
        <Lottie
          style={{ height: height / 2.75 }}
          loop
          animationData={passwordSuccess}
        />
        <div className="text-semibold text-3xl text-gray-900">Success</div>
        <div className="pt-5 text-center text-xl text-gray-500">
          Your new password has been successfully saved.
        </div>
        <div className="flex w-full pt-6">
          <button
            type="submit"
            className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => navigate('/login')}
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
            Login
          </button>
        </div>
      </div>
    </>
  );
}
