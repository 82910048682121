import CarouselCardSkeleton from './carousel-card-skeleton';
import FeaturedCardSkeleton from './featured-card-skeleton';
import MealCardSkeleton from './meal-card-skeleton';

export default function MainContentSkeleton() {
  return (
    <div className="flex animate-pulse flex-col">
      <div className="h-7 rounded p-4 text-lg">Featured</div>
      <CarouselCardSkeleton />
      <FeaturedCardSkeleton />
      <div className="relative my-4 items-center">
        <div className="absolute inset-0 ml-20 flex items-center">
          <div className="w-full border-t border-gray-300"></div>
        </div>
        <span className="px-4 pr-3 text-lg font-medium text-gray-900">
          Meals
        </span>
      </div>
      <MealCardSkeleton />
    </div>
  );
}
