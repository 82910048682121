import React from 'react';

const DEEPLINK_URL = 'cubbi://GroupOrders';
const WEB_URL = 'https://www.cub.bi/download-app';

class RedirectToApp extends React.Component {
  componentDidMount() {
    this.attemptDeepLink();
  }

  attemptDeepLink() {
    // This flag will be used to determine if the page visibility changed
    let visibilityChanged = false;

    const onVisibilityChange = () => {
      visibilityChanged = true;
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    // Attempt to open the deep link
    window.location.href = DEEPLINK_URL;

    setTimeout(() => {
      document.removeEventListener('visibilitychange', onVisibilityChange);

      // If visibility didn't change, it means the deep link didn't work
      if (!visibilityChanged) {
        window.location.href = WEB_URL;
      }
    }, 500); // Timeout for app to respond to deeplink
  }

  render() {
    return null; // Render nothing as this component only serves to redirect
  }
}

export default RedirectToApp;
