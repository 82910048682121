import logo from '../img/logo-black-square.png';
import { useNavigate } from 'react-router-dom';

export default function EmailVerification() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex min-h-full flex-col bg-white pt-16 pb-12 align-middle">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div className="flex flex-shrink-0 justify-center">
            <div onClick={() => navigate('/')} className="inline-flex">
              <img className="h-16 w-16" src={logo} alt="Cubbi" />
            </div>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-black-600 text-sm font-semibold uppercase tracking-wide">
                Thanks for signing up!
              </p>
              <p className="mt-2 text-base text-gray-500">
                A verification email has been sent to your account. Please check
                your inbox <br /> for further instructions.
              </p>
              <div className="mt-6 space-x-4">
                <div
                  onClick={() => navigate('/')}
                  className="text-base font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Go back<span aria-hidden="true"></span>
                </div>
                <div
                  onClick={() => navigate('/')}
                  className="text-base font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Contact support<span aria-hidden="true"></span>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
