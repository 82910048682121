export default function UpsellSkeleton() {
  const items = [0, 0, 0, 0];
  return (
    <div className="px-2 py-2">
      <div className="mb-6 w-full px-4 py-6 shadow">
        <div className="text-md flex w-full justify-start py-2 font-medium">
          Complete your order with a drink or a snack!
        </div>
        <div className="flex animate-pulse">
          {items.map((e, i) => (
            <div className="pr-1" key={i}>
              <div className="max-w-[98px]flex max-h-[164px] min-h-[164px] min-w-[98px] flex-col items-center justify-center rounded-md border border-gray-200 bg-white py-2 shadow-lg">
                <div className="flex items-center justify-center">
                  <div className="h-16 w-16 bg-slate-400"></div>
                </div>
                <div className="px-4 pt-4">
                  <div className="h-2  bg-slate-400"></div>
                </div>
                <div className="px-4 py-2">
                  <div className="h-2 bg-slate-400"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// <div>
//   <div className="flex w-full flex-col p-2">
//     <div className="text-md flex w-full justify-start py-2 font-medium">
//       Complete your order with a drink or a snack!
//     </div>
//     <div className="flex justify-center">
//       <div className="flex w-[340px] flex-row overflow-x-scroll pb-4 sm:w-full">
//         {inCart &&
//           inCart
//             .filter((a) => a.active)
//             .map((e, i) => {
//               return (
//                 <UpsellProduct
//                   key={i}
//                   incart={e}
//                   dates={dates}
//                   addItem={addItem}
//                 />
//               );
//             })}
//       </div>
//     </div>
//   </div>
// </div>;

//   <div className="pr-1" onClick={() => setOpen(true)}>
//     <div className="flex min-h-[164px] min-w-[98px] max-w-[98px] flex-col rounded-md border bg-white py-2 shadow-lg">
//       <div className="flex items-center justify-center">
//         <img
//           src={useCloudinaryImage(incart.product.image)}
//           alt={incart.product.name}
//           className="h-16 w-16"
//         ></img>
//       </div>
//       <div className="flex flex-col pt-2 text-center">
//         <div className="overflow-hidden truncate px-2 text-sm">
//           {incart.product.name}
//         </div>
//         {evenPrice ? (
//           <div className="text-sm">${incart.product.price}</div>
//         ) : (
//           <>
//             <div className="text-sm line-through">
//               ${incart.product.price}
//             </div>
//             <div className="text-sm">${incart.price}</div>
//           </>
//         )}
//       </div>
//     </div>
//     <div className="-mt-10 flex items-end justify-end px-2">
//       <button
//         type="button"
//         onClick={() => setOpen(true)}
//         className="h-8 w-8 items-center justify-center rounded-full border border-gray-200 bg-white shadow-sm focus:outline-none"
//       >
//         <FontAwesomeIcon
//           icon={faPlus}
//           aria-hidden="true"
//           size="sm"
//           className="font-thin text-gray-500"
//         />
//       </button>
//     </div>
//   </div>;
