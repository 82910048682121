import { Fridge } from '../types';
import { PersistentStorageItem } from './persistent-storage';

export const FRIDGE_STATE_KEY = 'fridge-state';

export class FridgeStatePersistentStorageItem extends PersistentStorageItem<
  string,
  Fridge
> {
  public key: string;
  public defaultValue?: Fridge = undefined;

  constructor(key: string) {
    super();
    this.key = key;
  }

  public encode(item: Fridge) {
    return JSON.stringify(item);
  }

  public decode(encodedFridgeState: string) {
    return JSON.parse(encodedFridgeState) as Fridge;
  }
}
