import { Fridge } from '../types';
import { useCloudinaryImage } from '../helpers';
import { faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface Props {
  fridge: Fridge;
  secondLine: string;
  thirdLine: string;
}

export default function FridgeMapMoreDetails({
  fridge,
  secondLine,
  thirdLine,
}: Props) {
  const { owner } = fridge;
  const logo = useCloudinaryImage(owner?.logo);
  const fridgeCount = owner?.fridges?.edges?.length;

  return (
    <div className="flex flex-col px-4">
      <div className="mt-4 flex flex-row items-start text-gray-400">
        <div className="flex items-start">
          <FontAwesomeIcon className="fa-fw" icon={faMapMarkerAlt} />
        </div>
        <p className="px-2 text-sm text-gray-500">
          {secondLine}, {fridge.location?.city},{' '}
          {fridge?.location?.state === 'Saskatchewan' ? 'SK' : ''}
          {fridge?.location?.state === 'Alberta' ? 'AB' : ''}{' '}
          {fridge?.location?.zip}
        </p>
      </div>
      <div className="start flex flex-row text-gray-400">
        <p className="mt-[6px] px-[29px] text-sm text-gray-500">{thirdLine}</p>
      </div>
      <div className="flex flex-row items-start py-4 text-gray-400">
        <div className="min-h-4 min-w-4 max-w-4 flex max-h-4 items-start">
          <FontAwesomeIcon className="fa-fw" icon={faClock} />
        </div>
        <p className="px-2 text-sm text-gray-500">Pickup 11:30AM, Mon-Fri</p>
      </div>
      <div className="mt-1 flex flex-row text-gray-900">
        {owner?.logo && (
          <img
            className="object-cotain mr-3 h-10 w-10 rounded-md object-contain text-sm"
            src={logo}
            alt={owner?.name}
          />
        )}
        <div className="flex flex-col">
          <p className="text-medium pr-2 text-sm font-medium text-black">
            {owner?.name}
          </p>
          <p className="pr-2 text-sm text-gray-500">
            {'Provider of this pod'}{' '}
            {fridgeCount && fridgeCount > 0 && (
              <>
                {fridgeCount && fridgeCount > 1
                  ? `+${fridgeCount} others`
                  : `1 other`}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
