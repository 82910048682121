import React, { useState, useRef, useEffect } from 'react';
import FeedbackHeader from './feedback-header';
import ReactStars from 'react-stars';
import { classNames } from '../helpers';
import { useMutation, useQuery } from '@apollo/client';
import { BookingItem, GetReviewProduct, Product } from '../types';
import { GET_REVIEW_PRODUCT } from '../services/products.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FeedbackProductImage from './feedback-product-image';
import LoadingPage from '../screens/loading';
import ErrorPage from '../screens/error';

interface fromUpcoming {
  rating: number;
}

export default function FeedbackMeal() {
  const rating = useRef(0);
  const location = useLocation();
  const state = location.state as fromUpcoming;
  const navigate = useNavigate();
  const { bookingId } = useParams();
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [currRating, setCurrRating] = useState(0);
  const { data, loading, error } = useQuery<{
    getreviewproduct: GetReviewProduct;
  }>(GET_REVIEW_PRODUCT, { variables: { booking: bookingId } });
  const [selected, setSelected] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const ratingChanged = (newRating: number) => {
    setCurrRating(newRating);
  };

  const feedbackTags = [
    'Small portion',
    'Large Portion',
    'Burnt',
    'Too spicy',
    'Too mild',
    'Lacks flavour',
  ];

  function handleSelected(index: number) {
    let currVals = selected;
    currVals[index] = !currVals[index];
    setSelected([...currVals]);
  }

  function handleRedirect() {
    let list: string[] = [];
    if (selected.includes(true)) {
      for (let i = 0; i < selected.length; i++) {
        if (selected[i]) {
          list = [...list, feedbackTags[i]];
        }
      }
    }
    navigate(`/rating/delivery/${bookingId}`, {
      state: {
        productId: data?.getreviewproduct.id,
        productRatingText: additionalInfo,
        productRating: currRating,
        productTags: list,
      },
    });
  }

  useEffect(() => {
    if (state && state.rating) {
      ratingChanged(state.rating);
    }
  }, [state]);

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <>
      <FeedbackHeader pageNumber={1} bookingId={bookingId} />
      <div className="flex flex-col px-4">
        <div className="flex flex-col">
          <div className="flex flex-col justify-start">
            <div className="py-4 text-3xl font-semibold">
              How was your meal?
            </div>
            <div className="text-sm text-gray-500">
              Your feedback helps improve the quality of food we offer you!
            </div>
          </div>
          {data?.getreviewproduct.product && (
            <>
              <FeedbackProductImage
                image={data.getreviewproduct.product.image}
                name={data.getreviewproduct.product.name}
              />
              <div className="flex justify-center text-lg font-semibold text-gray-900">
                {data.getreviewproduct.product.name}
              </div>
            </>
          )}
          <div className="flex flex-row items-center justify-center">
            <ReactStars
              count={5}
              onChange={ratingChanged}
              value={currRating}
              size={36}
              half={true}
              color2={'#ffd700'}
            />
          </div>
        </div>

        {currRating > 0 && (
          <>
            {currRating !== 5 && (
              <div className="flex flex-wrap whitespace-pre py-2">
                {feedbackTags.map((e, i) => {
                  return (
                    <div className="py-1 pr-2" key={i}>
                      <div
                        className={classNames(
                          selected[i]
                            ? 'rounded-md bg-cubbiIndigo px-3 text-white'
                            : 'rounded-md bg-gray-100 px-3 text-gray-800'
                        )}
                        onClick={() => handleSelected(i)}
                      >
                        {e}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <textarea
              className="h-40 rounded-md border border-gray-300 px-4"
              onChange={(e) => setAdditionalInfo(e.target.value)}
              placeholder="Care to share more? (optional)"
            ></textarea>
            <div className="py-4">
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => handleRedirect()}
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                {'Continue'}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
