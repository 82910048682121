import { gql, useQuery } from '@apollo/client';
import { getNodeDataFromEdges } from '../helpers';
import {
  Booking,
  QueryResult,
  OrderById,
  ProductWithAddons,
  UnavailableFridgeReschedule,
} from '../types';

export const BOOKINGS = gql`
  query bookings($owner_Email: String!) {
    bookings(owner_Email: $owner_Email, last: 100) {
      edges {
        node {
          id
          orderId
          reviewPopup
          owner {
            firstName
          }
          payment {
            subTotal
            shippingTotal
            taxTotal
          }
          cubbi {
            id
            position
            fridge {
              allowWeekendAccess
              image
              app
              owner {
                name
              }
              id
              name
              serialNumber
              location {
                building
                floor
                room
                zip
                additionalDirections
                additionalDirectionsText
                latitude
                longitude
                description
                building
                street
                city
              }
            }
          }
          cancelledAt
          deliveredAt
          prestockBookings {
            edges {
              node {
                payee {
                  firstName
                  lastName
                }
              }
            }
          }
          createdAt
          startAt
          endAt
          orderSet {
            edges {
              node {
                refundId
              }
            }
          }
          bookingItems {
            name
            quantity
            image
            incartAddition {
              price
            }
            incartPopup {
              price
            }
          }
          items {
            edges {
              node {
                name
                price
                image
                description
              }
            }
          }
        }
      }
    }
  }
`;

export function useBookings(email?: string) {
  const { data, loading, error, refetch } = useQuery<{
    bookings: QueryResult<Booking>;
  }>(BOOKINGS, {
    variables: {
      owner_Email: email,
    },
    skip: email === undefined,
    fetchPolicy: 'network-only',
  });
  const bookings =
    data?.bookings.edges && getNodeDataFromEdges(data?.bookings.edges);
  return { bookings, loading, error, refetch };
}

export const BOOKING_BY_PAYMENT_INTENT = gql`
  query bookings($payment_PaymentIntentId: String!) {
    bookings(payment_PaymentIntentId: $payment_PaymentIntentId) {
      edges {
        node {
          id
          startAt
          deliveredAt
          firstBooking
          reviewPopup
          cubbi {
            fridge {
              image
              name
              id
              serialNumber
              location {
                floor
                room
                zip
                additionalDirections
                additionalDirectionsText
                latitude
                longitude
                street
                city
                description
                building
              }
              owner {
                name
                logo
              }
            }
          }
          payment {
            creditCard {
              expMonth
              expYear
              last4
            }
            payee {
              firstName
              lastName
            }
            subTotal
            taxTotal
            shippingTotal
            multiDayDiscount
            incartAdditionsDiscount
            discountTotal
            multiDayDiscount
            incartAdditionsDiscount
          }
          bookingItems {
            name
            description
            price
            quantity
            image
            description
            incartAddition {
              price
            }
            incartPopup {
              price
            }
          }
          items {
            edges {
              node {
                id
                name
                description
                price
                cost
              }
            }
          }
        }
      }
    }
  }
`;

export function useOrderByPaymentIntent(paymentIntentId?: string) {
  const { data, loading, error, refetch } = useQuery<{
    bookings: QueryResult<Booking>;
  }>(BOOKING_BY_PAYMENT_INTENT, {
    variables: {
      payment_PaymentIntentId: paymentIntentId,
    },
    skip: paymentIntentId === undefined,
  });
  const bookings = data && getNodeDataFromEdges(data?.bookings.edges);
  return { bookings, loading, error, refetch };
}

export const ORDER_BY_ID = gql`
  query order($id: Int) {
    order(id: $id) {
      id
      refundId
      updatedAt
      payment {
        subTotal
        discountTotal
        shippingTotal
        taxTotal
        multiDayDiscount
        incartAdditionsDiscount
        creditCard {
          last4
          expMonth
          expYear
          paymentSet {
            edges {
              node {
                payee {
                  firstName
                  lastName
                }
                subTotal
                discountTotal
                shippingTotal
                taxTotal
              }
            }
          }
        }
      }
      bookings {
        edges {
          node {
            id
            startAt
            createdAt
            cancelledAt
            reviewPopup
            cubbi {
              fridge {
                name
                image
                serialNumber
                owner {
                  name
                }
                location {
                  floor
                  room
                  building
                  zip
                  description
                  street
                  city
                  additionalDirections
                  additionalDirectionsText
                }
              }
            }
            bookingItems {
              name
              quantity
              image
              price
              incartAddition {
                price
              }
              incartPopup {
                price
              }
              addons
              productId
            }
          }
        }
      }
    }
  }
`;

export function useOrderById(id?: number) {
  const { data, loading, error, refetch } = useQuery<{
    order: OrderById;
  }>(ORDER_BY_ID, {
    variables: {
      id: id,
    },
    skip: id === undefined,
    fetchPolicy: 'network-only',
  });
  const bookings =
    data?.order && getNodeDataFromEdges(data?.order.bookings.edges);
  return { order: data?.order, bookings, loading, error, refetch };
}

export const REFUND_BY_ID = gql`
  mutation refundPayment($input: RefundPaymentInput!) {
    refundPayment(input: $input) {
      ok
    }
  }
`;

//empty items[] input reschedules the entire booking.
export const RESCHEDULE_BOOKING = gql`
  mutation rescheduleBooking($input: RescheduleBookingInput!) {
    rescheduleBooking(input: $input) {
      booking {
        startAt
        endAt
      }
    }
  }
`;

export const UNAVAILABLE_RESCHEDULE_DAY = gql`
  query unavailableFridgeDaysReschedule(
    $month: Int
    $user: String
    $fridgeList: [Int]
    $year: Int
    $products: [Int]
    $booking: Int
  ) {
    unavailableFridgeDaysReschedule(
      month: $month
      user: $user
      fridgeList: $fridgeList
      year: $year
      products: $products
      booking: $booking
    ) {
      day
      month
      year
      available
      spaceAvailable
      productAvailable
    }
  }
`;

export const UNAVAILABLE_RESCHEDULE_ALL = gql`
  query unavailableFridgeDaysRescheduleList(
    $month: Int
    $user: String
    $fridgeList: [Int]
    $year: Int
    $bookings: [Int]
  ) {
    unavailableFridgeDaysRescheduleList(
      month: $month
      user: $user
      fridgeList: $fridgeList
      year: $year
      bookings: $bookings
    ) {
      day
      month
      year
      available
      spaceAvailable
      productAvailable
    }
  }
`;

export const BOOKING_BY_ID = gql`
  query booking($id: Int) {
    booking(id: $id) {
      startAt
      endAt
      reviewPopup
      cancelledAt
      bookingItems {
        name
        quantity
        price
        image
        description
        productId
        addons
      }
      owner {
        email
      }
      cubbi {
        fridge {
          id
        }
      }
      items {
        edges {
          node {
            id
            name
            description
            quantity
            image
            addons {
              selection
              name
              description
              id
            }
          }
        }
      }
    }
  }
`;

export function useBookingById(id?: number) {
  const { data, loading, error, refetch } = useQuery<{
    booking: Booking;
  }>(BOOKING_BY_ID, {
    variables: {
      id: id,
    },
    skip: id === undefined,
  });
  const bookingItems =
    data?.booking && getNodeDataFromEdges(data?.booking.items.edges);
  return { data, items: bookingItems, loading, error, refetch };
}

export function useUnavailableRescheduleDays(
  month: number,
  user: string,
  fridgeList: number[] | null,
  year: number,
  products: ProductWithAddons[],
  booking: number
) {
  const { data, loading, error, refetch } = useQuery<{
    unavailableFridgeDaysReschedule: UnavailableFridgeReschedule[];
  }>(UNAVAILABLE_RESCHEDULE_DAY, {
    variables: {
      month,
      user,
      fridgeList,
      year,
      products: products.map((e) => e.product),
      booking,
    },
    skip: booking === undefined || fridgeList === null,
  });
  return { data, loading, error, refetch };
}

export function useUnavailableRescheduleAll(
  month: number,
  user: string,
  fridgeList: number[] | null,
  year: number,
  bookings: number[]
) {
  const { data, loading, error, refetch } = useQuery<{
    unavailableFridgeDaysRescheduleList: UnavailableFridgeReschedule[];
  }>(UNAVAILABLE_RESCHEDULE_ALL, {
    variables: {
      month,
      user,
      fridgeList,
      year,
      bookings,
    },
    skip: bookings === undefined || fridgeList === null,
  });
  return { data, loading, error, refetch };
}

export const UPDATE_BOOKING = gql`
  mutation updateBooking($id: Int!, $input: BookingInput!) {
    updateBooking(id: $id, input: $input) {
      booking {
        reviewPopup
      }
    }
  }
`;
