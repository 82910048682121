import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Moment } from 'moment';
import { classNames, useCloudinaryImage } from '../helpers';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';
import { Product, ProductObj } from '../types';

interface Props {
  product: Product;
  categoryName: string;
  currentDate: Moment;
  addItemToOrder: (product: ProductObj) => unknown;
  handleOpenProductModal: (product: Product) => unknown;
  orderItems: OrderState[];
}

export default function GridProductCard({
  product,
  categoryName,
  addItemToOrder,
  handleOpenProductModal,
  currentDate,
  orderItems,
}: Props) {
  const {
    name,
    image,
    price,
    description,
    quantity,
    totalSize,
    vendor,
    availableEndDate,
    shelfLife,
    arrivalDate,
    nextArrivalDate,
    nextQuantity,
    sameDayOrders,
    prepTime,
    discountPrice,
  } = product;
  const productImage = useCloudinaryImage(image);
  const fullWidth = categoryName === 'Meals';
  let greyOut = false;
  let soldOut = false;

  const productOrderedQuantity = orderItems.filter(
    (e) => e.product.product.id === product.id
  );

  function handleAvailable() {
    soldOut = false;
    greyOut = false;
  }

  function handleUnavailable() {
    soldOut = true;
    greyOut = true;
  }

  const shelfLifeNumber = shelfLife?.substring(0, 1);
  const timeNow = moment();

  const nextArrivalFirst =
    nextArrivalDate !== undefined &&
    arrivalDate !== undefined &&
    moment(nextArrivalDate).isBefore(arrivalDate);

  const nextArrival =
    nextArrivalDate !== null && currentDate.isBefore(moment(nextArrivalDate));
  const productArrival =
    arrivalDate !== null && currentDate.isBefore(moment(arrivalDate));
  const infiniteQuantity =
    arrivalDate !== null &&
    shelfLife !== 'None' &&
    currentDate.isAfter(moment(arrivalDate).add(shelfLifeNumber, 'days'));
  const nextArrivalInfinite =
    nextArrivalDate !== null &&
    shelfLife !== 'None' &&
    currentDate.isAfter(moment(nextArrivalDate).add(shelfLifeNumber, 'days'));
  const nextArrivalFinite =
    nextArrivalDate !== null &&
    nextQuantity !== null &&
    currentDate.isAfter(moment(nextArrivalDate)) &&
    currentDate.isBefore(moment(nextArrivalDate).add(shelfLifeNumber, 'days'));
  const finiteQuantity =
    arrivalDate !== null &&
    currentDate.isAfter(moment(arrivalDate)) &&
    !infiniteQuantity;
  const quantityCheck =
    quantity !== null &&
    quantity >= 0 &&
    productOrderedQuantity.length >= quantity;

  const nextArrivalQuantityCheck =
    nextQuantity !== null &&
    product.nextQuantity >= 0 &&
    productOrderedQuantity.length >= product.nextQuantity;

  const today = moment();

  const currentDateAt9 = moment(currentDate).set({
    hour: 9,
    minute: 0,
    second: 0,
  });

  const itemFromToday = orderItems.filter(
    (e) =>
      e.product.product.id === product.id &&
      moment(e.date).day() === currentDate.day() &&
      moment(e.date).month() === currentDate.month()
  );

  const nextDay = moment().add(1, 'day');

  const currentDateCutoff = currentDateAt9.subtract(prepTime, 'hours');
  if (
    sameDayOrders &&
    (timeNow.isAfter(currentDateCutoff) ||
      currentDate.date() === today.date()) &&
    (quantity === 0 || !quantity) &&
    (nextQuantity === 0 || !nextQuantity)
  ) {
    handleUnavailable();
  } else if (sameDayOrders && timeNow.isBefore(currentDateCutoff)) {
    handleAvailable();
  } else {
    if (!nextArrivalFirst) {
      if (productArrival) {
        handleUnavailable();
      } else if (infiniteQuantity) {
        if (
          nextArrivalDate !== null &&
          currentDate.isBefore(moment(nextArrivalDate))
        ) {
          handleUnavailable();
        } else if (nextArrivalInfinite) {
          handleAvailable();
        } else if (nextArrivalFinite) {
          if (nextArrivalQuantityCheck) {
            handleUnavailable();
          } else {
            handleAvailable();
          }
        } else {
          handleAvailable();
        }
      } else if (finiteQuantity) {
        if (quantityCheck) {
          greyOut = true;
        } else {
          handleAvailable();
        }
      } else {
        if (quantityCheck) {
          handleUnavailable();
        } else {
          handleAvailable();
        }
      }
    } else {
      if (nextArrival) {
        handleUnavailable();
      } else if (nextArrivalInfinite) {
        if (productArrival) {
          handleUnavailable();
        } else if (infiniteQuantity) {
          handleAvailable();
        } else if (finiteQuantity) {
          if (quantityCheck) {
            handleUnavailable();
          } else {
            handleAvailable();
          }
        } else {
          handleAvailable();
        }
      } else if (nextArrivalFinite) {
        if (nextArrivalQuantityCheck) {
          greyOut = true;
        } else {
          handleAvailable();
        }
      } else {
        if (nextArrivalQuantityCheck) {
          handleUnavailable();
        } else {
          handleAvailable();
        }
      }
    }
  }

  return (
    <div className="relative overflow-hidden rounded-xl bg-white shadow-md hover:shadow-xl">
      <div className="pb-6" onClick={() => handleOpenProductModal(product)}>
        <img
          src={productImage}
          alt={name}
          className={classNames(
            'pointer-events-none mx-auto mb-3 h-32 object-cover group-hover:opacity-75',
            greyOut ? 'opacity-40' : undefined,
            fullWidth ? 'rounded-t-xvl w-full' : 'mt-4 w-32 rounded-xl'
          )}
        />
        {sameDayOrders &&
        currentDate.date() === nextDay.date() &&
        (today.hour() < currentDateCutoff.hour() ||
          (today.hour() === currentDateCutoff.hour() &&
            today.minutes() < currentDateCutoff.minutes())) ? (
          <>
            <div className="-mt-32 px-4">
              <div className="relative w-fit rounded-md bg-white px-4 py-1 text-xs">
                Order by {currentDateAt9.format('h:mm A')}
              </div>
            </div>
            <div className="mt-[104px]"></div>
          </>
        ) : (
          <>
            {sameDayOrders &&
              currentDate.date() === nextDay.date() &&
              ((!nextArrivalFirst && quantityCheck && !infiniteQuantity) ||
                (nextArrivalFirst &&
                  nextArrivalQuantityCheck &&
                  !nextArrivalInfinite)) && (
                <>
                  <div className="-mt-32 px-4">
                    <div className="relative w-fit rounded-md bg-white px-4 py-1 text-xs">
                      Order by {currentDateAt9.format('h:mm A')}
                    </div>
                  </div>
                  <div className="mt-[104px]"></div>
                </>
              )}
          </>
        )}

        {sameDayOrders &&
          (timeNow.isAfter(currentDateCutoff) ||
            currentDate.date() === today.date()) &&
          (quantity === 0 || !quantity) &&
          (nextQuantity === 0 || !nextQuantity) && (
            <>
              <div className="-mt-32 px-4">
                <div className="relative w-fit rounded-md bg-white px-4 py-1 text-xs">
                  Order by {currentDateAt9.format('h:mm A')}
                </div>
              </div>
              <div className="mt-[104px]"></div>
            </>
          )}
        <div className="px-4 text-xs font-bold text-gray-500">
          {vendor.toUpperCase()}
        </div>
        <div
          className={classNames(
            greyOut ? 'opacity-40' : undefined,
            'px-4 py-1 font-bold text-gray-900'
          )}
        >
          {name}
        </div>
        <div
          className={classNames(
            greyOut ? 'w-full px-4 opacity-40' : undefined,
            'w-full truncate px-4 text-gray-400'
          )}
        >
          {name === 'Meals' ? description : totalSize ?? description}
        </div>
        <div>
          {/* {soldOut && (
            <span className="my-1 ml-3 inline-flex justify-center rounded-xl bg-red-100 px-3 py-1 text-xs font-medium leading-4 text-red-800">
              Sold out{' '}
            </span>
          )} */}
          {/* {(!isAvailableDay ||
            (todayLogic && !product.sameDayCutoff) ||
            (tomorrowLogic && !product.sameDayCutoff)) && (
            <span className="my-1 ml-2 inline-flex justify-center rounded-xl bg-red-100 px-3 py-1 text-xs font-medium leading-4 text-red-800">
              Invalid Delivery Day
            </span>
          )} */}
          {/* {almostSoldOut && quantity !== null && (
            <span className="my-1 ml-3 inline-flex justify-center rounded-xl bg-yellow-100 px-3 py-1 text-xs font-medium leading-4 text-yellow-800">
              {`Only ${quantity} left!`}
            </span>
          )} */}
        </div>
      </div>
      <div className="mt-6 mb-2 flex w-4 items-center justify-between px-4">
        <div className="mt-4"></div>
        <div className="text-md absolute bottom-3 flex flex-col font-bold text-gray-900">
          {discountPrice ? (
            <div
              className={classNames(
                greyOut
                  ? 'font-medium text-gray-400 line-through opacity-40'
                  : 'font-medium text-gray-400 line-through '
              )}
            >{`$${price}`}</div>
          ) : (
            <div
              className={classNames(greyOut ? 'text-lg opacity-40' : 'text-lg')}
            >{`$${price}`}</div>
          )}
          {discountPrice && (
            <>
              <div className={classNames(greyOut ? 'opacity-40' : undefined)}>
                {`$${discountPrice}`}
              </div>
            </>
          )}
        </div>
        <div className="mr-4" />
        {!greyOut && (
          <button
            type="button"
            onClick={
              product.addons.length === 0
                ? () => addItemToOrder({ product, addons: [] })
                : () => handleOpenProductModal(product)
            }
            disabled={soldOut || greyOut}
            className={classNames(
              itemFromToday.length > 0
                ? 'absolute bottom-2 right-2 inline-flex items-center rounded-full border border-gray-200 bg-black p-2.5 text-white shadow-sm focus:outline-none'
                : 'absolute bottom-2 right-2 inline-flex items-center rounded-full border border-gray-200 bg-white p-2.5 shadow-sm focus:outline-none'
            )}
          >
            {itemFromToday.length > 0 ? (
              <div className="w-4 text-xs font-bold">
                {itemFromToday.length}
              </div>
            ) : (
              <FontAwesomeIcon
                icon={faPlus}
                aria-hidden="true"
                size="sm"
                className="font-thin text-gray-500"
              />
            )}
          </button>
        )}
      </div>
    </div>
  );
}
