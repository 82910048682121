import {
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment';
import { useNavigate } from 'react-router-dom';
import { DateStream } from '../helpers';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';
import { useState } from 'react';
import { classNames } from '../helpers';
interface Props {
  setDateStream: (dateStream: DateStream) => void;
  dateStream: DateStream;
  previous?: Moment;
  current: Moment;
  next?: Moment;
  orderItems: OrderState[];
  isFullVals:
    | Array<{
        day: number;
        month: number;
        available: boolean;
        spaceAvailable: number;
      }>
    | undefined;
  firstTimeUser: boolean;
}

export default function DeliveryDateSelect({
  setDateStream,
  previous,
  current,
  next,
  dateStream,
  orderItems,
  isFullVals,
  firstTimeUser,
}: Props) {
  const currentDayItems = orderItems.filter(
    ({ date }) => moment(date).format('L') === moment(current).format('L')
  );
  // const currentDaySubtotal = currentDayItems.reduce(
  //   (sum, { product: { price } }) => sum + +price,
  //   0
  // );

  const [index, setIndex] = useState(0);

  const navigate = useNavigate();

  function handleAddDate() {
    navigate('/order-summary');
  }

  return (
    <div className="mb-0.5 flex h-20 items-center justify-around bg-white bg-zinc-50 object-center px-4 shadow-md">
      {previous && (
        <div className="pr-2">
          <button
            className="h-[50px] w-[56px] rounded-md border border-gray-300 bg-white md:w-24"
            onClick={() => {
              setDateStream(dateStream.previous().clone());
              setIndex(index - 1);
            }}
          >
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="text-xs text-gray-500"
              />
              <div className="flex flex-col px-[7px]">
                <div className="text-xs text-gray-500">
                  {moment(previous).format('MMM')}{' '}
                </div>
                <div className="text-sm leading-[16px] text-gray-500">
                  {previous.date()}
                </div>
              </div>
            </div>
          </button>
        </div>
      )}
      <div
        className="flex h-[58px] w-1/2 flex-grow rounded-md border border-gray-300 px-2"
        onClick={() => navigate('/order-summary')}
      >
        <div className="flex items-center">
          <div className="flex h-10 w-10 items-center justify-center rounded-md bg-indigo-100 text-center">
            <div className="flex flex-col justify-center text-center ">
              <div className="text-xs text-indigo-600">
                {moment(current).format('MMM')}{' '}
              </div>
              <div className="text-sm leading-[16px] text-indigo-600">
                {current.date()}
              </div>
            </div>
          </div>
          <div className="ml-2 grow">
            <div className="flex flex-col">
              <div className="overflow-hidden text-sm text-gray-900">
                {moment(current).format('ddd.')}
                {` Delivery`}
              </div>
              <div
                className={classNames(
                  currentDayItems.length >= 1
                    ? 'w-fit rounded-md bg-green-100 px-2 text-sm text-green-800'
                    : 'text-sm text-gray-600'
                )}
              >
                {currentDayItems.length > 0
                  ? currentDayItems.length > 1
                    ? `${currentDayItems.length > 1} items` &&
                      isFullVals &&
                      isFullVals[index] &&
                      isFullVals[index].spaceAvailable <= 6
                      ? `✓ ${currentDayItems.length}/${isFullVals[index].spaceAvailable} items`
                      : `✓ ${currentDayItems.length} items`
                    : isFullVals &&
                      isFullVals[index] &&
                      isFullVals[index].spaceAvailable <= 6
                    ? `✓ 1/${isFullVals[index].spaceAvailable} items`
                    : `✓ 1 item`
                  : `Select Items`}
                {isFullVals &&
                  isFullVals[index] &&
                  currentDayItems.length > isFullVals[index].spaceAvailable && (
                    <>
                      <div>
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="pr-1 text-red-500"
                        />
                        Error too many items
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {next ? (
        current.startOf('day').unix() <
          moment().startOf('day').add(30, 'day').unix() && (
          <div className="pl-2">
            <button
              className={classNames(
                firstTimeUser
                  ? 'h-[50px] w-[56px] rounded-md border border-gray-300 bg-white md:w-24'
                  : 'h-[50px] w-[56px] rounded-md border border-gray-300 bg-white md:w-24'
              )}
              onClick={() => {
                setDateStream(dateStream.next().clone());
                setIndex(index + 1);
              }}
            >
              <div className="flex items-center justify-center">
                <div className="flex flex-col px-[7px]">
                  <div className="text-xs text-gray-500">
                    {moment(next).format('MMM')}{' '}
                  </div>
                  <div className="text-sm leading-[16px] text-gray-500">
                    {next.date()}
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="text-xs text-gray-500"
                />
              </div>
            </button>
          </div>
        )
      ) : (
        <>
          <div className="pl-2">
            <button
              className={classNames(
                firstTimeUser
                  ? 'h-[50px] w-[56px] rounded-md bg-indigo-600 bg-white text-sm text-white md:w-24'
                  : 'h-[50px] w-[56px] rounded-md bg-indigo-600 bg-white text-sm text-white md:w-24'
              )}
              onClick={() => handleAddDate()}
            >
              <div className="flex items-center justify-center px-2 text-sm">
                Next
              </div>
            </button>
          </div>
        </>
      )}
    </div>
  );
}
