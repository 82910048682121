import { ChevronRightIcon } from '@heroicons/react/solid';
import { classNames } from '../helpers';
import { Dispatch, SetStateAction } from 'react';
import { Fridge } from '../types';
import { useNavigate } from 'react-router-dom';

interface Props {
  items: Fridge[] | undefined;
  title: string;
  functionToSend?: Dispatch<SetStateAction<Fridge | undefined>>;
  changed: boolean;
  height?: string;
}

export default function PrestockDropdown({ title, changed }: Props) {
  const navigate = useNavigate();
  return (
    <div
      className={`relative inline-block text-left`}
      onClick={() => navigate('/prestock-fridges')}
    >
      <div onClick={() => navigate('/prestock-fridges')}>
        <div
          className={classNames(
            changed
              ? 'inline-flex w-full items-center justify-between bg-white pb-2 text-lg font-medium text-gray-900 focus:outline-none'
              : 'inline-flex w-full items-center justify-between bg-white pb-2 text-lg font-medium text-gray-900 focus:outline-none'
          )}
        >
          {title}
          <ChevronRightIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
}
