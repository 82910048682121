import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default function CheckoutHeader() {
  const navigate = useNavigate();
  return (
    <div className="sticky top-0 z-20">
      <div className="flex justify-between border-b bg-white py-5 px-6 shadow-sm">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="fa-lg w-14 text-gray-400"
          onClick={() => navigate('/order-summary')}
        />
        <div className="text-base font-medium leading-6 text-gray-900">
          Checkout
        </div>
        <div />
      </div>
    </div>
  );
}
