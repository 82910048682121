import { useMutation } from '@apollo/client';
import { CREATE_CREDIT_CARD } from '../services/user.service';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useMe } from '../services/user.service';
import { useState, Dispatch, SetStateAction } from 'react';
import { classNames } from '../helpers';

interface Props {
  setEdited: Dispatch<SetStateAction<boolean>>;
}

export default function ProfileCreditCard({ setEdited }: Props) {
  const [createCreditCard] = useMutation(CREATE_CREDIT_CARD);
  const stripe = useStripe();
  const elements = useElements();
  const { data: me, loading, refetch } = useMe();
  const [errors, setErrors] = useState<string[]>([]);
  const [loadingCard, setLoadingCard] = useState(false);

  function handleError(error: string) {
    setErrors([error]);
  }

  async function handleSubmit() {
    setErrors([]);
    setLoadingCard(true);
    if (!me || !stripe || !elements || !me.email) {
      handleError('Could not find subscription');
      return;
    }
    const cardElement = elements.getElement('card');
    if (!cardElement) {
      handleError('Please enter a valid credit card');
      return;
    }
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    if (!paymentMethod || error || !me) {
      handleError('Please enter a valid credit card');
      return;
    }

    try {
      const { data: cardData } = await createCreditCard({
        variables: {
          input: {
            paymentMethodId: paymentMethod.id,
            customer: me.email,
          },
        },
      });
      if (!cardData) {
        handleError('Error updating');
      }
      setLoadingCard(false);
      setEdited(false);
      refetch();
    } catch (error: any) {
      const message = error.graphQLErrors[0].message.split(': ')[1];
      handleError(`Card Error: ${message}`);
      return;
    }
  }

  console.log(errors);

  return (
    <>
      <CardElement
        options={{ hidePostalCode: true }}
        className="block w-full rounded-md border border-gray-300 p-4 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      />
      <button
        type="button"
        onClick={handleSubmit}
        disabled={loading || loadingCard || !stripe || !elements}
        className={classNames(
          loadingCard ? 'bg-indigo-300' : 'bg-indigo-600',
          'mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent  px-4 py-3 text-center text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        )}
      >
        {loadingCard ? 'Loading...' : 'Update'}
      </button>
    </>
  );
}
