import { useCloudinaryImage, classNames } from '../helpers';
import { BookingItem } from '../types';
interface Props {
  item: BookingItem;
  position: number;
  adjustItem: (position: number) => void;
  checked: boolean;
}

export default function RescheduleItem({
  item,
  position,
  adjustItem,
  checked,
}: Props) {
  return (
    <>
      <div className="flex w-full justify-center">
        <div
          className="w-full px-4 pt-2 sm:w-1/2"
          key={position}
          onClick={() => adjustItem(position)}
        >
          <div
            className={classNames(
              checked
                ? 'flex flex-row items-center justify-between rounded-md bg-indigo-100 p-4 text-sm text-gray-900 shadow-md'
                : 'flex flex-row items-center justify-between rounded-md p-4 text-sm text-gray-900 shadow-md'
            )}
          >
            <div className="flex flex-row items-center">
              <img
                src={useCloudinaryImage(item.image)}
                alt={item.name}
                className="object-fit h-[60px] w-[60px]"
              ></img>
              <div className="flex flex-col pl-2">
                <div>{item.name}</div>
                <div className="max-w-[22ch] truncate">{item.description}</div>
              </div>
            </div>
            <input
              type={'checkbox'}
              checked={checked}
              onChange={() => {}}
            ></input>
          </div>
        </div>
      </div>
    </>
  );
}
