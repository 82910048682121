import { useNavigate } from 'react-router-dom';
import { Product, Prestock } from '../types';
import { useEffect, useState } from 'react';
import { useMe } from '../services/user.service';
import BottomSheetModal from '../components/bottom-sheet-modal';
import PrestockSheet from './prestock-sheet';
import { useModalToggle } from '../hooks';
import PrestockItems from './prestock-items';
import { FRIDGE_STATE_KEY } from '../persistent-storage/fridge-state.persistent-storage';
import { FridgeStatePersistentStorageItem } from '../persistent-storage/fridge-state.persistent-storage';
import PersistentStorage from '../persistent-storage/persistent-storage';
import { useAvailableFridges } from '../services/fridges.service';
import { Fridge } from '../types';
import PrestockTopNavigation from './prestock-top-navigation';
import {
  GET_PRESTOCK_PAYMENT_INTENT,
  usePrestockItems,
} from '../services/prestock.service';
import { getNodeDataFromEdges } from '../helpers';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import PrestockSkeleton from '../loading-skeletons/prestock-skeleton';

export default function PrestockMenu() {
  const navigate = useNavigate();

  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );
  const fridge = PersistentStorage.get(selectedFridgeState);
  const [sortedFridges, setSortedFridges] = useState<Fridge[]>();

  const [selectedFridge, setSelectedFridge] = useState<Fridge | undefined>();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [bookingId, setBookingId] = useState<number | undefined>();
  const [currSold, setCurrSold] = useState(false);
  const [deliveredAt, setDeliveredAt] = useState(false);
  const { data: me } = useMe();
  const dateTimeString = moment().toISOString(true).substring(0, 23);
  const [time, setTime] = useState<string>('');
  const [filteredItems, setFilteredItems] = useState<Prestock[]>();
  const today = moment();

  const [getPaymentIntent] = useMutation<{
    getPaymentIntentPrestock: { clientSecret: string };
  }>(GET_PRESTOCK_PAYMENT_INTENT);
  const {
    fridges,
    loading: fridgeLoading,
    error,
  } = useAvailableFridges(time, me?.email);
  const productModal = useModalToggle();
  const visible = productModal.props.visible;

  const {
    data: preStockItems,
    loading: preLoading,
    error: preError,
    refetch,
  } = usePrestockItems(selectedFridge && selectedFridge.id, time);

  function handleOpenProductModal(
    product: Product,
    bookingId: number,
    sold: boolean | undefined,
    deliveredAt: string | undefined
  ) {
    setSelectedProduct(product);
    if (sold) {
      setCurrSold(sold);
    } else {
      setCurrSold(false);
    }
    if (deliveredAt) {
      setDeliveredAt(true);
    } else {
      setDeliveredAt(false);
    }
    setBookingId(bookingId);
    productModal.open();
  }

  async function handleAddItem() {
    if (me && selectedFridge && selectedProduct && bookingId) {
      let discount = localStorage.getItem('prestockDiscount');
      const input = [
        {
          payee: me.email,
          booking: bookingId,
          discountCode: discount ?? '',
          creditCardDetailsId: null,
        },
      ];

      try {
        const { data, errors } = await getPaymentIntent({
          variables: {
            input: input,
          },
        });
        if (data && !errors) {
          const clientSecret = data.getPaymentIntentPrestock.clientSecret;
          PersistentStorage.update(selectedFridgeState, selectedFridge);
          navigate(`/prestock-checkout/${clientSecret}`, {
            state: { product: selectedProduct, bookingId: bookingId },
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  async function handlePlusItem(product: Product, bookingId: number) {
    if (me && selectedFridge && product && bookingId) {
      let discount = localStorage.getItem('prestockDiscount');
      const input = [
        {
          payee: me.email,
          booking: bookingId,
          discountCode: discount ?? '',
          creditCardDetailsId: null,
        },
      ];
      try {
        const { data, errors } = await getPaymentIntent({
          variables: {
            input: input,
          },
        });
        if (data && !errors) {
          const clientSecret = data.getPaymentIntentPrestock.clientSecret;
          PersistentStorage.update(selectedFridgeState, selectedFridge);
          navigate(`/prestock-checkout/${clientSecret}`, {
            state: { product: product, bookingId: bookingId },
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  useEffect(() => {
    if (fridges) {
      const allFridges = fridges.filter((e) => e.prestock);
      setSortedFridges([...allFridges]);
    }
  }, [fridges]);

  useEffect(() => {
    if (fridge) {
      const preselectedFridge = sortedFridges
        ? sortedFridges.filter((e) => e.id === fridge?.id)
        : [];
      if (preselectedFridge && preselectedFridge.length > 0) {
        setSelectedFridge(fridge);
      }
    }
  }, [sortedFridges]);

  useEffect(() => {
    setTime(dateTimeString);
  }, []);

  useEffect(() => {
    if (preStockItems) {
      const pastOrders = preStockItems.filter(
        (e) => moment(e.booking.startAt).date() !== today.date()
      );

      const currentOrders = preStockItems.filter((e) =>
        moment(e.booking.endAt)
          .set({ hour: 23, minutes: 59, seconds: 59 })
          .isSameOrAfter(today)
      );
      const deliveredToday = currentOrders.filter(
        (e) => moment(e.booking.deliveredAt).date() === today.date()
      );

      //if it is Monday or an item is delivered, display the current orders in the window
      if (deliveredToday.length > 0 || today.day() === 1) {
        setFilteredItems(currentOrders);
        // if it is past 11:30AM display todays orders regardless of whether it's been delivered
      } else if (
        today.hour() > 11 ||
        (today.hour() === 11 && today.minutes() >= 30)
      ) {
        setFilteredItems(currentOrders);
        //otherwise display past bookings and hide todays
      } else {
        setFilteredItems(pastOrders);
      }
    } else {
      setFilteredItems(undefined);
    }
  }, [preStockItems]);

  return (
    <>
      <PrestockTopNavigation
        title="Menu of the Day"
        fridges={sortedFridges}
        selectedFridge={selectedFridge}
        setSelectedFridge={setSelectedFridge}
      />
      <div className="-mx-4 bg-[#EAF1FC] px-4 text-blue-800">
        <div className="px-4 py-2">
          Explore our fresh selection of Grab 'n Go items, re-stocked daily at
          11:30AM.
        </div>
      </div>
      {filteredItems ? (
        <div className="p-4">
          <div className="pb-8 pt-4">
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8">
              {filteredItems &&
                filteredItems.map((product, i) => {
                  const items =
                    product.booking.items.edges &&
                    getNodeDataFromEdges(product.booking.items.edges);

                  if (items && items[0]) {
                    return (
                      <PrestockItems
                        key={i}
                        product={items[0]}
                        handleOpenProductModal={handleOpenProductModal}
                        addItemToOrder={handlePlusItem}
                        sold={product.sold}
                        bookingId={product.booking.id}
                        setBookingId={setBookingId}
                        delivered={product.booking.deliveredAt}
                        startAt={product.booking.startAt}
                      />
                    );
                  }
                })}
            </div>
            <div className="oveflow-scroll-y">
              <BottomSheetModal
                visible={visible}
                close={productModal.close}
                height={0.935}
                blocked={true}
              >
                {selectedProduct && (
                  <PrestockSheet
                    close={productModal.close}
                    product={selectedProduct}
                    addItemToOrder={handleAddItem}
                    sold={currSold}
                    deliveredAt={deliveredAt}
                  />
                )}
              </BottomSheetModal>
            </div>
          </div>
        </div>
      ) : (
        <PrestockSkeleton />
      )}
    </>
  );
}
