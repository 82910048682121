import { useMutation } from '@apollo/client';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NotificationType } from '../constants';
import { validateEmail, validatePhoneNumber } from '../helpers';
import TopNavigation from '../navigation/top-navigation';
import ErrorPage from '../screens/error';
import { CHANGE_USER, useMe } from '../services/user.service';
import { User } from '../types';
import Notification from './notification';

type LocationState = { toEdit: string };

export default function AccountEditItem() {
  const [editable, setEditable] = useState('');
  const [loading, setLoading] = useState(false);
  const [editPhone, setEditPhone] = useState('');
  const [editFirstName, setEditFirstName] = useState('');
  const [editLastName, setEditLastName] = useState('');
  const [editEmail, setEditEmail] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const inputEl = useRef(null);

  const { data: user, refetch, error } = useMe();
  const location = useLocation();
  const [updateUser] = useMutation<{ lastName: string }>(CHANGE_USER);
  const navigate = useNavigate();

  useEffect(() => {
    if (location && location.state) {
      const { toEdit } = location.state as LocationState;
      setEditable(toEdit);
    }
  }, [location]);

  const handleError = () => {
    toast(
      <Notification
        type={NotificationType.Error}
        message={'Error updating information'}
        title="Error"
      />,
      { position: 'top-right' }
    );
  };

  async function handleChangeUser(user: User | undefined) {
    if (user) {
      if (editEmail !== '') {
        if (validateEmail(editEmail) !== '') {
          setPhoneError(true);
          return;
        }
      }
      if (editPhone !== '') {
        if (validatePhoneNumber(editPhone) !== '') {
          setEmailError(true);
          return;
        }
      }
      try {
        setLoading(true);
        const { data, errors } = await updateUser({
          variables: {
            id: user?.pk,
            input: {
              email: editEmail !== '' ? editEmail : user.email,
              firstName: editFirstName !== '' ? editFirstName : user.firstName,
              lastName: editLastName !== '' ? editLastName : user.lastName,
              phone: editPhone !== '' ? editPhone : user.phone,
            },
          },
        });
        setLoading(false);
        if (!data || errors) {
          handleError();
        }
        if (!loading) {
          setLoading(true);
          await refetch();
          setLoading(false);
          navigate(-1);
        }
      } catch (e) {
        handleError();
      }
    }
  }

  if (error || !location.state) {
    return (
      <ErrorPage
        error={'Could not find user or you got here from the wrong page'}
      />
    );
  }

  return (
    <>
      <TopNavigation title="" url={'/upcoming'} />

      <div className="px-4">
        <div className="pt-6"></div>
        <div className="py-4">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-5 text-gray-500"
          >
            {editable}
          </label>
          {editable === 'Phone Number' && (
            <div className="pt-1">
              <InputMask
                mask="+1 (999) 999-9999"
                onChange={(e) => setEditPhone(e.target.value)}
                defaultValue={user?.phone}
                autoFocus={true}
                className={'h-[42px] w-full px-1'}
                ref={inputEl}
              ></InputMask>
              <input className="hidden h-[42px] w-full" ref={inputEl} />
            </div>
          )}
          {editable === 'Last Name' && (
            <div className="pt-1">
              <input
                className="h-[42px] w-full px-1"
                autoFocus={true}
                defaultValue={user?.lastName}
                onChange={(e) => setEditLastName(e.target.value)}
              ></input>
            </div>
          )}
          {editable === 'Email' && (
            <div className="pt-1">
              <input
                className="h-[42px] w-full px-1"
                autoFocus={true}
                defaultValue={user?.email}
                onChange={(e) => setEditEmail(e.target.value)}
              ></input>
            </div>
          )}
          {editable === 'First Name' && (
            <div className="h-[42px] pt-1">
              <input
                className="h-[42px] w-full px-1"
                autoFocus={true}
                defaultValue={user?.firstName}
                onChange={(e) => setEditFirstName(e.target.value)}
              ></input>
            </div>
          )}
          <div className="border-b pb-4"></div>
          <div className="pt-4">
            {emailError && (
              <div
                className="relative rounded bg-red-50 px-4 py-3 text-red-700"
                role="alert"
              >
                <div className="mr-12 flex flex-row justify-around">
                  <div>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="text-red-500"
                    />
                  </div>
                  <div className="ml-4">
                    <strong className="font-medium">
                      Error changing phone number.
                    </strong>
                    <span className="block sm:inline">
                      {'Please follow this format:'}
                      <br />
                      {' +1 (306)-555-0123'}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {phoneError && (
              <div
                className="relative rounded bg-red-50 px-4 py-3 text-red-700"
                role="alert"
              >
                <div className="mr-12 flex flex-row justify-around">
                  <div>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="text-red-500"
                    />
                  </div>
                  <div className="ml-4">
                    <strong className="font-medium">
                      Error changing email address.
                    </strong>
                    <span className="block sm:inline">{}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-center px-4 py-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-1/4 sm:justify-center sm:text-sm"
              onClick={() => handleChangeUser(user)}
            >
              {loading ? 'Loading...' : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
