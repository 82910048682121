import { gql } from '@apollo/client';

export const LOGIN_PHONE = gql`
  mutation tokenAuth($phone: String!, $token: String!) {
    tokenAuth(phone: $phone, token: $token) {
      token
      refreshToken
      errors
      success
      user {
        id
        pk
        firstName
        lastName
        email
        phone
        archived
        verified
        pushReminders
        orderUpdatesEmail
        orderUpdatesSms
        orderUpdatesPush
        promotionalEmails
        promotionalPush
        discountUpdatesPush
        reminderWeekDays
        reminderCounter
        reviewUpdatesPush
        reminderWeekDays
        reminderTime
        reminderCounter
        cubbiWalletPrompt
        favouriteProducts {
          edges {
            node {
              id
            }
          }
        }
        defaultPod {
          id
          name
          isVisible
          image
          size
          prestock
          location {
            description
            building
            floor
            street
            room
            city
            state
            zip
            additionalDirections
            additionalDirectionsText
            latitude
            longitude
          }
        }
        defaultSelectedCard {
          paymentMethodId
          last4
          expYear
          expMonth
        }
        heatablePrompt
        prestockPrompt
        cubbiWalletPrompt
        multipleDayPrompt
        orderCutoffTimePrompt
        searchFilterSortingPrompt
      }
    }
  }
`;

export const LOGIN_EMAIl = gql`
  mutation tokenAuth($email: String!, $token: String!) {
    tokenAuth(email: $email, token: $token) {
      token
      refreshToken
      errors
      user {
        id
        pk
        firstName
        lastName
        email
        phone
        archived
        verified
        pushReminders
        orderUpdatesEmail
        orderUpdatesSms
        orderUpdatesPush
        promotionalEmails
        promotionalPush
        discountUpdatesPush
        reviewUpdatesPush
        reminderTime
        reminderWeekDays
        reminderCounter
        favouriteProducts {
          edges {
            node {
              id
            }
          }
        }
        defaultPod {
          id
          name
          isVisible
          image
          size
          prestock
          location {
            description
            building
            floor
            street
            room
            city
            state
            zip
            additionalDirections
            additionalDirectionsText
            latitude
            longitude
          }
        }
        defaultSelectedCard {
          paymentMethodId
          last4
          expYear
          expMonth
        }
        heatablePrompt
        prestockPrompt
        cubbiWalletPrompt
        multipleDayPrompt
        orderCutoffTimePrompt
        searchFilterSortingPrompt
      }
    }
  }
`;
export const LOGIN = gql`
  mutation tokenAuth($phone: String!, $password: String!) {
    tokenAuth(phone: $phone, password: $password) {
      token
      refreshToken
      errors
      user {
        id
        pk
        firstName
        lastName
        email
        phone
        archived
        verified
        pushReminders
        orderUpdatesEmail
        orderUpdatesSms
        orderUpdatesPush
        promotionalEmails
        promotionalPush
        discountUpdatesPush
        reviewUpdatesPush
        reminderWeekDays
        reminderTime
        reminderCounter
        favouriteProducts {
          edges {
            node {
              id
            }
          }
        }
        defaultPod {
          id
          name
          isVisible
          image
          size
          prestock
          location {
            description
            building
            floor
            street
            room
            city
            state
            zip
            additionalDirections
            additionalDirectionsText
            latitude
            longitude
          }
        }
        defaultSelectedCard {
          paymentMethodId
          last4
          expYear
          expMonth
        }
        heatablePrompt
        prestockPrompt
        cubbiWalletPrompt
        multipleDayPrompt
        orderCutoffTimePrompt
        searchFilterSortingPrompt
      }
    }
  }
`;
export const PASSWORD_RESET = gql`
  mutation passwordReset(
    $token: String!
    $password1: String!
    $password2: String!
  ) {
    passwordReset(
      token: $token
      newPassword1: $password1
      newPassword2: $password2
    ) {
      success
      errors
    }
  }
`;

export const CHECK_PHONE = gql`
  query phoneNumber($email: String) {
    phoneNumber(email: $email) {
      phone
    }
  }
`;

export const REGISTER = gql`
  mutation register(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password1: String!
    $password2: String!
    $phone: String!
    $birthDate: String!
  ) {
    register(
      email: $email
      birthDate: $birthDate
      firstName: $firstName
      lastName: $lastName
      password1: $password1
      password2: $password2
      phone: $phone
    ) {
      errors
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
      errors
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation sendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      errors
    }
  }
`;

export const SEND_SMS_LOGIN_TOKEN = gql`
  mutation sendSmsLoginToken($input: SendLoginTokenInput!) {
    sendSmsLoginToken(input: $input) {
      ok
      success
    }
  }
`;

export const SEND_EMAIL_LOGIN_TOKEN = gql`
  mutation sendEmailLoginToken($input: SendLoginTokenInput!) {
    sendEmailLoginToken(input: $input) {
      ok
      success
    }
  }
`;
