import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationType } from '../constants';
import { Notification as INotification } from '../types';

export interface Props extends INotification {}

export default function Notification({ title, message, type }: Props) {
  function getNotificationIconType() {
    switch (type) {
      case NotificationType.Error:
        return (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="h-6 w-6 text-red-400"
            aria-hidden="true"
          />
        );
      case NotificationType.Warning:
        return (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="h-6 w-6 text-orange-400"
            aria-hidden="true"
          />
        );
      case NotificationType.Success:
        return (
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="h-6 w-6 text-green-400"
            aria-hidden="true"
          />
        );
      default:
        return (
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="h-6 w-6 text-indigo-400"
            aria-hidden="true"
          />
        );
    }
  }
  return (
    <>
      <div className="flex flex-shrink-0 flex-row px-2">
        {getNotificationIconType()}
        <p className="pl-2 text-sm font-medium text-gray-900">{title}</p>
      </div>
      <div className="flex flex-col px-2">
        <p className="mt-1 text-sm text-gray-500">{message}</p>
      </div>
    </>
    // <div>
    //   <div
    //     aria-live="assertive"
    //     className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
    //   >
    //     <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
    //       <Transition
    //         show
    //         as={Fragment}
    //         enter="transform ease-out duration-300 transition"
    //         enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    //         enterTo="translate-y-0 opacity-100 sm:translate-x-0"
    //         leave="transition ease-in duration-100"
    //         leaveFrom="opacity-100"
    //         leaveTo="opacity-0"
    //       >
    //         <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
    //           <div className="p-4">
    //             <div className="flex items-start">
    //               <div className="ml-3 w-0 flex-1 pt-0.5">
    //                 <p className="text-sm font-medium text-gray-900">{title}</p>
    //                 <p className="mt-1 text-sm text-gray-500">{message}</p>
    //               </div>
    //               <div className="ml-4 flex flex-shrink-0">
    //                 <button className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
    //                   <span className="sr-only">Close</span>
    //                   <FontAwesomeIcon
    //                     icon={faTimesCircle}
    //                     className="h-5 w-5"
    //                     aria-hidden="true"
    //                   />
    //                 </button>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </Transition>
    //     </div>
    //   </div>
    // </div>
  );
}
