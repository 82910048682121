import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './en/translation.json';
import spanish from './es/translation.json';
import french from './fr/translation.json';

const languages = ['en', 'es', 'fr'];

export const resources = {
  en: {
    translation: english,
  },
  es: {
    translation: spanish,
  },
  fr: {
    translation: french,
  },
} as const;
i18n.use(initReactI18next).init({
  lng: 'en',
  supportedLngs: languages,
  fallbackLng: 'en',
  debug: false,
  resources: resources,
  react: {
    useSuspense: false,
  },
});
