import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { Dispatch, Fragment, SetStateAction } from 'react';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function MealAlert({ open, setOpen }: Props) {
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={open}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="flex w-full flex-shrink-0 justify-end px-2 pt-2">
                <button
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className="px-4 pb-4">
                <div className="flex flex-col items-start">
                  <div className="flex-shrink-0"></div>
                  <div className="ml-3 flex-1 pt-0.5">
                    <p className="mt-1 pb-4 text-sm text-gray-900">
                      Enjoy! Remember to tightly shut the door(s) after
                      collecting your items.
                    </p>
                  </div>
                  <div className="flex w-full flex-row justify-between px-3">
                    <a
                      target="__blank"
                      href="tel:3064005430"
                      className={
                        'block text-sm text-red-500 hover:text-red-700'
                      }
                    >
                      Contact Emergency Support
                    </a>
                    <dt
                      className="text-sm text-indigo-600 hover:text-indigo-900"
                      onClick={() => setOpen(false)}
                    >
                      Done
                    </dt>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
