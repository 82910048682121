import { Booking } from '../types';
import { PersistentStorageItem } from './persistent-storage';

export const FRIDGE_ORDER_STATE_KEY = 'fridge-order-state';

export class FridgeOrderStatePersistentStorageItem extends PersistentStorageItem<
  string,
  Booking[]
> {
  public key: string;
  public defaultValue?: Booking[] = undefined;

  constructor(key: string) {
    super();
    this.key = key;
  }

  public encode(item: Booking[]) {
    return JSON.stringify(item);
  }

  public decode(encodedFridgeState: string) {
    return JSON.parse(encodedFridgeState) as Booking[];
  }
}
