import { useNavigate } from 'react-router-dom';
import ProgressIndicator from '../../onboarding/progress-indicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { PinInput } from 'react-input-pin-code';
import { useState } from 'react';
import { classNames, useWindowDimensions } from '../../helpers';
import { useLocation } from 'react-router-dom';
import Lottie from 'lottie-react';
import signupPhone from '../../img/lotties/signup-phone.json';
import { VERIFY_SMS, SEND_VERIFY_SMS } from '../../services/user.service';
import { GenericResponse, AuthResponse } from '../../types';
import { useMutation } from '@apollo/client';
import { LOGIN_PHONE } from '../../services/auth.service';
import Notification from '../../components/notification';
import { toast } from 'react-toastify';
import { NotificationType } from '../../constants';
import { TOKEN, REFRESH_TOKEN, FRIDGE } from '../../constants';

interface phoneState {
  phone: string;
}

export default function SignupPhoneVerify() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as phoneState;
  const { phone } = state;
  const [values, setValues] = useState<string[]>(['', '', '', '', '']);
  const [pin, setPin] = useState<string>();
  const [loading, setLoading] = useState(false);

  const [resendSms] = useMutation<{
    sendVerifySms: GenericResponse;
  }>(SEND_VERIFY_SMS);
  const [verifySms] = useMutation<{
    verifySms: GenericResponse;
  }>(VERIFY_SMS);
  const [loginPhone] = useMutation<{
    tokenAuth: AuthResponse;
  }>(LOGIN_PHONE);

  const handleError = (message: string) =>
    toast(
      <Notification
        type={NotificationType.Error}
        message={`${message}`}
        title="Error"
      />,
      { position: 'top-center', autoClose: 5000 }
    );

  async function handleResendText() {
    await resendSms({
      variables: {
        input: {
          phone,
        },
      },
      onError: (e) => {
        handleError(e.message);
      },
    });
  }

  async function verifyCode() {
    setLoading(true);
    const { data } = await verifySms({
      variables: {
        input: {
          phone,
          code: pin,
        },
      },
      onError: (e) => {
        if (e.message.includes('Incorrect verification code.')) {
          handleError('The code you entered is incorrect.');
        } else {
          handleError(e.message);
        }
      },
    });
    setLoading(false);
    return data;
  }

  async function login() {
    setLoading(true);
    const { data } = await loginPhone({
      variables: {
        phone,
        token: pin,
      },
      onError: (e) => {
        console.log(e.message);
        handleError(e.message);
      },
    });
    setLoading(false);
    return data;
  }

  async function setupUser(authResponse: AuthResponse) {
    localStorage.setItem(TOKEN, authResponse.token);
    localStorage.setItem(REFRESH_TOKEN, authResponse.refreshToken);
    if (
      authResponse &&
      authResponse.user &&
      authResponse.user.defaultPod &&
      authResponse.user.defaultPod.id
    ) {
      localStorage.setItem(
        FRIDGE,
        JSON.stringify(authResponse.user.defaultPod.id)
      );
    }
  }

  async function handleNext() {
    const codeVerifyData = await verifyCode();
    console.log(codeVerifyData);
    if (codeVerifyData?.verifySms?.ok) {
      const loginData = await login();
      if (loginData?.tokenAuth) {
        await setupUser(loginData.tokenAuth);
        if (
          !loginData.tokenAuth.user.firstName &&
          !loginData.tokenAuth.user.lastName
        ) {
          navigate('/signup-name', {
            state: { user: loginData.tokenAuth.user },
          });
        } else if (loginData.tokenAuth.user.email.includes('cubbi_temp_')) {
          navigate('/signup-email', {
            state: { user: loginData.tokenAuth.user },
          });
        } else {
          navigate('/upcoming');
        }
      }
    }
  }

  return (
    <div className={`flex h-screen w-screen justify-center bg-background p-4`}>
      <div className="lg:w-3/8">
        <div className="items-center">
          <ProgressIndicator filled={2} length={4} />
          <div
            className="flex flex-row items-center text-center"
            onClick={() => navigate('/login')}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="text-xl text-gray-500"
            />
            <div className={`pl-4 text-2xl font-bold text-graphite-900`}>
              Verify your phone
            </div>
          </div>
          <div className="py-4 text-graphite-900">
            <div className="flex items-start justify-start pb-4">
              <Lottie style={{ height: 80 }} loop animationData={signupPhone} />
            </div>
            <span>Enter the code sent to</span>
            <span className="font-bold"> {state.phone}.</span>
          </div>
          <div className="flex flex-col rounded-lg bg-white py-4 px-6 pt-4 shadow-lg sm:w-96">
            <div className="py-2">
              <PinInput
                inputClassName="px-4"
                containerClassName="w-full justify-between"
                inputStyle={{ width: 48 }}
                type="text"
                values={values}
                onChange={(value, index, values) => {
                  setValues(values);
                  setPin(values.join(''));
                }}
                focusBorderColor={'rgb(99,102,241)'}
                validBorderColor={'rgb(99,102,241)'}
                placeholder=""
                inputMode={'numeric'}
                autoComplete="one-time-code"
              />
              <p
                className={'pt-4 text-sm text-primary-700'}
                onClick={() => handleResendText()}
              >
                Didn't get it? Resend code
              </p>
              <div className="py-4">
                <button
                  type="submit"
                  disabled={pin && pin.length < 5 ? true : false}
                  className={classNames(
                    pin && pin.length < 5
                      ? 'bg-indigo-600/20'
                      : pin
                      ? 'bg-indigo-600'
                      : 'bg-indigo-600/20',
                    'group relative flex w-full justify-center rounded-md border border-transparent p-3 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  )}
                  onClick={() => handleNext()}
                >
                  <span className="flex items-center text-base"></span>
                  {loading ? 'Loading...' : 'Next'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
