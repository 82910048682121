import { GoogleMap, OverlayView } from '@react-google-maps/api';
import { useState } from 'react';
import LoadingSkeleton from '../components/loading-skeleton';
import { useCloudinaryImage, handleAdditionalDirections } from '../helpers';
import { useModalToggle } from '../hooks';
import mapPin from '../img/map-pin.png';
import { FridgeWithDistance } from '../screens/fridge-select';
import FridgeImageModal from './fridge-image-modal';
import FridgeMapMoreDetails from './fridge-map-more-details';
interface Props {
  isLoaded: boolean;
  fridge: FridgeWithDistance;
  handleFridgeSelect: (fridge: FridgeWithDistance) => void;
}

export default function FridgeMapCard({
  fridge,
  isLoaded,
  handleFridgeSelect,
}: Props) {
  const { open, close, props } = useModalToggle();
  const fridgeImage = useCloudinaryImage(fridge?.image);
  const [moreDetails, setMoreDetails] = useState(false);
  const logo = useCloudinaryImage(fridge?.owner?.logo);
  const building =
    fridge?.location?.building !== null ? fridge?.location?.building : '';
  const secondLine = `${fridge?.location?.street} ${building}`;
  const floorWithInterpunct =
    fridge?.location?.floor && fridge?.location.room
      ? `Floor ${fridge.location.floor} · `
      : '';
  const floorWithoutInterpunct =
    fridge?.location?.floor && !fridge.location.room
      ? `Floor ${fridge.location.floor}`
      : '';
  const room =
    fridge?.location?.room && !fridge?.location.additionalDirectionsText
      ? `In ${fridge.location?.room} `
      : '';
  const roomWithComma =
    fridge?.location?.room && fridge?.location.additionalDirectionsText
      ? `In ${fridge.location.room},`
      : '';
  const additionalDirections = fridge?.location?.additionalDirections
    ? handleAdditionalDirections(fridge)
    : '';
  const additionalDirectionsText = fridge?.location?.additionalDirectionsText
    ? `${fridge?.location.additionalDirectionsText}`
    : '';
  const thirdLine = `${floorWithInterpunct} ${floorWithoutInterpunct} ${roomWithComma} ${room} ${additionalDirections} ${additionalDirectionsText} `;

  if (!isLoaded) {
    return <LoadingSkeleton size={1} />;
  }

  return (
    <>
      <div className="divide-y rounded-xl shadow-xl">
        <div className="w-full">
          <FridgeImageModal
            fridge={fridge}
            fridgeImage={fridgeImage}
            visible={props.visible}
            close={close}
          />
          {/* <div
            className="z-0 h-40"
            onClick={() => setMoreDetails(!moreDetails)}
          > */}
          {/* {fridge.location?.latitude && fridge.location?.longitude && (
              <GoogleMap
                mapContainerStyle={{
                  width: '100%',
                  height: '165px',
                  borderTopRightRadius: 8,
                  borderTopLeftRadius: 8,
                  zIndex: -100,
                }}
                center={{
                  lat: fridge.location?.latitude,
                  lng: fridge.location?.longitude,
                }}
                zoom={13.5}
                options={options}
              >
                <OverlayView
                  key={'center'}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  position={{
                    lat: fridge.location?.latitude,
                    lng: fridge.location?.longitude,
                  }}
                >
                  <div className="inline-flex w-10 cursor-pointer items-center justify-center">
                    <span className="text-lg font-medium leading-none text-white">
                      <img
                        className="h-full w-full"
                        alt={'user-pin'}
                        src={mapPin}
                      />
                    </span>
                  </div>
                </OverlayView>
              </GoogleMap>
            )} */}
          {/* </div> */}
          <div className="mt-4 flex flex-row items-center py-4 px-4">
            <div>
              <div className="flex flex-row">
                <div
                  className="flex-none cursor-pointer overflow-hidden rounded-xl border-4 border-white shadow-md"
                  onClick={open}
                >
                  <img
                    className="min-h-24 min-w-24 h-24 w-24 shrink-0 bg-white object-cover"
                    src={fridge?.image ? fridgeImage : logo}
                    alt={fridge.owner?.name}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 ml-1 flex-1 content-end divide-y divide-gray-200 px-4">
              <div>
                <div className="flex items-start justify-between">
                  <div className="w-4/6">
                    <div className="text-sm font-medium leading-5 text-gray-900">
                      {fridge?.name}
                    </div>
                    {!fridge?.deliveryFee && (
                      <div className="text-sm leading-5 text-gray-500">
                        Free Delivery
                      </div>
                    )}
                  </div>
                  {!isNaN(fridge.distance) && (
                    <span className="ml-2.5 text-sm text-gray-400">
                      {fridge.distance.toFixed(1)} km
                    </span>
                  )}
                </div>
                {fridge?.serialNumber && (
                  <div className="text-sm leading-[16px] text-gray-500">
                    Pod #{fridge?.serialNumber}
                  </div>
                )}

                {!moreDetails ? (
                  <>
                    <p className="mt-2 text-sm text-gray-500">{secondLine}</p>
                    <p className=" text-sm text-gray-500">{thirdLine}</p>
                  </>
                ) : (
                  <FridgeMapMoreDetails
                    fridge={fridge}
                    secondLine={secondLine}
                    thirdLine={thirdLine}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="divide-x divide-gray-200">
            <div className="flex items-end divide-x divide-gray-200">
              {/* <button
                className="flex w-0 flex-1"
                onClick={() => setMoreDetails(!moreDetails)}
              >
                <div className="relative -mr-px inline-flex w-0 flex-1 grow items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700">
                  <span className="">
                    {moreDetails ? 'Hide Details' : 'More Details'}
                  </span>
                </div>
              </button> */}

              <button
                className="-ml-px flex w-0 flex-1"
                onClick={() => handleFridgeSelect(fridge)}
              >
                <div className="relative w-0 flex-1 grow items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700">
                  <span className="ml-3">Select</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const options = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
};
