import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../components/alert-modal';
import { calculateDistance } from '../helpers';
import TopNavigation from '../navigation/top-navigation';
import {
  FridgeStatePersistentStorageItem,
  FRIDGE_STATE_KEY,
} from '../persistent-storage/fridge-state.persistent-storage';
import PersistentStorage from '../persistent-storage/persistent-storage';
import { useAvailableFridges } from '../services/fridges.service';
import { useMe } from '../services/user.service';
import { Fridge } from '../types';
import { toast } from 'react-toastify';
import Notification from '../components/notification';
import { NotificationType } from '../constants';
import FridgeSelectSkeleton from '../loading-skeletons/fridge-select-skeleton';
import fridgeImage from '../img/fridge.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import PrestockFridgeMapCard from './prestock-fridge-map-card';

export type FridgeWithDistance = Fridge & { distance: number };

export default function PrestockFridges() {
  const navigate = useNavigate();
  const constList = [0, 0, 0, 0, 0, 0, 0, 0];
  const [searchValue, setSearchValue] = useState<string>('');
  const [search, setSearch] = useState(false);

  const [modalToggle, setModalToggle] = useState(false);
  const [selectedFridge, setSelectedFridge] = useState<
    FridgeWithDistance | Fridge
  >();
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );

  const fridge = PersistentStorage.get(selectedFridgeState);
  const { data: me, loading } = useMe();
  const [dateTime] = useState(new Date().toISOString());
  const [sortedFridges, setSortedFridges] = useState<FridgeWithDistance[]>();
  const {
    fridges,
    loading: fridgeLoading,
    error,
  } = useAvailableFridges(dateTime, me?.email);

  const [userLocation, setUserLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const handleAdded = () =>
    toast(
      <Notification
        type={NotificationType.Success}
        message={`Added ${selectedFridge?.name} for Grab 'n Go.`}
        title="Added"
      />,
      { position: 'top-right', autoClose: 2000 }
    );

  //Error updating
  const handleError = () =>
    toast(
      <Notification
        type={NotificationType.Error}
        message={'Error adding pod to meal plan.'}
        title="Error"
      />,
      { position: 'top-right' }
    );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setUserLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);
  //Recall fridge after confirmation
  useEffect(() => {
    let isMounted = true;
    if (confirmed && selectedFridge && isMounted) {
      handleFridgeSelect(selectedFridge);
    }
    return () => {
      isMounted = false;
    };
  }, [confirmed === true]);

  const ref = useRef<HTMLInputElement>(null);

  function handleClick() {
    if (ref.current) {
      ref.current.focus();
    }
    setSearchValue('');
  }

  useEffect(() => {
    if (fridges) {
      const sortedFridges: FridgeWithDistance[] = fridges
        .filter((e) => e.prestock)
        .map((fridge) => {
          const { location } = fridge;
          const distance = calculateDistance(
            location?.latitude!,
            location?.longitude!,
            userLocation?.lat!,
            userLocation?.lng!
          );
          return {
            ...fridge,
            distance,
          };
        })
        .sort((a, b) => a.distance - b.distance);

      setSortedFridges([...sortedFridges]);
    }
  }, [fridges]);

  async function handleFridgeSelect(fridge: FridgeWithDistance | Fridge) {
    setModalToggle(true);
    setSelectedFridge(fridge);
    if (confirmed) {
      //Condition for from meal plan and have an existing subscription and not updating the existing fridge
      PersistentStorage.update(selectedFridgeState, selectedFridge);
      handleAdded();
      navigate('/prestock-menu');
    }
  }

  function handleCancelSearch() {
    setSearch(false);
    setSearchValue('');
  }

  function handleRedirect() {
    if (fridge) {
      navigate('/date-select');
    } else {
      navigate('/fridge-select');
    }
  }

  return (
    <div>
      <TopNavigation title="Pickup Pods" url="/upcoming" />
      <div className="sticky top-16 z-20 bg-white px-4 pt-3 shadow-lg">
        <div className="flex">
          <div className="flex-shrink-0"></div>
          <img className="px-4 " src={fridgeImage} alt={'fridge'}></img>
          <div>
            <h4 className=" text-lg font-medium">Delivery Location</h4>
            <p className="text-gray-500">
              Select the most convenient Cubbi Pickup Pod for this order.
            </p>
          </div>
        </div>
        <div className="pl-2">
          <div className="flex flex-row items-center py-4">
            <div className="z-40 -mr-6 ml-1 text-gray-400">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              className={
                'h-[42px] w-full rounded-md border pl-8 text-gray-500 outline-0'
              }
              placeholder="Search by city or company name"
              onChange={(e) => setSearchValue(e.target.value)}
              type="kitchen"
              value={searchValue}
              autoComplete="kitchen"
              onFocus={() => setSearch(true)}
              ref={ref}
            ></input>
            {searchValue && searchValue.length > 0 && (
              <>
                <div onClick={() => handleClick()}>
                  <div className="z-20 -ml-6 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-gray-400 ">
                    <div className="-mt-[2px] text-xs text-white">x</div>
                  </div>
                </div>
              </>
            )}
            {search && (
              <div
                className="px-3 text-indigo-500"
                onClick={() => handleCancelSearch()}
              >
                Cancel
              </div>
            )}
          </div>
        </div>
        <div className="-mx-4 bg-blue-50">
          <div className="py-4 px-4 text-gray-900">
            <div className="inline-flex">
              <div className="pt-[2px]">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-blue-400"
                />
              </div>
              <div className="pl-[14px]">
                <span className="">
                  Grab 'n Go is only available at select locations. Don't see
                  your location?
                </span>
                <span
                  className="pl-1 text-indigo-700"
                  onClick={() => handleRedirect()}
                >
                  Pre-order
                </span>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
      <div
        role="list"
        className="grid auto-cols-auto grid-cols-1 gap-6 px-6 py-4 shadow-lg sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      >
        {fridges && sortedFridges ? (
          <>
            {sortedFridges
              .filter((e) =>
                search
                  ? e.owner?.name
                      .toLowerCase()
                      .match(searchValue.toLowerCase()) ||
                    e.location?.city
                      .toLowerCase()
                      .match(searchValue.toLowerCase())
                  : true
              )
              .map((fridge, index) => (
                <PrestockFridgeMapCard
                  key={index}
                  fridge={fridge}
                  handleFridgeSelect={handleFridgeSelect}
                />
              ))}
          </>
        ) : (
          <>
            {constList?.map((e, i) => {
              return <FridgeSelectSkeleton key={i} />;
            })}
          </>
        )}

        <AlertModal
          open={modalToggle}
          setOpen={setModalToggle}
          title="Confirm Pickup Pod"
          setConfirmed={setConfirmed}
          message={`Are you sure you want to select ${selectedFridge?.name}?`}
        />
      </div>
    </div>
  );
}
