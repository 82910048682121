import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import logo from '../img/logo.png';
import { PASSWORD_RESET } from '../services/auth.service';
import { PasswordError, PasswordResetResponse } from '../types';
import Alert, { AlertType } from '../components/alert';
interface ResetForm {
  password1: string;
  password2: string;
}

export default function PasswordResetForm() {
  const initialValue: ResetForm = { password1: '', password2: '' };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { resetToken } = useParams();
  const [forgotPassword] =
    useMutation<{ passwordReset: PasswordResetResponse }>(PASSWORD_RESET);
  const [errors, setErrors] = useState<PasswordError[]>();

  async function handleResetPassword({ password1, password2 }: ResetForm) {
    try {
      setLoading(true);
      const { data } = await forgotPassword({
        variables: { token: resetToken, password1, password2 },
      });
      setLoading(false);
      if (data?.passwordReset.success && !data.passwordReset.errors) {
        navigate('/password-confirmation');
      } else {
        setErrors(data?.passwordReset.errors.newPassword2);
      }
    } catch (e) {
      setErrors([{ code: 1, message: 'Something went wrong, try again' }]);
      setLoading(false);
      console.log(e);
    }
  }

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="object-fit mx-auto h-[84px] w-[84px] object-scale-down"
              src={logo}
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-semibold text-gray-900">
              Create New Password
            </h2>
          </div>
          <Formik
            initialValues={initialValue}
            onSubmit={(values, actions) => {
              handleResetPassword({ ...values });
              actions.setSubmitting(false);
            }}
          >
            <Form className="" action="#" method="POST">
              <input type="hidden" name="remember" defaultValue="true" />
              <div>
                <div>
                  <div className="text-sm text-gray-700">Password</div>
                  <Field
                    id="password2"
                    name="password2"
                    type="password"
                    required
                    className="relative block w-full appearance-none rounded-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="pb-4 text-sm text-gray-500">
                Must be at least 8 characters
              </div>
              <div>
                <div className="text-sm text-gray-700">Confirm Password</div>
                <Field
                  id="password1"
                  name="password1"
                  type="password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div className="pb-6 text-sm text-gray-500">
                Both Passwords must match
              </div>
              {errors !== undefined && (
                <div className="pb-5">
                  <Alert
                    title={'Reset Error'}
                    type={AlertType.Error}
                    messages={errors.map((e) => e.message)}
                  />
                </div>
              )}
              <div>
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                  {loading ? 'Loading...' : 'Reset Password'}
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
