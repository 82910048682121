import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { TOKEN } from '../constants';

type AuthContextType = {
  token: string | null;
  setAuthState: Dispatch<SetStateAction<AuthContextType>>;
};

const initialAuthState: AuthContextType = {
  token: localStorage.getItem(TOKEN),
  setAuthState() {
    throw new Error('Auth state must be overriden');
  },
};

export const AuthContext = createContext(initialAuthState);

interface Props {
  children: React.ReactNode;
}

export default function AuthProvider({ children }: Props) {
  const [authState, setAuthState] = useState<AuthContextType>(initialAuthState);
  const setAuthStateOverride: any = (state: AuthContextType) => {
    return setAuthState(state);
  };

  return (
    <AuthContext.Provider
      value={{ ...authState, setAuthState: setAuthStateOverride }}
    >
      {children}
    </AuthContext.Provider>
  );
}
