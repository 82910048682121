import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import MealPlanConfirmationModal from './meal-plan-confirmation-modal';
import { Dispatch, SetStateAction, useState } from 'react';
import { faArrowLeft, faCog } from '@fortawesome/free-solid-svg-icons';

interface Props {
  hasChanged: boolean;
  applyChanges: () => unknown;
  setPaymentExistsModal: Dispatch<SetStateAction<boolean>>;
}

export default function MealPlanNavigation({
  hasChanged,
  applyChanges,
  setPaymentExistsModal,
}: Props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  function handleApplyChanges() {
    if (!hasChanged) {
      navigate('/upcoming');
    } else {
      setOpen(true);
    }
  }
  return (
    <div className="sticky top-0 z-20">
      <div className="flex justify-between border-b bg-white py-5 px-6">
        <button onClick={() => handleApplyChanges()}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            size="lg"
            className="text-gray-400"
          />
        </button>
        <div className="text-base font-medium leading-6 text-gray-900">
          Meal Plan
        </div>
        <div
          className={'flex flex-row items-center text-gray-400'}
          onClick={() => setPaymentExistsModal(true)}
        >
          <FontAwesomeIcon className={'mr-2 text-lg'} icon={faCog} />
        </div>
      </div>
      <MealPlanConfirmationModal
        open={open}
        setOpen={setOpen}
        applyChanges={applyChanges}
      />
    </div>
  );
}
