import UpsellProduct from './upsell-product';
import { useUpsells } from '../services/products.service';
import { Moment } from 'moment';
import { ProductObj } from '../types';

interface Props {
  dates: string[];
  addItem: (
    { product, addons, incartAddition, incartPopup }: ProductObj,
    date: Moment
  ) => void;
  city: string | undefined;
}

export default function Upsells({ dates, addItem, city }: Props) {
  const { data: inCart, error } = useUpsells(city);

  if (!inCart || error) {
    return <></>;
  }

  return (
    <div>
      <div className="flex w-full flex-col p-2">
        {inCart && inCart.length > 0 && (
          <div className="text-md flex w-full justify-start py-2 font-medium">
            Complete your order with a drink or a snack!
          </div>
        )}
        <div className="flex justify-center">
          <div className="flex w-[340px] flex-row overflow-x-scroll pb-4 sm:w-full">
            {inCart &&
              inCart
                .filter((a) => a.active)
                .map((e, i) => {
                  return (
                    <UpsellProduct
                      key={i}
                      incart={e}
                      dates={dates}
                      addItem={addItem}
                    />
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
}
