import i18next from 'i18next';

export default function LanguageControl() {
  const handleLanguage = (value: string) => {
    if (value === 'english') {
      i18next.changeLanguage('en');
    } else if (value === 'french') {
      i18next.changeLanguage('fr');
    } else {
      i18next.changeLanguage('es');
    }
  };
  return (
    <div>
      <label
        htmlFor="location"
        className="block text-sm font-medium text-gray-700"
      >
        Language
      </label>
      <select
        onChange={(e) => handleLanguage(e.target.value)}
        id="location"
        name="location"
        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        defaultValue="Canada"
      >
        <option value={'english'}>English</option>
        <option value={'french'}>Français</option>
        <option value={'spanish'}>Español</option>
      </select>
    </div>
  );
}
