import { useCloudinaryImage } from '../helpers';
import { Product } from '../types';

interface Props {
  product: Product;
  length: number;
  index: number;
}

export default function ProductImageCard({ product, index, length }: Props) {
  const image = useCloudinaryImage(product.image);
  const showNumber = index === 3 && length > 4;
  return showNumber ? (
    <div className="relative h-24 w-20 overflow-hidden rounded-md bg-gray-900 shadow-md">
      <img
        src={image}
        alt="Avatar"
        className="h-24 w-20 rounded-md object-cover opacity-60 shadow-md"
      />
      <div className="absolute inset-x-0 bottom-8 w-full py-2.5 text-center text-xl font-bold leading-3 text-white">
        +{length - 4}
      </div>
    </div>
  ) : (
    <img
      src={image}
      alt={product.name}
      className="h-24 w-20 rounded-md object-cover shadow-md"
    />
  );
}
