import { useCloudinaryImage } from '../helpers';
import { InCartAdditions, ProductObj } from '../types';
import { useState } from 'react';
import TopProductModal from './top-product-modal';
import { Moment } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

interface Props {
  incart: InCartAdditions;
  dates: string[];
  addItem: (
    { product, addons, incartAddition, incartPopup }: ProductObj,
    date: Moment
  ) => void;
}

export default function UpsellProduct({ incart, dates, addItem }: Props) {
  const evenPrice = incart.price === incart.product.price;
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="pr-1" onClick={() => setOpen(true)}>
        <div className="flex min-h-[164px] min-w-[98px] max-w-[98px] flex-col rounded-md border bg-white py-1 shadow-lg">
          <div className="flex items-center justify-center">
            <img
              src={useCloudinaryImage(incart.product.image)}
              alt={incart.product.name}
              className="h-16 w-16"
            ></img>
          </div>
          <div className="flex flex-col text-center">
            <div className="overflow-hidden truncate px-2 text-sm">
              {incart.product.name}
            </div>
            {evenPrice ? (
              <div className="text-sm">${incart.product.price}</div>
            ) : (
              <>
                <div className="text-sm line-through">
                  ${incart.product.price}
                </div>
                <div className="text-sm">${incart.price}</div>
              </>
            )}
          </div>
        </div>
        <div className="-mt-10 flex items-end justify-end px-2">
          <button
            type="button"
            onClick={() => setOpen(true)}
            className="h-8 w-8 items-center justify-center rounded-full border border-gray-200 bg-white shadow-sm focus:outline-none"
          >
            <FontAwesomeIcon
              icon={faPlus}
              aria-hidden="true"
              size="sm"
              className="font-thin text-gray-500"
            />
          </button>
        </div>
      </div>
      <TopProductModal
        open={open}
        setOpen={setOpen}
        incart={incart}
        dates={dates}
        addItem={addItem}
      />
    </>
  );
}
