import { Field } from 'formik';
import { useState } from 'react';
import { SelectionType } from '../constants';
import { classNames } from '../helpers';
import { Addon, AddonGroup } from '../types';

interface Props {
  addon: AddonGroup;
}

export default function TwoThreeAddon({ addon: group }: Props) {
  const { addons } = group;
  const [state, setState] = useState(new Set());

  const addAddon = (addon: Addon) => {
    setState((previousState) => new Set([...previousState, addon]));
  };

  const removeAddon = (addon: Addon) => {
    setState((prev) => new Set([...prev].filter((x) => x !== addon)));
  };

  function handleChange(addon: Addon) {
    if (state.has(addon)) {
      removeAddon(addon);
    } else {
      if (state.size < 3) {
        addAddon(addon);
      }
    }
  }

  return (
    <div className="-space-y-px rounded-md bg-white">
      {addons.map((addon, addonIdx) => {
        const checked = state.has(addon);
        return (
          <div
            className={classNames(
              addonIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
              addonIdx === addons.length - 1
                ? 'rounded-bl-md rounded-br-md'
                : undefined,
              checked ? 'border-indigo-200 bg-indigo-50' : 'border-gray-200',
              'relative cursor-pointer items-center border p-4 focus:outline-none'
            )}
          >
            <div className="flex flex-row items-center justify-between">
              <div>
                <span
                  className={classNames(
                    checked ? 'text-indigo-900' : 'text-gray-900',
                    'block text-sm font-medium'
                  )}
                >
                  {addon.name}
                </span>

                <span
                  className={classNames(
                    checked ? 'text-indigo-700' : 'text-gray-500',
                    'block text-sm'
                  )}
                >
                  {addon.price > 0 ? `Add $${addon.price}` : 'Free'}
                </span>
              </div>
              <div>
                <Field
                  name={SelectionType.A_2}
                  type="checkbox"
                  onClick={() => handleChange(addon)}
                  value={addon.id}
                  className={classNames(
                    checked
                      ? 'border-transparent bg-indigo-600'
                      : 'border-gray-300 bg-white',
                    'mt-0.5 flex h-7 w-7 cursor-pointer items-center justify-center rounded-md border'
                  )}
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
