import { classNames } from '../helpers';

interface Props {
  disabled: boolean;
  onClick: () => void;
  loading: boolean;
  total: number;
}

export default function CheckoutFooter({
  disabled,
  onClick,
  loading,
  total,
}: Props) {
  return (
    <div className="flex justify-between border-t bg-white px-4 pt-4 pb-6">
      <button
        type="submit"
        onClick={onClick}
        className={classNames(
          disabled
            ? 'bg-gray-300 text-gray-400'
            : 'bg-indigo-600 text-white hover:bg-indigo-700  focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
          'text-md my-3 w-full rounded-md border border-transparent py-4 font-medium shadow-sm focus:outline-none  '
        )}
      >
        {loading ? 'Loading...' : `Checkout - $${total.toFixed(2)}`}
      </button>
    </div>
  );
}
