import { faArrowLeft, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { classNames } from '../helpers';

interface Props {
  pageNumber: number;
  bookingId: string | undefined;
}

export default function FeedbackHeader({ pageNumber, bookingId }: Props) {
  const navigate = useNavigate();

  function handleSkip() {
    if (pageNumber === 1) {
      navigate(`/rating/delivery/${bookingId}`, {
        state: {
          productId: null,
          productRatingText: null,
          productRating: null,
          productTags: [],
        },
      });
    } else {
      navigate('/upcoming');
    }
  }
  return (
    <div className="sticky top-0 z-20">
      <div
        key={'navigation'}
        className="flex justify-between bg-white py-5 px-6"
      >
        <button onClick={() => navigate(-1)}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            size="lg"
            className="text-gray-400"
          />
        </button>
        <div className="text-base font-medium leading-6 text-gray-900">
          {''}
        </div>
        <div className={'flex flex-row items-center text-gray-500'}>
          <div
            className="text-lg font-medium text-gray-900"
            onClick={() => handleSkip()}
          >
            Skip
          </div>
        </div>
      </div>
      <div
        className={classNames(
          pageNumber === 2
            ? 'h-1 w-full bg-cubbiBlue'
            : 'h-1 w-1/2 bg-cubbiBlue'
        )}
      ></div>
    </div>
  );
}
