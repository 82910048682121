import { useState, useRef } from 'react';
import FeedbackHeader from './feedback-header';
import ReactStars from 'react-stars';
import { classNames } from '../helpers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CREATE_REVIEW } from '../services/products.service';
import { toast } from 'react-toastify';
import Notification from '../components/notification';
import { NotificationType } from '../constants';

interface reviewState {
  productId: number | null;
  productRatingText: string | null;
  productRating: number | null;
  productTags: string[];
}

export default function FeedbackDelivery() {
  const rating = useRef(0);
  const navigate = useNavigate();
  const { bookingId } = useParams();
  const location = useLocation();
  const { productId, productRatingText, productRating, productTags } =
    location.state as reviewState;
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [currRating, setCurrRating] = useState(0);
  const [selected, setSelected] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [createReview] = useMutation<{ lastName: string }>(CREATE_REVIEW);

  const ratingChanged = (newRating: number) => {
    setCurrRating(newRating);
  };

  const feedbackTags = [
    'Condition of food',
    'Packaging',
    'Cleanliness',
    'Issue retrieving order',
    'Wrong/missing items',
    'Damage',
  ];

  function handleSelected(index: number) {
    let currVals = selected;
    currVals[index] = !currVals[index];
    setSelected([...currVals]);
  }

  const handleSuccess = () =>
    toast(
      <Notification
        type={NotificationType.Success}
        message={'Thanks for submitting feedback'}
        title="Success"
      />,
      { position: 'top-right' }
    );

  async function handleCreateReview() {
    let list: string[] = [];
    if (selected.includes(true)) {
      for (let i = 0; i < selected.length; i++) {
        if (selected[i]) {
          list = [...list, feedbackTags[i]];
        }
      }
    }
    try {
      const { data } = await createReview({
        variables: {
          input: {
            booking: bookingId,
            bookingItem: Number(productId ?? null),
            productRatingText: productRatingText ?? null,
            deliveryRatingText: additionalInfo ?? null,
            productRating: productRating ?? null,
            deliveryRating: currRating ?? null,
            productTags: productTags,
            deliveryTags: list,
          },
        },
      });
      if (data) {
        handleSuccess();
        navigate('/upcoming');
      }
    } catch (e) {}
  }

  return (
    <>
      <FeedbackHeader pageNumber={2} bookingId={bookingId} />
      <div className="flex flex-col px-4">
        <div className="flex flex-col">
          <div className="flex flex-col justify-start">
            <div className="py-4 text-3xl font-semibold">
              How was the delivery?
            </div>
            <div className="text-sm text-gray-500">
              Help us improve the experience
            </div>
          </div>
          <div className="flex flex-row items-center justify-center">
            <ReactStars
              count={5}
              onChange={ratingChanged}
              value={currRating}
              size={36}
              half={true}
              color2={'#ffd700'}
            />
          </div>
        </div>
        {currRating > 0 && (
          <>
            {currRating !== 5 && (
              <>
                <div className="text-color-[#181818] text-lg font-semibold">
                  What can we improve on?
                </div>
                <div className="flex flex-wrap whitespace-pre py-2">
                  {feedbackTags.map((e, i) => {
                    return (
                      <div className="py-1 pr-2" key={i}>
                        <div
                          className={classNames(
                            selected[i]
                              ? 'rounded-md bg-cubbiIndigo px-3 text-white'
                              : 'rounded-md bg-gray-100 px-3 text-gray-800'
                          )}
                          onClick={() => handleSelected(i)}
                        >
                          {e}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            <textarea
              className="h-40 rounded-md border border-gray-300 px-4"
              onChange={(e) => setAdditionalInfo(e.target.value)}
              placeholder="Care to share more? (optional)"
            ></textarea>
            <div className="py-4">
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => handleCreateReview()}
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                {'Submit'}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
