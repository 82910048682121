import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';
import ErrorPage from '../screens/error';
import LoadingPage from '../screens/loading';
import { useMe } from '../services/user.service';
import { User } from '../types';

type LayoutContextType = {
  user?: User;
  setLayoutState: Dispatch<SetStateAction<LayoutContextType>>;
};

const initialLayoutState: LayoutContextType = {
  user: undefined,
  setLayoutState() {
    throw new Error('Layout state must be overriden');
  },
};

export const LayoutContext = createContext(initialLayoutState);

interface Props {
  children: ReactNode;
  cart?: OrderState[];
}

export default function LayoutProvider({ children, cart }: Props) {
  const { data: me, error, loading } = useMe();
  const [layoutState, setLayoutState] =
    useState<LayoutContextType>(initialLayoutState);
  const setLayoutStateOverride: any = (state: LayoutContextType) => {
    return setLayoutState(state);
  };

  useEffect(() => {
    if (!me) {
      return;
    }
    setLayoutState({ user: me, setLayoutState });
  }, [me]);

  if (loading) {
    return <LoadingPage />;
  }

  if (!me || error) {
    return (
      <ErrorPage
        error={error?.graphQLErrors.map(({ message }) => `${message}`)}
      />
    );
  }

  return (
    <LayoutContext.Provider
      value={{ user: layoutState.user, setLayoutState: setLayoutStateOverride }}
    >
      <div className="flex h-screen flex-col justify-between bg-gray-50">
        <main className="mb-auto ">{children}</main>
        {/* {cart && cart.length > 0 && (
          <footer>
            <button
              onClick={() => navigate('/order-summary')}
              type="button"
              className="fixed bottom-0 z-50 flex w-full flex-row justify-between border border-transparent bg-indigo-600 p-4 text-lg font-medium text-white shadow-sm hover:bg-indigo-700"
            >
              <div className="flex flex-row">
                <FontAwesomeIcon icon={faReceipt} className="mt-1 mr-2" />
                <p className="text-center">View Order</p>
              </div>
              <div>{`${cart.length} Items`}</div>
            </button>
          </footer>
        )} */}
      </div>
    </LayoutContext.Provider>
  );
}
