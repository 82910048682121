import NutritionInfo from '../product-info-modal/nutrition-info';
import ProductInfoHeader from '../product-info-modal/product-info-header';
import { Product } from '../types';

interface Props {
  product: Product;
  close: () => void;
  addItemToOrder: () => void;
  sold: boolean;
  deliveredAt: boolean;
}

export default function PrestockSheet({
  product,
  close,
  addItemToOrder,
  sold,
  deliveredAt,
}: Props) {
  function handleBack() {
    close();
  }
  return (
    <div className="flex min-h-screen flex-col">
      <div className="bg-white">
        <ProductInfoHeader
          image={product.image}
          name={product.name}
          close={close}
        />
        <NutritionInfo
          name={product.name}
          description={product.description}
          allergens={product.allergens}
          nutritionFacts={product.nutritionfacts}
          almostSoldOut={false}
          totalSize={product.totalSize}
          quantity={product.quantity}
        />
        <div className="pb-96" />

        <div className="fixed inset-x-0 bottom-0 border-t bg-white">
          <footer className="relative inset-x-0 bottom-0 z-50">
            <div className="inline-flex w-full content-center justify-between border border-transparent px-6 pt-4 pb-8">
              {!sold && deliveredAt ? (
                <>
                  <div className={'w-1/2 pr-4'}>
                    <button
                      className="inline-flex w-full items-center justify-center rounded-md bg-indigo-100 px-5 py-3 text-sm font-medium  text-indigo-700 shadow-sm hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      type={'button'}
                      onClick={() => handleBack()}
                    >
                      Go back
                    </button>
                  </div>
                  <div className="w-1/2">
                    <button
                      type="submit"
                      className="inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-5 py-3 text-sm font-medium  text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={addItemToOrder}
                    >
                      Add ${product.price}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className={'w-1/2 pr-4'}>
                    <button
                      className="inline-flex w-full items-center justify-center rounded-md bg-indigo-100 px-5 py-3 text-sm font-medium  text-indigo-700 shadow-sm hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      type={'button'}
                      onClick={() => handleBack()}
                    >
                      Go back
                    </button>
                  </div>
                  <div className="w-1/2">
                    <button
                      type="submit"
                      className="inline-flex w-full items-center justify-center rounded-md bg-red-200 px-5 py-3 text-sm font-medium  text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      disabled={true}
                    >
                      Unavailable
                    </button>
                  </div>
                </>
              )}
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}
