import { gql, useQuery } from '@apollo/client';
import { QueryResult, Subscription } from '../types';
import { getNodeDataFromEdges } from '../helpers';

export const SUBSCRIPTIONS_BY_EMAIL = gql`
  query subscriptions($email: String!) {
    subscriptions(user_Email: $email) {
      edges {
        node {
          id
          deliveryDays
          mealSelection
          activate
          paymentInfo {
            id
            paymentMethodId
            last4
            expMonth
            expYear
          }
          fridge {
            id
            name
            image
            owner {
              name
              logo
            }
          }
          products {
            id
            name
            quantity
            addons
            price
            image
            description
            position
          }
        }
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation updateSubscription($id: Int!, $input: SubscriptionInput!) {
    updateSubscription(id: $id, input: $input) {
      subscription {
        id
        user {
          email
        }
      }
      ok
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation createSubscription($input: SubscriptionInput!) {
    createSubscription(input: $input) {
      subscription {
        id
        user {
          email
        }
      }
      ok
    }
  }
`;
//Subscriptions by email that prevents caching
export function useSubByEmail(email?: string) {
  const { data, loading, error, refetch } = useQuery<{
    subscriptions: QueryResult<Subscription>;
  }>(SUBSCRIPTIONS_BY_EMAIL, {
    variables: { email },
    skip: !email,
    fetchPolicy: 'network-only',
  });
  const result =
    data?.subscriptions.edges &&
    getNodeDataFromEdges(data?.subscriptions.edges);
  return { data: result, loading, error, refetch };
}
