import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction } from 'react';
import { classNames, useCloudinaryImage } from '../helpers';
import { Product } from '../types';
import moment from 'moment';

interface Props {
  product: Product;
  addItemToOrder: (product: Product, bookingId: number) => unknown;
  handleOpenProductModal: (
    product: Product,
    bookingId: number,
    sold: boolean | undefined,
    deliveredAt: string | undefined
  ) => unknown;
  sold: boolean | undefined;
  bookingId: number;
  setBookingId: Dispatch<SetStateAction<number | undefined>>;
  delivered: string | undefined;
  startAt: string;
}

export default function PrestockItems({
  product,
  addItemToOrder,
  handleOpenProductModal,
  sold,
  bookingId,
  setBookingId,
  delivered,
  startAt,
}: Props) {
  const { name, image, price, description, totalSize, discountPrice, vendor } =
    product;
  const productImage = useCloudinaryImage(image);
  const deliveredDate = moment(startAt);
  const today = moment();

  function handleRedirect() {
    setBookingId(bookingId);
    addItemToOrder(product, bookingId);
  }

  return (
    <div className="relative overflow-hidden rounded-xl bg-white shadow-md hover:shadow-xl">
      <div
        className="pb-6"
        onClick={() =>
          handleOpenProductModal(product, bookingId, sold, delivered)
        }
      >
        <img
          src={productImage}
          alt={name}
          className={classNames(
            'rounded-t-xvl pointer-events-none mx-auto mb-3 h-32 w-full object-cover group-hover:opacity-75',
            sold || !delivered ? 'opacity-40' : undefined
          )}
        />
        {!delivered && today.date() === deliveredDate.date() && (
          <>
            <div className="flex items-center px-2 py-4">
              <div className="z-10 -mt-[274px] flex items-center rounded-md bg-white px-1 text-sm">
                Available at 11:30AM
              </div>
            </div>
            <div className="-mt-8"></div>
          </>
        )}
        <div className="px-4 text-xs font-bold text-gray-500">
          {vendor.toUpperCase()}
        </div>
        <div
          className={classNames(
            sold || !delivered ? 'opacity-40' : undefined,
            'px-4 py-1 font-bold text-gray-900'
          )}
        >
          {name}
        </div>
        <div
          className={classNames(
            sold || !delivered ? 'w-full px-4 opacity-40' : undefined,
            'w-full truncate px-4 text-gray-400'
          )}
        >
          {name === 'Meals' ? description : totalSize ?? description}
        </div>
      </div>
      <div className="mt-6 mb-2 flex w-4 items-center justify-between px-4">
        <div className="mt-4"></div>
        <div className="text-md absolute bottom-3 flex flex-col font-bold text-gray-900">
          {discountPrice ? (
            <div
              className={classNames(
                sold || !delivered
                  ? 'font-medium text-gray-400 line-through opacity-40'
                  : 'font-medium text-gray-400 line-through'
              )}
            >{`$${price}`}</div>
          ) : (
            <div
              className={classNames(
                sold || !delivered ? 'text-lg opacity-40' : 'text-lg'
              )}
            >{`$${price}`}</div>
          )}
          {discountPrice && (
            <>
              <div className={classNames(sold ? 'opacity-40' : undefined)}>
                {`$${discountPrice}`}
              </div>
            </>
          )}
        </div>
        <div className="mr-4" />
        <div className="mr-4" />
        {!sold && delivered && (
          <button
            type="button"
            onClick={() => handleRedirect()}
            className="absolute bottom-2 right-2 inline-flex items-center rounded-full border border-gray-200 bg-white p-2.5 shadow-sm focus:outline-none"
          >
            <FontAwesomeIcon
              icon={faPlus}
              aria-hidden="true"
              size="sm"
              className="font-thin text-gray-500"
            />
          </button>
        )}
      </div>
    </div>
  );
}
