import { classNames } from '../helpers';

export enum AlertType {
  Success,
  Error,
}

interface Props {
  type?: AlertType;
  title?: string;
  messages: string[];
}

export default function Alert({ type, title, messages }: Props) {
  return (
    <div
      className={classNames(
        'p-4',
        type === AlertType.Error ? 'bg-red-50' : 'bg-red-50'
      )}
    >
      <div className="flex">
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {title ??
              `There were ${messages.length} errors with your submission`}
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <div role="list" className="list-disc space-y-1 pl-4">
              {messages.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
