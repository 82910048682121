import {
  faAngleRight,
  faArrowRight,
  faParachuteBox,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  FridgeFilterStatePersistentStorageItem,
  FRIDGE_FILTER_STATE_KEY,
} from '../persistent-storage/fridge-filter-state.persistent-storage';
import {
  FridgeOrderStatePersistentStorageItem,
  FRIDGE_ORDER_STATE_KEY,
} from '../persistent-storage/order-fridge-state.persistent-storage';
import PersistentStorage from '../persistent-storage/persistent-storage';
import { Booking } from '../types';

interface Props {
  bookings: Booking[];
  today: boolean;
  past: boolean;
  totalBookings: Booking[];
  handleNavigation: () => void;
}

export default function DeliveryResults({
  bookings,
  today,
  past,
  handleNavigation,
  totalBookings,
}: Props) {
  const fridgeOrderState = new FridgeOrderStatePersistentStorageItem(
    FRIDGE_ORDER_STATE_KEY
  );
  const fridgeFilterState = new FridgeFilterStatePersistentStorageItem(
    FRIDGE_FILTER_STATE_KEY
  );
  const date = moment();
  const navigate = useNavigate();
  const tomorrow = moment().add(1, 'days');
  const prevDate = moment().add(-1, 'days');

  function handleRedirectFridge(bookings: Booking[], filterValue: String) {
    PersistentStorage.update(fridgeFilterState, filterValue);
    PersistentStorage.update(fridgeOrderState, bookings);
    navigate('/fridge-order');
  }

  //Get the cancelledTotal from
  // const cancelledTotal = bookings.filter((e) => e.cancelledAt !== null);
  // const cancelledLength = cancelledTotal.length;
  // const bookingsLength = bookings.length;

  //Filters to total bookings on that day at that start date. We need to do it on this page so we can still
  //retrieve if an order is cancelled even if it is not the one displayed.
  function checkCancelledLength(name: string, date: string) {
    const names = totalBookings?.map(() => name + date);
    const result = totalBookings.filter((e, index) =>
      names.includes(e.cubbi.fridge.name + e.startAt.substring(0, 10), index)
    );
    return result;
  }

  return (
    <>
      <h2 className="sr-only">Recent orders</h2>
      <div className="mx-auto max-w-7xl rounded-md px-4 shadow-sm sm:px-2 lg:px-8">
        {!bookings[0] && (
          <div className="h-96 py-4">
            <div className="mt-12 flex items-center justify-center">
              <FontAwesomeIcon
                className="align-self-center fa-2x gray-900 justify-self-center text-gray-400"
                icon={faParachuteBox}
              />
            </div>
            <div className="py-4 text-center">
              <p className="text-gray-900">No Bookings Found</p>
              <p className="text-gray-500">
                Get started by placing an order below
              </p>
              <button
                type="button"
                onClick={handleNavigation}
                className="mt-6 rounded-md border border-transparent bg-indigo-500 px-10 py-3 text-sm  leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Order food
                <FontAwesomeIcon className="ml-4" icon={faArrowRight} />
              </button>
            </div>
          </div>
        )}
      </div>
      {bookings.map((results) => {
        const cubbi = results.cubbi;
        const fridge = results.cubbi.fridge;
        const fridgeWeekendAccess = results.cubbi.fridge.allowWeekendAccess;
        const currDate = results.startAt.substring(0, 10);
        const cancelledLength = checkCancelledLength(fridge.name, currDate);
        const prestockBookings = results.prestockBookings;
        const cancelledItems = cancelledLength.filter(
          (e) => e.cancelledAt !== null
        );
        const deliveredAtLength = cancelledLength.filter(
          (e) => e.deliveredAt !== null
        );
        //Simple orderWindow thats cancelled the next day at 9AM
        const orderWindowSingle =
          currDate === prevDate.toISOString(true).substring(0, 10) &&
          parseInt(date.toISOString(true).substring(11, 13)) < 9;

        //Variable that extends the pickup into the weekend until 9AM on Monday (3 days ahead)
        const orderWindowExtendedWeekend =
          fridgeWeekendAccess &&
          moment(currDate).day() === 5 &&
          (date.toISOString(true).substring(0, 10) <
            moment(currDate)
              .add(3, 'days')
              .toISOString(true)
              .substring(0, 10) ||
            (parseInt(date.toISOString(true).substring(11, 13)) < 9 &&
              date.toISOString(true).substring(0, 10) ===
                moment(currDate)
                  .add(3, 'days')
                  .toISOString(true)
                  .substring(0, 10)));
        return (
          <>
            <div
              key={results.id}
              className="border-t border-gray-200 px-4 py-4 text-sm sm:border"
              onClick={() =>
                handleRedirectFridge(
                  totalBookings,
                  cubbi.fridge.name + results.startAt.substring(0, 10)
                )
              }
            >
              <div className="flex">
                <dt className="justify-start pr-2 text-lg font-semibold text-black">
                  {fridge?.name}
                </dt>
                {today && <dd className="text-gray-500"></dd>}
              </div>
              {prestockBookings && prestockBookings.edges.length === 0 && (
                <div className="text-md flex w-full justify-between text-gray-500">
                  {moment(results.startAt).format('ddd, MMM D') + ' @11:30AM'}
                </div>
              )}
              <div className="flex justify-end">
                <FontAwesomeIcon
                  icon={faAngleRight}
                  size="lg"
                  className="text-gray-400"
                />
              </div>
              {/*Display for today delivered with no cancellations */}
              {deliveredAtLength.length > 0 &&
                today &&
                cancelledItems.length === 0 && (
                  <div className="flex">
                    <dt className="rounded-lg bg-green-100 px-2 text-green-800">
                      Delivered
                    </dt>
                    <div className="pr-2"></div>
                    {prestockBookings && prestockBookings.edges.length > 0 ? (
                      <dd className="text-md rounded-lg bg-gray-100 px-2 text-gray-800">
                        Collect by{' '}
                        {moment(results.endAt).format('ddd D, h:mmA')}
                      </dd>
                    ) : (
                      <dd className="text-md rounded-lg bg-gray-100 px-2 text-gray-800">
                        Collect by {moment(tomorrow).format('ddd D')}, 9:00AM
                      </dd>
                    )}
                  </div>
                )}
              {/*Display for delivered today with cancellations, but not all */}
              {deliveredAtLength.length > 0 &&
                today &&
                cancelledItems.length > 0 &&
                cancelledItems.length !== cancelledLength.length && (
                  <>
                    <div className="flex flex-row">
                      <dt className="rounded-lg bg-green-100 px-2 text-green-800">
                        Delivered
                      </dt>
                      <div className="pr-2"></div>
                      {prestockBookings && prestockBookings.edges.length > 0 ? (
                        <dd className="text-md rounded-lg bg-gray-100 px-2 text-gray-800">
                          Collect by{' '}
                          {moment(results.endAt).format('ddd D, h:mmA')}
                        </dd>
                      ) : (
                        <dd className="text-md rounded-lg bg-gray-100 px-2 text-gray-800">
                          Collect by {moment(tomorrow).format('ddd D')}, 9:00AM
                        </dd>
                      )}
                    </div>
                    <p className="py-2 text-red-500">
                      {cancelledItems.length > 1
                        ? `${cancelledItems.length} Orders have been cancelled`
                        : `1 Order has been cancelled`}
                    </p>
                  </>
                )}
              {/* Not delivered today and no cancellations */}
              {today &&
                deliveredAtLength.length === 0 &&
                cancelledItems.length === 0 && (
                  <div className="flex">
                    <p className="text-md rounded-lg bg-blue-100 px-2 text-blue-800">
                      Preparing Finalized Delivery
                    </p>
                  </div>
                )}
              {/*Not yet delivered today with a cancellation, not all cancelled */}
              {today &&
                deliveredAtLength.length === 0 &&
                cancelledItems.length > 0 &&
                cancelledLength.length !== cancelledItems.length && (
                  <div className="flex flex-col">
                    <p className="text-md w-[196px] rounded-lg bg-blue-100 px-2 text-blue-800">
                      Preparing Finalized Delivery
                    </p>
                    <p className="py-2 text-red-500">
                      {cancelledItems.length > 1
                        ? `${cancelledItems.length} Orders have been cancelled`
                        : `1 Order has been cancelled`}
                    </p>
                  </div>
                )}
              {/* Delivered, within the time window (in the past), also checks that not all orders are cancelled. */}
              {past &&
                deliveredAtLength.length > 0 &&
                orderWindowSingle &&
                cancelledItems.length !== cancelledLength.length && (
                  <div className="flex">
                    <p className="text-md rounded-lg bg-green-100 px-2 text-green-800">
                      Delivered
                    </p>
                    <dd className="text-md rounded-lg bg-gray-100 px-2 text-gray-800">
                      Collect by {moment().format('ddd D')}, 9:00AM
                    </dd>
                  </div>
                )}
              {/*Delivered on a friday where fridges allow weekendAccess */}
              {past &&
                deliveredAtLength.length > 0 &&
                orderWindowExtendedWeekend && (
                  <div className="flex">
                    <p className="text-md rounded-lg bg-green-100 px-2 text-green-800">
                      Delivered
                    </p>
                    <dd className="text-md rounded-lg bg-gray-100 px-2 text-gray-800">
                      Collect by{' '}
                      {moment(currDate).add(3, 'days').format('ddd D')}, 9:00AM
                    </dd>
                  </div>
                )}
              {/*In the time window in the past, but not delivered */}
              {past &&
                deliveredAtLength.length === 0 &&
                orderWindowSingle &&
                cancelledItems.length !== cancelledLength.length && (
                  <div className="flex flex-col">
                    <p className="text-md w-[196px] rounded-lg bg-blue-100 px-2 text-blue-800">
                      Preparing Finalized Delivery
                    </p>
                  </div>
                )}
              {/*Wasn't marked as delivered, was on a friday and allowed access to pickup on Fridays. */}
              {past &&
                deliveredAtLength.length === 0 &&
                orderWindowExtendedWeekend &&
                cancelledItems.length !== cancelledLength.length && (
                  <div className="flex flex-col">
                    <p className="text-md w-[196px] rounded-lg bg-blue-100 px-2 text-blue-800">
                      Preparing Finalized Delivery
                    </p>
                  </div>
                )}
              {/*Past the pickup window on the day after it was delivered. */}
              {past &&
                cancelledItems.length !== cancelledLength.length &&
                !orderWindowSingle &&
                !orderWindowExtendedWeekend && (
                  <div>
                    <p className="w-[164px] rounded-lg bg-red-100 px-2.5 text-red-800">
                      Pickup window closed
                    </p>
                  </div>
                )}
              {/* Cancelled items length red text, applies whenever some, but not all orders are cancelled in the past */}
              {past &&
                cancelledItems.length > 0 &&
                cancelledLength.length !== cancelledItems.length && (
                  <div>
                    <p className="py-2 text-red-500">
                      {cancelledItems.length > 1
                        ? `${cancelledItems.length} orders cancelled`
                        : `1 order cancelled`}
                    </p>
                  </div>
                )}
              {/*Upcoming tab start
                Booked tag for no cancellations */}
              {!today && !past && cancelledItems.length === 0 && (
                <div className="flex">
                  <p className="rounded-lg bg-gray-100 px-2 text-gray-800">
                    Booked
                  </p>
                </div>
              )}
              {/*Upcoming Booked partial cancellation */}
              {cancelledItems.length > 0 &&
                cancelledItems.length < cancelledLength.length &&
                !today &&
                !past && (
                  <div className="flex flex-col">
                    <p className="w-16 rounded-lg bg-gray-100 px-2 text-gray-800">
                      Booked
                    </p>
                    <p className="py-2 text-red-500">
                      {cancelledItems.length > 1
                        ? `${cancelledItems.length} Orders have been cancelled`
                        : `1 Order has been cancelled`}
                    </p>
                  </div>
                )}
              {/* Fully cancelled order for all days */}
              {cancelledItems.length === cancelledLength.length && (
                <div className="flex flex-col">
                  <p className="w-20 rounded-lg bg-gray-100 px-2 text-gray-800">
                    Cancelled
                  </p>
                  <p className="py-2 text-red-500">
                    All orders have been cancelled
                  </p>
                </div>
              )}
            </div>
          </>
        );
      })}
    </>
  );
}
