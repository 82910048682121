/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { classNames } from '../helpers';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  items: string[];
  title: string;
  functionToSend?: Dispatch<SetStateAction<string | undefined>>;
  changed: boolean;
  width: string;
  widthOfDropdown: string;
  height?: string;
}

export default function Dropdown({
  items,
  title,
  functionToSend,
  changed,
  width,
  widthOfDropdown,
  height,
}: Props) {
  return (
    <Menu as="div" className={`${width} relative inline-block text-left`}>
      <div>
        <Menu.Button
          className={classNames(
            changed
              ? 'inline-flex w-full justify-between rounded-md border border-gray-400 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100'
              : 'inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100'
          )}
        >
          {title}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute z-50 mt-2 h-48 ${widthOfDropdown} ${height} origin-top-right overflow-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1">
            {items.map((item) => {
              return (
                <Menu.Item key={item}>
                  {({ active }) => (
                    <p
                      onClick={() => functionToSend && functionToSend(item)}
                      key={item}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      {item.toString()}
                    </p>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
