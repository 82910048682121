export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refresh_token';
export const FRIDGE = 'fridge';
export const BOOKING_DAYS = 'booking_days';

export enum NotificationType {
  Success,
  Error,
  Warning,
  Info,
}

export enum SelectionType {
  A_1 = 'A_1',
  A_2 = 'A_2',
  A_3 = 'A_3',
}

export enum Route {
  Login = 'Login',
  Kitchen = 'Kitchen',
  History = 'History',
  Map = 'Map',
  Account = 'Account',
  Settings = 'Settings',
  Logout = 'Logout',
  Register = 'Register',
  Loading = 'Loading',
  Fridges = 'Fridges',
  Checkout = 'Checkout',
  Orders = 'Orders',
  OrderSuccess = 'OrderSuccess',
  Upcoming = 'Upcoming',
  Receipt = 'Receipt',
  Onboarding = 'Onboarding',
  OrderDetails = 'OrderDetails',
}

export enum ErrorCode {
  Unique = 'unique',
  PasswordShort = 'password_too_short',
  PasswordCommon = 'password_too_common',
  PasswordNumeric = 'password_entirely_numeric',
}

export enum AppType {
  Cubbi = 'A_1',
  LunchLocker = 'A_2',
}

export enum PhoneErrorCodes {
  AleadyExistsVerified = 'verified_unique',
  AlreadyExistsUnverified = 'not_verified_unique',
}

export enum TokenAuthError {
  InvalidCredentials = 'invalid_credentials',
}
