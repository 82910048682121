import { faApple, faGoogle } from '@fortawesome/free-brands-svg-icons';
import {
  faCreditCard,
  faPlus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSheetModal from '../components/bottom-sheet-modal';
import { DeviceType, getMobileOperatingSystem } from '../helpers';
import { useModalToggle } from '../hooks';
import { CreditCard, User } from '../types';
import { PaymentTypeSelection } from './checkout-form';
import CreditCardForm from './credit-card-form';

interface Props {
  defaultCard: CreditCard | null;
  setPaymentType: (paymentType: PaymentTypeSelection) => void;
  paymentType: PaymentTypeSelection;
  close: () => void;
  user: User;
  setCreditCard: (creditCard: CreditCard | null) => void;
  refetchUser: () => void;
  hasPaymentRequest: boolean;
  handleGetPaymentIntent: () => void;
}

export default function PaymentMethodSelect({
  defaultCard,
  setPaymentType,
  paymentType,
  setCreditCard,
  close,
  user,
  refetchUser,
  hasPaymentRequest,
  handleGetPaymentIntent,
}: Props) {
  const device = getMobileOperatingSystem();
  const { props, open, close: close2 } = useModalToggle();
  const options = [
    {
      type: PaymentTypeSelection.CreditCard,
      name: `Credit Card Ending in ${defaultCard?.last4}`,
      icon: faCreditCard,
      hidden: !defaultCard,
    },
    {
      type: PaymentTypeSelection.PaymentRequest,
      name:
        device === DeviceType.Browser
          ? 'Pay'
          : DeviceType.iOS
          ? 'Apple Pay'
          : 'Google Pay',
      icon:
        device === DeviceType.Browser
          ? faCreditCard
          : DeviceType.iOS
          ? faApple
          : faGoogle,
      hidden: !hasPaymentRequest,
    },
  ];

  return (
    <>
      <BottomSheetModal
        visible={props.visible}
        close={close2}
        height={0.401}
        blocked={true}
      >
        <CreditCardForm
          defaultCard={defaultCard}
          setCreditCard={setCreditCard}
          close={close2}
          user={user}
          onSuccess={() => {
            refetchUser();
            handleGetPaymentIntent();
          }}
        />
      </BottomSheetModal>
      <div className=" px-4">
        <div className="flex items-center justify-between">
          <div className="pointer-cursor" onClick={close}>
            <FontAwesomeIcon
              icon={faTimes}
              size="lg"
              className="text-gray-600"
            />
          </div>
          <div className="font-medium">Select Payment Method</div>
          <div />
        </div>
        <div>
          <fieldset className="my-4">
            <div className="divide-y divide-gray-200">
              <div className="  items-start py-4">
                <div className="flex justify-between text-sm" onClick={open}>
                  <label className="text-md font-medium text-gray-700">
                    {defaultCard
                      ? 'Update Payment Method'
                      : 'Add Payment Method'}
                  </label>
                  <FontAwesomeIcon icon={faPlus} className="mr-1.5" />
                </div>
              </div>
              {options.map(({ name, icon, type, hidden }, index) => {
                if (hidden) {
                  return null;
                }
                return (
                  <div
                    key={index}
                    className="relative flex items-start py-4"
                    onClick={() => setPaymentType(type)}
                  >
                    <div className="min-w-0 flex-1 text-sm">
                      <FontAwesomeIcon icon={icon} className="mr-1.5" />
                      <label
                        htmlFor={`account-${index}`}
                        className="text-md font-medium text-gray-700"
                      >
                        {name}
                      </label>
                    </div>
                    <div className="ml-3 flex h-5 items-center">
                      <input
                        id={`account-${index}`}
                        aria-describedby={`account-${index}-description`}
                        name="account"
                        type="radio"
                        onChange={() => {
                          setPaymentType(type);
                          close();
                        }}
                        checked={paymentType === type}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </fieldset>
        </div>
      </div>
    </>
  );
}
