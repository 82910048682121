import { PersistentStorageItem } from './persistent-storage';

export const FRIDGE_FILTER_STATE_KEY = 'fridge-filter-state';

export class FridgeFilterStatePersistentStorageItem extends PersistentStorageItem<
  string,
  string
> {
  public key: string;
  public defaultValue?: string = undefined;

  constructor(key: string) {
    super();
    this.key = key;
  }

  public encode(item: string) {
    return JSON.stringify(item);
  }

  public decode(encodedFridgeState: string) {
    return JSON.parse(encodedFridgeState) as string;
  }
}
