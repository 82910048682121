import React from 'react';
import { classNames } from '../helpers';

interface Props {
  length: number;
  filled: number;
}

export default function ProgressIndicator({ length, filled }: Props) {
  return (
    <div className="flex flex-row">
      {Array.from({ length }).map((_, index) => (
        <div className="g-1 mb-6 mt-4 flex w-full flex-row px-1">
          <div
            key={index}
            className={classNames(
              index < filled ? `bg-primary-600` : `bg-primary-100`,
              `flex h-4 w-full rounded-lg px-1`
            )}
          ></div>
        </div>
      ))}
    </div>
  );
}
