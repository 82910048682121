import { useEffect, useState } from 'react';
import { useUpsellPopups } from '../services/products.service';
import IncartPopupModal from './incart-popup-modal';
import { ProductObj } from '../types';
import { Moment } from 'moment';

interface Props {
  user: string | undefined;
  products: number[];
  dates: string[];
  fridge: string | undefined;
  addItem: (
    { product, addons, incartAddition, incartPopup }: ProductObj,
    date: Moment
  ) => void;
}

export default function IncartPopupItems({
  user,
  products,
  dates,
  addItem,
  fridge,
}: Props) {
  const [open, setOpen] = useState(false);
  const [closed, setClosed] = useState(false);

  const { data, error } = useUpsellPopups(user, products, fridge);

  useEffect(() => {
    if (data && data.incartpopups && data.incartpopups.length > 0 && !closed) {
      setOpen(true);
    }
  }, [data]);

  if (error || !data) {
    return <></>;
  }
  return (
    <>
      <IncartPopupModal
        open={open}
        setOpen={setOpen}
        item={data && data.incartpopups && data.incartpopups.slice(-1)[0]}
        dates={dates}
        addItem={addItem}
        setClosed={setClosed}
      />
    </>
  );
}
